import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateSongWaiting } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import { arraysAreEqual } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const SongWaitingFormScreen = ({ crudType }) => {
  const { applicationsForm, songWaitingForm, getSongWaitingLink, songWaiting, createSongWaiting, updateSongWaiting, getApplicationsLocutions, arrayLocutionsHook } = useApplications();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);

  const data = formCreateSongWaiting;

  useEffect(() => {
    getHeadquarter();
    getSongWaitingLink();
    getApplicationsLocutions();
    applicationsForm({ action: 'clearForm' }, { form: 'songWaiting' });

    if (crudType === 'edit') {
      applicationsForm(
        {
          action: 'input',
          value: location.state.id,
        },
        {
          form: 'songWaiting',
          element: 'id',
        }
      );

      applicationsForm(
        {
          action: 'input',
          value: location.state.name,
        },
        {
          form: 'songWaiting',
          element: 'name',
        }
      );

      applicationsForm(
        {
          action: 'multiselect',
          value: location.state.soundPrompt,
        },
        {
          form: 'songWaiting',
          element: 'soundPrompt',
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'songWaiting',
          element: 'company',
        },
        {
          label: location.state.company,
          value: location.state.companyId,
        }
      );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (arrayHeadquarterHook.length > 0 && songWaiting.length > 0 && arrayLocutionsHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'company') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  value: item3.id,
                  label: item3.name,
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref.element === 'soundPrompt') {
              item2.options = [];
              arrayLocutionsHook.forEach((item3) => {
                const locution = {
                  value: item3.id,
                  label: item3.description,
                };

                // const found = item2.options.some((e) => e.value === locution.value);
                // if (!found && locution.value !== undefined) {
                item2.options.push(locution);
                // }
              });
              // if (
              //   songWaitingForm?.soundPrompt !== undefined &&
              //   location.state?.arraySongWaiting !== undefined &&
              //   arraysAreEqual(location.state?.arraySongWaiting, songWaitingForm?.soundPrompt) === false
              // ) {
              //   location.state.arraySongWaiting = songWaitingForm?.arraySongWaiting;
              //   applicationsForm(
              //     {
              //       action: 'select-option',
              //       value: location.state?.arraySongWaiting,
              //     },
              //     {
              //       form: 'songWaiting',
              //       element: 'soundPrompt',
              //     }
              //   );
              // }
            }
            return item2;
          });
          return item;
        });
        if (crudType === 'edit' && (songWaitingForm.id === '' || songWaitingForm.name !== location.state.name)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' && songWaitingForm.name === location.state.name) {
          data.steps.map((item) => {
            item.input.map((item2) => {
              // let newValues = [];
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = songWaitingForm.name;
                  break;
                case 'company':
                  item2.options.filter((item3) => {
                    if (item3.value === songWaitingForm.company.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'soundPrompt':
                  // item2.defaultValues = [];
                  // item2.options.filter((item3) =>
                  //   songWaitingForm.soundPrompt.forEach((item4) => {
                  //     if (item3.value === item4.value) {
                  //       item2.defaultValues.push(item3);
                  //     }
                  //   })
                  // );
                  // if (arraysAreEqual(location.state?.soundPrompt, songWaitingForm?.soundPrompt) === false) {
                  //   location.state.soundPrompt = songWaitingForm?.soundPrompt;
                  //   applicationsForm(
                  //     {
                  //       action: 'multiselect',
                  //       value: location.state?.soundPrompt,
                  //     },
                  //     {
                  //       form: 'songWaiting',
                  //       element: 'soundPrompt',
                  //     }
                  //   );
                  // }
                  // item2.defaultValues = location.state.soundPrompt;
                  // item2.defaultValues = [];
                  // item2.options.filter((item3) =>
                  //   location.state.soundPrompt.forEach((item4) => {
                  //     if (item3.value === item4.value) {
                  //       item2.defaultValues.push(item3);
                  //     }
                  //     return false;
                  //   })
                  // );
                  if (item2.options) {
                    if (arraysAreEqual(location.state.soundPrompt, songWaitingForm?.soundPrompt) === false) {
                      location.state.soundPrompt = songWaitingForm?.soundPrompt;
                      applicationsForm(
                        {
                          action: 'multiselect',
                          value: location.state?.soundPrompt,
                        },
                        {
                          form: 'songWaiting',
                          element: 'soundPrompt',
                        }
                      );
                    }
                    return (item2.defaultValues = location.state?.soundPrompt);
                  }
                  break;

                // if (item2.options) {
                //   if (arraysAreEqual(location.state?.soundPrompt, songWaitingForm?.soundPrompt) === false) {
                //     location.state.soundPrompt = songWaitingForm?.soundPrompt;
                //     applicationsForm(
                //       {
                //         action: 'multiselect',
                //         value: location.state?.soundPrompt,
                //       },
                //       {
                //         form: 'songWaiting',
                //         element: 'soundPrompt',
                //       }
                //     );
                //   }
                //   return (item2.defaultValues = location.state?.soundPrompt);
                // }
                // break;
                default:
                  break;
              }

              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [loadingCounter, crudType]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getSongWaitingLink, `/${routesWide.applications}/${routesApplications.songWaiting}`, 'shortMarking', createSongWaiting);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getSongWaitingLink, `/${routesWide.applications}/${routesApplications.songWaiting}`, 'shortMarking', updateSongWaiting);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen arrScreen={['Música en espera']} data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} />}</>;
};

SongWaitingFormScreen.propTypes = { crudType: string };
