import React, { useEffect, useState } from 'react';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import { NavTableWrapper } from './NavTable.style';
import { array, func, number } from 'prop-types';

export const NavTable = ({ checkboxesActives, totalDocs }) => {
  const [, setSelectedItem] = useState([]);

  useEffect(() => {
    setSelectedItem(checkboxesActives);
  }, [checkboxesActives]);

  // const handleDelete = () => {
  //   deleteFunction(checkboxesActives);
  // };

  return (
    <NavTableWrapper className="navtable__container">
      <p className="navTable__selectedItems">
        {/* <p className="navTable__selectedItems">{selectedItem && selectedItem.length ? selectedItem.length : ' 0'} seleccionados</p> */}
        {checkboxesActives && Array.isArray(checkboxesActives) ? checkboxesActives.length : ' 0'} / {totalDocs?.toString()} seleccionado
        {checkboxesActives && checkboxesActives.length > 1 ? 's' : ''}
      </p>
      {/* <ReactTooltip className="tooltip" anchorId="delete-button" place="right" content="Borrar seleccionado/s" delayShow={1000} /> */}
    </NavTableWrapper>
  );
};

NavTable.propTypes = { totalDocs: number, checkboxesActives: array, deleteFunction: func };
