import styled from 'styled-components';

export const NumberingsNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: 100%;
  margin: auto;
  position: fixed;
  top: 70px;
  left: 70px;
  padding: 10px;
  margin-right: 5px;

  .contain__button-save {
    opacity: 1;
    height: 35px;
    position: fixed;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    top: 15px;
    right: 100px;
    z-index: 718;

    .cancel_button {
      color: lightgrey;
      /* background-color: #c142269a; */
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      font-weight: bold;
      margin-right: 20px;

      &:hover {
        color: white;
        background-color: #c14326;
      }
    }

    .save_button {
      color: lightgrey;
      background-color: #29903b;
      border-radius: 10px;
      width: 100px;
      font-size: 14px;
      font-weight: bold;
      margin-right: 20px;

      &:hover {
        color: white;
        background-color: #2faa43;
      }
    }
  }
  ${
    '' /* .listNumber__body {
    position: relative;
    top: 60px;
  } */
  }
`;
