import styled from 'styled-components';

export const WideWrapper = styled.div`
  box-sizing: border-box;
  ${'' /* height: 100%; */}
  width: 100%;
  height: 100%
    ${
      '' /* .widenav__container{
  } */
    };
`;
