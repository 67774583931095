import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const UsersScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50px;
  background-color: ${colors.primaryColor};
  border-radius: 15px;

  .users-container {
    background-color: ${colors.backgroundColor};
    border-radius: 15px;
  }

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .users__screen {
    position: fixed;
    ${'' /* left: 0; */}
    ${'' /* padding-left: 68px; */}
    width: 100%;
    height: 100%;
    border: 1px solid ${colors.primaryColor};
    background-color: ${colors.backgroundColor};
    border-radius: 15px 15px 0 0;
  }

  .modal__form__container {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: ${colors.primaryColor};
    top: 0;
    z-index: 9;
    opacity: 1;
  }

  .modal__form__body {
    width: 700px;
    height: auto;
    position: relative;
    background-color: white;
    top: 200px;
    margin: auto;
    z-index: 9;
    opacity: 1;
    border-radius: 15px;

    .stepForm {
      width: 100%;
      margin: auto;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      /* max-width: 600px; */
      row-gap: 13px;
      row-gap: 20px;
      margin: auto;
      max-width: unset;

      &__title {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        ${'' /* text-align: left; */}
        color: #0c3fc4;
        font-size: 14px;
        font-weight: bold;
        ${'' /* top: 50px; */}
        margin: auto;
        width: 100%;
      }
    }
    .form__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      text-align: left;
      color: #0c3fc4;
      font-size: 14px;
      font-weight: bold;
      top: 50px;
      margin: auto;
      width: 100%;
      background-color: ${colors.primaryColor};
      border-radius: 10px 10px 0 0px;
      padding: 5px;
    }

    .title__Form_title {
      color: white;
    }

    .stepForm__contain-typeInput {
      ${'' /* margin-top: 20px; */}
      display: flex;
      ${
        '' /* grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 8px; */
      }

      .stepForm__toggle {
        grid-column: span 4 / span 4;
      }

      .basicRow {
        grid-column: span 1;
      }

      .contain__select {
        grid-column: span 3;
        width: 100%;
      }
    }

    .form__body {
      margin-top: 0;
      top: 0;
      min-height: 220px;

      .icon__Form_title {
        color: white;
      }

      .contain__button-save {
        opacity: 1;
        width: 100%;
        height: 45px;
        position: relative;
        top: 20px;
        box-sizing: border-box;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 10px;
        color: white;
        z-index: unset;

        button {
          color: lightgrey;
          width: 75px;
          height: 35px;
          background-color: #29903b;
          border-radius: 10px;
          ${'' /* width: 120px; */}
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;
        }

        button:hover {
          color: white;
        }

        .cancel_button {
          background-color: ${colors.primaryColor};
          height: 35px;
          border: 1px solid #474849;

          &:hover {
            color: white;
            background-color: #cd3434;
          }
        }

        .save_button {
          color: lightgrey;
          background-color: #29903b;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
