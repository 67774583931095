import React, { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { RandomCodeGeneratorWrapper } from './RandomCodeGenerator.style';
import { func, string } from 'prop-types';

export const RandomCodeGenerator = ({ onChange, refForm }) => {
  const [apiKey, setApiKey] = React.useState('');

  const generateApiKey = () => {
    const apiKeyRandom = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    setApiKey(apiKeyRandom);
    onChange(apiKeyRandom, refForm);
  };

  useEffect(() => {
    generateApiKey();
  }, []);

  const handleCopy = async () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
    } else {
      navigator.clipboard.writeText(apiKey);
    }
  };
  return (
    <RandomCodeGeneratorWrapper
      isPassword={refForm === 'user'}
      // className="generateKey"
      className={refForm !== 'user' ? 'randomCodeGenerator' : 'input__password'}
      onClick={refForm !== 'user' ? handleCopy : null}
      id={7}
      data-tooltip-delay-hide={2000}>
      <h4>{refForm === 'user' ? 'Password' : 'APIKey'}</h4>
      <p>{apiKey}</p>
      {refForm !== 'user' ? <Tooltip className="tooltip" anchorId={7} place="top" content={'¡Copiado!'} events={refForm !== 'user' ? ['click'] : null} /> : null}
    </RandomCodeGeneratorWrapper>
  );
};

RandomCodeGenerator.propTypes = { onChange: func, refForm: string };
