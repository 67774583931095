import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { CardGird } from '../../../components/CardGrid/CardGird';
// import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
import { dataScreenGroupCategories } from '../../../data/dataOutbound';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { useSwal } from '../../../hooks/useSwal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './GroupCategories.css';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { IconEye } from '@tabler/icons-react';
import { number, string } from 'prop-types';

export const GroupCategoriesScreen = () => {
  const {
    //getCategoriesLinkHook,
    categoriesLinkHook,
    getCategoriesGroupHook,
    categoriesGroupHook,
    deleteCategoriesGroup,
    loading,
  } = useOutbound();
  const { typeSwal } = useSwal();
  const tableInstance = useRef(null);

  // const [selectedId, setSelectedId] = useState('');
  const [, setEnable] = useState(0);
  const [search, setSearch] = useState([]);

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const [objectCategoriesGroup, setObjectCategoriesGroup] = useState({
    arrayCategoriesGroup: [],
    title: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    getCategoriesGroupHook();
    setSearch([]);
  }, [getCategoriesGroupHook, setSearch]);

  useEffect(() => {
    let newName = categoriesLinkHook.filter((object) => object.CategoriesGroup?.id === categoriesGroupHook[0]?.id);
    setObjectCategoriesGroup({
      arrayCategoriesGroup: newName,
      title: categoriesGroupHook[0]?.description,
    });
    setEnable(categoriesGroupHook[0]?.id);
  }, [categoriesGroupHook, categoriesLinkHook]);

  const COLUMNS = [
    { Header: 'Nombre', accessor: 'CategoriesGroup.description' },
    {
      Header: 'Categorías',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => {
        const result = [];
        categoriesLinkHook?.find((object) => {
          if (object.Category.id === row.original?.Category.id) {
            result.push({ value: object.Category.id, label: object.Category.description });
          }
        });

        return (
          <>
            <ReactTooltip
              className="tooltip"
              clickable={true}
              anchorSelect={'#icon__viewMohLinks' + row.index}
              place="bottom"
              content={() =>
                result.map((cat) => {
                  return (
                    <div key={cat.value + row.index} className="tooltip__audio">
                      <span>
                        <br />
                        {cat.value} - {cat.label}
                      </span>
                      {/* <audio src={import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + sound.filename} controls /> */}
                    </div>
                  );
                })
              }
              delayShow={1000}
            />
            <IconEye type="Change" row={row} id={'icon__viewMohLinks' + row.index} className={'icon__view'} />
          </>
        );
      },
    },
  ];

  const handleClick = (item) => {
    let newGroupCategory = categoriesLinkHook.filter((object) => object.CategoriesGroup.id === item.id);
    setObjectCategoriesGroup({
      arrayCategoriesGroup: newGroupCategory,
      title: item.text,
    });

    setEnable(item.id);
  };

  const handleOpenFormCreate = () => {
    navigate('/outboundRoutes/groupCategories/create', {});
  };

  const handleOpenFormEdit = (item) => {
    handleClick(item);
    // navigate('/outboundRoutes/groupCategories/update', {
    //   state: objectCategoriesGroup,
    // });
    item.original.categories = objectCategoriesGroup.arrayCategoriesGroup.map((cat) => {
      return { value: cat.Category?.id, label: cat.Category?.description };
    });
    navigate('/outboundRoutes/groupCategories/update', {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await deleteCategoriesGroup(item);
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getCategoriesGroupHook();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const DATA = objectCategoriesGroup.arrayCategoriesGroup?.map(({ Category, CategoriesGroup }) => ({ Category, CategoriesGroup }));

  // const dataToCards = objectCategoriesGroup.arrayCategoriesGroup?.map((item) => ({
  //   title: item.Category?.description,
  //   icon: dataScreenGroupCategories.icon.flag,
  //   subtitle: item.Category?.prefix,
  //   icon2: dataScreenGroupCategories.icon.categories,
  //   text: item.CategoriesGroup?.description,
  //   icon3: dataScreenGroupCategories.icon.arrowRight,
  //   text2: item.Category?.createdAt,
  // }));

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) {
      item.function = handleOpenFormEdit;
    } else if (item.id === 2) {
      item.function = handleDelete;
    }
  });

  const { notFound } = dataScreenGroupCategories;
  const template = [
    {
      'Número Corto': '',
      Destino: '',
      Nombre: '',
      'Sede Id': '',
    },
  ];

  const dataExport = objectCategoriesGroup.arrayCategoriesGroup.map(({ nombre, num_corto, destino, Company }) => ({
    Nombre: nombre,
    'Num corto': num_corto,
    Destino: destino,
    Compañia: Company?.name,
  }));
  // const option = categoriesGroupHook.map((item) => ({
  //   text: item.description,
  //   id: item.id,
  // }));

  return (
    <div className="group-categories">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : categoriesGroupHook[0]?.status ? (
        <div className="distributionTable">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : (
        // <div className="distributionTable">
        // <DistributionTable
        // title={title}
        //     option={option}
        //     component={<CardGird data={dataToCards} />}
        //     selectedId={selectedId}
        //     setSelectId={setSelectedId}
        //     handleClick={handleClick}
        //     menuButtonsDropDown={menuButtonsDropDownTable}
        //     enable={enable}
        //   />
        // </div>
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            // setShowPassword={setShowPassword}
            // showPassword={showPassword}
            isNavTable={true}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
            setDataCard={() => {
              false;
            }}
          />
        </div>
      )}
    </div>
  );
};

GroupCategoriesScreen.propTypes = { row: string, index: number };
