import { useCallback, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  // addUserFormRolesAction,
  changeUserFormEmailAction,
  changeUserFormFirstSurnameAction,
  changeUserFormNameAction,
  changeUserFormPasswordAction,
  changeUserFormSecondSurnameAction,
  changeUserFormIdAction,
  removeUserFormRolesAction,
  clearUserFormAction,
  clearUserFormRolesAction,
  changeUserFormCompanyAction,
  changeUserFormDepartmentAction,
  // addUserFormCompanyAction,
  // addUserFormDepartmentAction,
  // changeUserFormRolesAction,
  // addUserFormAccessLevelAction,
  changeUserFormAccessLevelsAction,
  addUserFormRolesAction,
  addUserFormAccessLevelAction,
  addUserFormCompanyAction,
  changeUserFormRolesAction,
  changeUserFormisSIPExtension,
  changeUserFormSIPExtension,
  changeUserFormisVoicemail,
  changeUserFormisvoicemailEmail,
  changeUserFormvoicemailPIN,
  changeUserFormisTeams,
  changeUserFormisRainbow,
  changeUserRingTimeout,
  changeUserFormisCallForwardAction,
  changeUserFormCallForwardAction,
  changeUserFormNotAnswerAction,
  changeUserFormIsNotAnswerAction,
  addUserFormSIPExtensionAction,
  changeUserFormCaptureAction,
} from '../redux/actions/settingsScreen/user/actionCreators';
import { usersDeleteThunk, usersLoginThunk, usersCreateThunk, usersUpdateThunk, getUsersPermissionsThunk, changeUserPassword, uploadFileUsersThunk } from '../redux/thunks/usersThunks';
import { addExtensionCategoriesAction } from '../redux/actions/numbering/actionCreator';

const useUsers = () => {
  const [loading, setLoading] = useState(true);

  const userLogin = useSelector((login) => login.login);
  const usersFormReducer = useSelector((user) => user.usersFormReducer);

  const dispatch = useDispatch();
  const { getState } = useStore();

  const permissionsUser = useSelector((permissions) => permissions.arrayUsersPermission);

  const getPermissionsUser = useCallback(
    (user) => {
      dispatch(getUsersPermissionsThunk(user));
    },
    [dispatch]
  );

  const getLoginUser = async (user) => {
    let response = await dispatch(usersLoginThunk(user));
    return response;
  };

  const postCreateUser = useCallback(() => {
    const newUser = getState()['usersFormReducer'];

    let response = dispatch(usersCreateThunk(newUser, setLoading));
    return response;
  }, [dispatch]);

  const deleteUser = (user) => {
    let response = dispatch(usersDeleteThunk(user, setLoading));
    return response;
  };

  const updateUser = useCallback(() => {
    const newUser = getState()['usersFormReducer'];
    newUser.role = +newUser.role?.value;
    let response = dispatch(usersUpdateThunk(newUser, setLoading));
    return response;
  }, [dispatch]);

  const userForm = useCallback((arg, ref, inputValue) => {
    if (arg.action) {
      // let valueToDispatch = '';
      switch (ref.form) {
        case 'user':
          switch (arg.action) {
            case 'select-option':
              switch (ref.element) {
                case 'role':
                  dispatch(changeUserFormRolesAction(inputValue !== undefined && inputValue.value !== undefined ? Number(inputValue.value) : arg.value));
                  break;
                case 'rolesId':
                  dispatch(changeUserFormRolesAction(inputValue !== undefined && inputValue.value !== undefined ? Number(inputValue.value) : arg.value));
                  break;
                case 'companyId':
                  dispatch(changeUserFormCompanyAction(inputValue !== undefined && inputValue.value !== undefined ? inputValue.value : arg.value));
                  break;
                case 'Company':
                  dispatch(changeUserFormCompanyAction(inputValue && inputValue.value !== undefined ? inputValue.value : arg.value));
                  break;
                case 'departmentId':
                  dispatch(changeUserFormDepartmentAction(inputValue && inputValue.value !== undefined ? inputValue.value : { value: arg.value, label: arg.label }));
                  break;
                case 'accesslevelId':
                  dispatch(changeUserFormAccessLevelsAction(inputValue !== undefined && inputValue.value !== undefined ? Number(inputValue.value) : Number(arg.value)));
                  break;
                case 'isvoicemailEmail':
                  dispatch(changeUserFormisvoicemailEmail(arg.value));
                  break;
                case 'voicemailPIN':
                  dispatch(changeUserFormvoicemailPIN(arg.value));
                  break;
                case 'isTeams':
                  dispatch(changeUserFormisTeams(arg.value));
                  break;
                case 'isRainbow':
                  dispatch(changeUserFormisRainbow(arg.value));
                  break;
                case 'categories':
                  dispatch(addExtensionCategoriesAction(arg.value));
                  break;
                case 'SIPExtension':
                  dispatch(addUserFormSIPExtensionAction(inputValue));
                  break;

                default:
                  break;
              }
              break;
            case 'multiselect':
              switch (ref.element) {
                case 'SIPExtension':
                  dispatch(changeUserFormSIPExtension(inputValue));
                  break;
                case 'captureId':
                  dispatch(changeUserFormCaptureAction(arg.value ? { value: arg.value, label: arg.label } : inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'toggle':
              switch (ref.element) {
                case 'isSIPExtension':
                  dispatch(changeUserFormisSIPExtension(arg.value));
                  break;
                case 'isVoicemail':
                  dispatch(changeUserFormisVoicemail(arg.value));
                  break;
                case 'isvoicemailEmail':
                  dispatch(changeUserFormisvoicemailEmail(arg.value));
                  break;
                case 'isTeams':
                  dispatch(changeUserFormisTeams(arg.value));
                  break;
                case 'isRainbow':
                  dispatch(changeUserFormisRainbow(arg.value));
                  break;
                case 'isCallForward':
                  dispatch(changeUserFormisCallForwardAction(arg.value));
                  break;
                case 'isNotAnswer':
                  dispatch(changeUserFormIsNotAnswerAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            case 'remove-value':
              dispatch(removeUserFormRolesAction(arg.removedValue));
              break;
            case 'clearForm':
              dispatch(clearUserFormAction());
              break;
            case 'clear':
              dispatch(clearUserFormRolesAction());
              break;
            case 'toggleButton':
              switch (ref.element) {
                case 'isSIPExtension':
                  dispatch(changeUserFormisSIPExtension(arg.value));
                  break;
                case 'isVoicemail':
                  dispatch(changeUserFormisVoicemail(arg.value));
                  break;
                case 'isvoicemailEmail':
                  dispatch(changeUserFormisvoicemailEmail(arg.value));
                  break;
                case 'isTeams':
                  dispatch(changeUserFormisTeams(arg.value));
                  break;
                case 'isRainbow':
                  dispatch(changeUserFormisRainbow(arg.value));
                  break;
                default:
                  break;
              }
              break;

            case 'select':
              switch (ref.element) {
                case 'SIPExtension':
                  dispatch(changeUserFormSIPExtension(inputValue));
                  break;
                default:
                  break;
              }
              break;
            case 'input':
              switch (ref.element) {
                case 'firstname':
                  dispatch(changeUserFormNameAction(arg.value));
                  break;
                case 'lastname':
                  dispatch(changeUserFormFirstSurnameAction(arg.value));
                  break;
                case 'secondname':
                  dispatch(changeUserFormSecondSurnameAction(arg.value));
                  break;
                case 'email':
                  dispatch(changeUserFormEmailAction(arg.value));
                  break;
                case 'password':
                  dispatch(changeUserFormPasswordAction(arg.value));
                  break;
                case 'accesslevelId':
                  dispatch(addUserFormAccessLevelAction(arg.value));
                  break;
                case 'companyId':
                  dispatch(addUserFormCompanyAction(arg.value));
                  break;
                case 'Company':
                  dispatch(changeUserFormCompanyAction(arg.value));
                  break;
                case 'departmentId':
                  dispatch(changeUserFormDepartmentAction(arg.value));
                  break;
                case 'Department':
                  dispatch(changeUserFormDepartmentAction(arg.value));
                  break;
                case 'role':
                  dispatch(addUserFormRolesAction(arg.value));
                  break;
                case 'rolesId':
                  dispatch(addUserFormRolesAction(arg.value));
                  break;
                case 'voicemailPIN':
                  dispatch(changeUserFormvoicemailPIN(arg.value));
                  break;
                case 'ringTimeout':
                  dispatch(changeUserRingTimeout(arg.value));
                  break;
                case 'SIPExtensionId':
                  dispatch(changeUserFormSIPExtension(arg.value));
                  break;
                case 'callForward':
                  dispatch(changeUserFormCallForwardAction(arg.value));
                  break;
                case 'notAnswer':
                  dispatch(changeUserFormNotAnswerAction(arg.value));
                  break;
                default:
                  break;
              }
              break;
            // case 'select-option':

            //   break;
            case 'id':
              dispatch(changeUserFormIdAction(arg.value));
              break;
          }
          break;
        default:
          break;
      }
    }
  }, []);

  const changePassword = useCallback(
    (newPassword, id) => {
      const newPasswordUser = {
        id,
        newPassword,
      };
      let response = dispatch(changeUserPassword(newPasswordUser));
      return response;
    },
    [dispatch]
  );

  const uploadFileUser = useCallback(
    (file) => {
      let response = dispatch(uploadFileUsersThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  return {
    userLogin,
    getLoginUser,
    usersFormReducer,
    postCreateUser,
    deleteUser,
    updateUser,
    userForm,
    changePassword,
    uploadFileUser,
    // permissionsUser
    permissionsUser,
    getPermissionsUser,

    loading,
  };
};

export default useUsers;
