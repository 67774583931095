import { useCallback, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import {
  addCategoryFormGroup,
  addOutboundRoutesTrunksFormHeadquarters,
  changeCategoriesFormIdAction,
  changeCategoriesFormNameAction,
  changeCategoriesFormPrefixAction,
  changeCategoriesGroupFormName,
  changeOutboundRoutesTrunksFormDescription,
  changeOutboundRoutesTrunksFormDomain,
  changeOutboundRoutesTrunksFormName,
  changeOutboundRoutesTrunksFormPassword,
  changeOutboundRoutesTrunksFormPrefix,
  changeOutboundRoutesTrunksFormRegister,
  changeOutboundRoutesTrunksFormServer,
  changeOutboundRoutesTrunksFormUser,
  changeTrunksFormId,
  changeTrunksFormSipPeerId,
  clearCategoriesFormAction,
  clearCategoryFormGroup,
  removeCategoryFormGroup,
  removeOutboundRoutesTrunksFormHeadquarters,
  removeTrunksFormAction,
  changeOutboundRoutesTrunksFormIpaddr,
  changeOutboundRoutesTrunksFormPort,
  changeOutboundRoutesTrunksFormContext,
  changeOutboundRoutesTrunksFormPermit,
  changeOutboundRoutesTrunksFormDeny,
  changeOutboundRoutesTrunksFormTransport,
  changeOutboundRoutesTrunksFormNat,
  changeOutboundRoutesTrunksFormDFMDMode,
  changeOutboundRoutesTrunksFormAllow,
  changeOutboundRoutesTrunksFormDisallow,
  changeOutboundRoutesTrunksFormQualify,
  changeCategoriesGroupFormIdAction,
  addCategoriesOutboundRouteFormAction,
  addCategoriesOutboundRouteFailFormAction,
  removeCategoriesOutboundRouteFormAction,
  removeCategoriesOutboundRouteFailFormAction,
  clearMultiselectCategoriesGroupFormAction,
  clearTrunksFormDisallowAction,
  clearTrunksFormAllowAction,
  clearTrunksFormNatAction,
  changeTrunksFormKamDispatcherIdAction,
  clearCategoriesOutboundRouteFormAction,
  clearCategoriesOutboundRouteFailFormAction,
  clearTrunksFormCompanyAction,
  clearTrunksFormTransportAction,
  clearTrunksFormDFMDModeAction,
} from '../redux/actions/outboundRoutes/actionCreators';

import {
  createCategoriesGroupThunk,
  createCategoriesThunk,
  createOutboundRoutesTrunksThunk,
  deleteCategoriesGroupThunk,
  deleteCategoryThunk,
  deleteOutboundRoutesTrunksThunk,
  getCategoriesGroupAndLinkThunk,
  getCategoriesThunk,
  getOutboundRoutesGetTrunksThunk,
  updateCategoriesGroupThunk,
  updateCategoryThunk,
  updateOutboundRoutesTrunksRegisterThunk,
  updateOutboundRoutesTrunksThunk,
  uploadFileCategoryThunk,
  uploadFileTrunksThunk,
} from '../redux/thunks/outboundRoutesThunks';

export const useOutbound = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const [loading, setLoading] = useState(true);

  const state = getState()['outboundRoutesFormReducer'];

  //use outboundRoutesTrunks
  const arrayOutboundRoutesTrunksHook = useSelector((outboundRoutes) => outboundRoutes.arrayOutboundRoutesTrunks);
  const loadListTrunks = useSelector((outboundRoutes) => outboundRoutes.loadListTrunksReducer);

  const getOutboundRoutesTrunks = useCallback(() => {
    dispatch(getOutboundRoutesGetTrunksThunk(setLoading));
  }, [dispatch]);

  const createOutboundRoutesTrunks = useCallback(() => {
    let trunk = getState()['outboundRoutesFormReducer'];
    let response = dispatch(createOutboundRoutesTrunksThunk(trunk, setLoading));
    return response;
  }, [dispatch, getState]);

  const outboundRoutesForm = useCallback((arg, ref, inputValue) => {
    if (arg.action) {
      switch (ref.form) {
        case 'categories':
          switch (arg.action) {
            case 'clearForm':
              dispatch(clearCategoriesFormAction());
              break;
            case 'clear':
              if (ref.element === 'outboundRoutesId') {
                dispatch(clearCategoriesOutboundRouteFormAction());
              }
              if (ref.element === 'failOverOutboundRoutesId') {
                dispatch(clearCategoriesOutboundRouteFailFormAction());
              }
              break;
            case 'select-option':
              if (ref.element === 'outboundRoutesId') {
                dispatch(addCategoriesOutboundRouteFormAction(inputValue));
              }
              if (ref.element === 'failOverOutboundRoutesId') {
                dispatch(addCategoriesOutboundRouteFailFormAction(inputValue));
              }
              break;
            case 'remove-value':
              if (ref.element === 'outboundRoutesId') {
                dispatch(removeCategoriesOutboundRouteFormAction(arg.removeValue.value));
              }
              if (ref.element === 'failOverOutboundRoutesId') {
                dispatch(removeCategoriesOutboundRouteFailFormAction(arg.removeValue.value));
              }
              break;
            case 'input':
              if (ref.element === 'name') {
                dispatch(changeCategoriesFormNameAction(arg.value));
              }

              if (ref.element === 'prefix') {
                dispatch(changeCategoriesFormPrefixAction(arg.value));
              }
              if (ref.element === 'id') {
                dispatch(changeCategoriesFormIdAction(arg.value));
              }

              break;

            default:
              break;
          }
          break;
        case 'trunks':
          switch (arg.action) {
            case 'clearForm':
              dispatch(removeTrunksFormAction());
              break;
            case 'clear':
              if (ref.element === 'nat') {
                dispatch(clearTrunksFormNatAction());
              }
              if (ref.element === 'allow') {
                dispatch(clearTrunksFormAllowAction());
              }
              if (ref.element === 'disallow') {
                dispatch(clearTrunksFormDisallowAction());
              }
              if (ref.element === 'companyId') {
                dispatch(clearTrunksFormCompanyAction());
              }
              if (ref.element === 'transport') {
                dispatch(clearTrunksFormTransportAction());
              }
              if (ref.element === 'dtmfmode') {
                dispatch(clearTrunksFormDFMDModeAction());
              }

              break;
            case 'select-option':
              if (ref.element === 'companyId') {
                dispatch(addOutboundRoutesTrunksFormHeadquarters(inputValue.value));
              }
              if (ref.element === 'nat') {
                dispatch(changeOutboundRoutesTrunksFormNat(inputValue));
              }
              if (ref.element === 'allow') {
                dispatch(changeOutboundRoutesTrunksFormAllow(inputValue));
              }
              if (ref.element === 'disallow') {
                dispatch(changeOutboundRoutesTrunksFormDisallow(inputValue));
              }
              if (ref.element === 'transport') {
                dispatch(changeOutboundRoutesTrunksFormTransport(inputValue));
              }
              if (ref.element === 'dtmfmode') {
                dispatch(changeOutboundRoutesTrunksFormDFMDMode(inputValue));
              }

              break;
            case 'remove-value':
              if (ref.element === 'companyId') {
                dispatch(removeOutboundRoutesTrunksFormHeadquarters(arg.removedValue.value));
              }
              if (ref.element === 'nat') {
                dispatch(changeOutboundRoutesTrunksFormNat(inputValue));
              }
              if (ref.element === 'allow') {
                dispatch(changeOutboundRoutesTrunksFormAllow(inputValue));
              }
              if (ref.element === 'disallow') {
                dispatch(changeOutboundRoutesTrunksFormDisallow(inputValue));
              }
              break;
            case 'id':
              if (ref.element === 'id') {
                dispatch(changeTrunksFormId(arg.value));
              }
              if (ref.element === 'sipPeerId') {
                dispatch(changeTrunksFormSipPeerId(arg.value));
              }
              if (ref.element === 'kamDispatcherId') {
                dispatch(changeTrunksFormKamDispatcherIdAction(arg.value));
              }
              break;
            case 'input':
              // Layout 1
              if (ref.element === 'name') {
                dispatch(changeOutboundRoutesTrunksFormName(arg.value));
              }
              if (ref.element === 'description') {
                dispatch(changeOutboundRoutesTrunksFormDescription(arg.value));
              }

              // CompanyId?

              if (ref.element === 'notPassword') {
                dispatch(changeOutboundRoutesTrunksFormPassword(arg.value));
              }
              if (ref.element === 'fromDomain') {
                dispatch(changeOutboundRoutesTrunksFormDomain(arg.value));
              }
              if (ref.element === 'server') {
                dispatch(changeOutboundRoutesTrunksFormServer(arg.value));
              }

              // Layout 2
              if (ref.element === 'userDefault') {
                dispatch(changeOutboundRoutesTrunksFormUser(arg.value));
              }
              if (ref.element === 'outboundPrefix') {
                dispatch(changeOutboundRoutesTrunksFormPrefix(arg.value));
              }
              if (ref.element === 'Ipaddr') {
                dispatch(changeOutboundRoutesTrunksFormIpaddr(arg.value));
              }
              if (ref.element === 'port') {
                dispatch(changeOutboundRoutesTrunksFormPort(arg.value));
              }
              if (ref.element === 'context') {
                dispatch(changeOutboundRoutesTrunksFormContext(arg.value));
              }

              //Layout 3
              if (ref.element === 'permit') {
                dispatch(changeOutboundRoutesTrunksFormPermit(arg.value));
              }
              if (ref.element === 'deny') {
                dispatch(changeOutboundRoutesTrunksFormDeny(arg.value));
              }
              // if (ref.element === 'transport') {
              //     dispatch(changeOutboundRoutesTrunksFormTransport(arg.value))
              // }

              // if (ref.element === 'dtmfmode') {
              //     dispatch(changeOutboundRoutesTrunksFormDFMDMode(arg.value))
              // }
              // if (ref.element === 'allow') {
              //     dispatch(changeOutboundRoutesTrunksFormAllow(arg.value))
              // }

              //Layout 4
              // if (ref.element === 'disallow') {
              //     dispatch(changeOutboundRoutesTrunksFormDisallow(arg.value))
              // }
              if (ref.element === 'qualify') {
                dispatch(changeOutboundRoutesTrunksFormQualify(arg.value));
              }

              break;
            case 'toggle':
              if (ref.element === 'regServer') {
                dispatch(changeOutboundRoutesTrunksFormRegister(arg.value));
              }
              break;
            default:
              break;
          }
          break;
        case 'groupCategories':
          switch (arg.action) {
            case 'clear':
              if (arg.removedValues) {
                dispatch(clearMultiselectCategoriesGroupFormAction());
              } else {
                dispatch(clearCategoryFormGroup());
              }
              break;
            case 'input':
              dispatch(changeCategoriesGroupFormName(arg.value));
              break;
            case 'select-option':
              dispatch(addCategoryFormGroup(arg.value ? arg.value : arg.option));
              break;
            case 'multiselect':
              dispatch(addCategoryFormGroup(arg.value ? arg.value : arg.option));
              break;
            case 'remove-value':
              dispatch(removeCategoryFormGroup(arg.removedValue));
              break;
            case 'id':
              dispatch(changeCategoriesGroupFormIdAction(arg.value));
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  });

  //update only register
  const updateOutboundRoutesRegisterTrunks = useCallback(
    (register) => {
      dispatch(updateOutboundRoutesTrunksRegisterThunk(register, setLoading));
    },
    [dispatch]
  );

  //update all fields
  const updateOutboundRoutesTrunks = useCallback(() => {
    let trunksEdit = getState()['outboundRoutesFormReducer'];
    let response = dispatch(updateOutboundRoutesTrunksThunk(trunksEdit, setLoading));
    return response;
  }, [dispatch]);

  const outboundRoutesFormReducer = useSelector((outboundRoutes) => outboundRoutes.outboundRoutesFormReducer);

  const deleteOutboundRoutesTrunks = useCallback(
    (trunks) => {
      let response = dispatch(deleteOutboundRoutesTrunksThunk(trunks, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileTrunks = useCallback(
    (file) => {
      let response = dispatch(uploadFileTrunksThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //categoriesLink

  const categoriesLinkHook = useSelector((categories) => categories.getCategoriesLinkReducer);

  // const getCategoriesLinkHook = useCallback(() => {
  //     dispatch(getCategoriesLinkThunk(setLoading))
  // }, [dispatch])

  //categoriesGroup

  const categoriesGroupHook = useSelector((categoriesGroup) => categoriesGroup.getCategoriesGroupReducer);

  const outboundRoutesFormCategoriesGroup = useSelector((item) => item.categoriesGroupFormReducer);

  const getCategoriesGroupHook = useCallback(() => {
    dispatch(getCategoriesGroupAndLinkThunk(setLoading));
  }, [dispatch]);

  const createCategoriesGroup = useCallback(() => {
    let categoryGroup = getState()['categoriesGroupFormReducer'];
    let response = dispatch(createCategoriesGroupThunk(categoryGroup, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteCategoriesGroup = useCallback(
    (categoryGroup) => {
      let response = dispatch(deleteCategoriesGroupThunk(categoryGroup, setLoading));
      return response;
    },
    [dispatch]
  );

  const updateCategoriesGroup = useCallback(() => {
    const categoriesGroup = getState()['categoriesGroupFormReducer'];
    let response = dispatch(updateCategoriesGroupThunk(categoriesGroup, setLoading));
    return response;
  }, [dispatch]);

  //categories

  const categoriesHook = useSelector((categories) => categories.getCategoriesReducer);

  const categoriesFormReducer = useSelector((category) => category.categoriesFormReducer);
  const loadListCategories = useSelector((category) => category.loadListCategoriesReducer);

  const getCategoriesHook = useCallback(() => {
    dispatch(getCategoriesThunk(setLoading));
  }, [dispatch]);

  const createCategories = useCallback(() => {
    let category = getState()['categoriesFormReducer'];
    let response = dispatch(createCategoriesThunk(category, setLoading));
    return response;
  }, [dispatch, getState]);

  const updateCategories = useCallback(() => {
    let category = getState()['categoriesFormReducer'];
    let response = dispatch(updateCategoryThunk(category, setLoading));
    return response;
  }, [dispatch, getState]);

  const deleteCategories = useCallback(
    (category) => {
      let response = dispatch(deleteCategoryThunk(category, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileCategories = useCallback(
    (file) => {
      let response = dispatch(uploadFileCategoryThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  return {
    state,
    //outboundRoutesTrunks
    arrayOutboundRoutesTrunksHook,
    getOutboundRoutesTrunks,
    createOutboundRoutesTrunks,
    updateOutboundRoutesRegisterTrunks,
    updateOutboundRoutesTrunks,
    outboundRoutesFormReducer,
    deleteOutboundRoutesTrunks,
    uploadFileTrunks,
    loadListTrunks,
    //categoriesLink
    categoriesLinkHook,
    // getCategoriesLinkHook,
    //categoriesGroup
    categoriesGroupHook,
    getCategoriesGroupHook,
    outboundRoutesFormCategoriesGroup,
    createCategoriesGroup,
    deleteCategoriesGroup,
    updateCategoriesGroup,
    //categories
    categoriesHook,
    getCategoriesHook,
    createCategories,
    categoriesFormReducer,
    updateCategories,
    deleteCategories,
    uploadFileCategories,
    loadListCategories,

    //formOutboundRoutes
    outboundRoutesForm,

    loading,
  };
};
