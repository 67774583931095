import React, { useState } from 'react';
// import { AnimatePresence } from 'framer-motion';
import { useValidationForm } from '../../hooks/useValidationForm';
import { StepForm } from '../StepForm/StepForm';
import { FormScreenWrapper } from './FormScreen.style';
import { array, bool, func, object, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { FormTitle } from './FormTitle/FormTitle';

export const FormScreen = ({ data, dataTabs, isEditMode, setCheckboxesActives, checkboxesActives, onSubmit, form, crudType, maxwidth, arrScreen, reducerForm, dataModalButtonToTable }) => {
  const [selectedId, setSelectedId] = useState(1);
  const [activeDIV, setActiveDIV] = useState(0);
  const { validationForm } = useValidationForm();

  const handleNextStep = () => {
    setActiveDIV(activeDIV + 1);
    setSelectedId(selectedId + 1);
  };

  const [stepForm, setStepForm] = useState({
    inputFull: false,
    button: 0,
  });

  const [inputValue, setInputValue] = useState({ value: '', label: '' });
  const [errorInput, setErrorInput] = useState(false);
  const [validationNormalInput, setValidationNormalInput] = useState([]);
  const [inputValueUnique, setInputValueUnique] = useState([]);

  const [validateReducer, setValidateReducer] = useState([]);

  // const variants = {
  //   initial: {
  //     opacity: 0,
  // y: '0px',
  // height: '0px',
  // transition: {
  //   duration: 0.4,
  //   ease: 'easeInOut',
  //   opacity: {
  //     delay: 0.4,
  //   },
  // },
  // },
  // animate: {
  //   opacity: 1,
  // // y: '0px',
  // // height: '100%',
  // transition: {
  //   duration: 0.4,
  //   ease: 'easeInOut',
  //   opacity: {
  //     delay: 0.4,
  //   },
  // },
  // },
  // exit: {
  //   opacity: 0,
  // // y: '0px',
  // // height: '100%',
  // transition: {
  //   duration: 0.4,
  //   ease: 'easeInOut',
  //   opacity: {
  //     delay: 0.4,
  //   },
  // },
  //   },
  // };

  const checkForm = () => {
    const invalidInput = [];

    Object.entries(inputValue).map((item) =>
      data.steps.map((step) =>
        step.input.forEach((item2) => {
          if (item2.ref?.element === item[0]) {
            if (item2.required?.validation) {
              let regexExpression = item2.required.regex;
              let validate = regexExpression.test(item[1]);
              if (!validate || item[1] === undefined) {
                invalidInput.push(item);
              }
            }
          }
        })
      )
    );
    if (invalidInput.length !== 0) {
      const invalidInputKeys = invalidInput.map((item) => item[0]);
      setValidationNormalInput(invalidInputKeys);
      setErrorInput(true);
    } else {
      setErrorInput(false);
      setValidationNormalInput([]);
      if (selectedId === data.steps.length || crudType === 'edit') {
        let responseReducerValidate = [];
        if (reducerForm !== undefined) {
          responseReducerValidate = validationForm(reducerForm, data);
          setValidateReducer(responseReducerValidate);
        }

        if (crudType === 'assignRule' || (responseReducerValidate?.length === 0 && inputValueUnique?.length === 0)) {
          onSubmit();
        }
      } else {
        if (inputValueUnique?.length === 0) handleNextStep();
      }
    }
  };

  const ref = React.createRef();

  const location = useLocation();
  const isUpdate = location.pathname.includes('update');

  const handleCancel = () => {
    history.back();
  };

  const handleClassForm = () => {
    let newClass = '';
    switch (arrScreen[0]) {
      case 'Usuario':
        newClass = 'form__body__users';
        break;
      case 'Preferencias':
        newClass = 'form__body__prefs';
        break;
      case 'Cola':
        newClass = 'form__body__queue';
        break;

      default:
        newClass = crudType === 'assignRule' ? 'modal__numberList__body' : 'form__body';

        break;
    }

    return newClass;
  };

  const isFormTitleVisible = () => {
    let newStatus = false;
    switch (arrScreen[0]) {
      case 'Dispositivo':
        newStatus = crudType !== 'edit' && crudType !== 'create';
        break;
      case 'Preferencias':
        newStatus = false;
        break;
      default:
        newStatus = true;
        break;
    }

    return newStatus;
  };

  const isSectionVisible = (item) => {
    const newState = item.title === 'Añadir' && isEditMode !== true ? false : true;

    return newState;
  };

  const arrayToProcess = data.steps ? data.steps : data.input;

  return (
    <FormScreenWrapper max-width={maxwidth} className={handleClassForm()}>
      <div className="container__cards">
        {isFormTitleVisible() ? <FormTitle formTitle={arrScreen} type={crudType} /> : null}

        {arrayToProcess.map((itemInput, index) =>
          isSectionVisible(itemInput) && (index === activeDIV || isUpdate) ? (
            <div
              className={activeDIV > index || (itemInput?.label === 'Allow' && location.state.typeExtension !== 'extension') ? 'contain__inputCard-component hidden' : 'contain__inputCard-component'}
              key={index}>
              {itemInput.title !== undefined && crudType !== 'assignUserDispo' && crudType !== 'createUserDispo' ? (
                <div className="section__container">
                  <span className="section__name">{itemInput.title}</span>
                </div>
              ) : null}
              {selectedId >= itemInput.id || crudType === 'edit' ? (
                <StepForm
                  dataForm={itemInput}
                  dataTabs={dataTabs}
                  isOpen={true}
                  layoutId={itemInput.id}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  handleNextStep={handleNextStep}
                  ref={ref}
                  form={form}
                  crudType={crudType}
                  selectedId={selectedId}
                  stepForm={stepForm}
                  setStepForm={setStepForm}
                  errorInput={errorInput}
                  setErrorInput={setErrorInput}
                  validationNormalInput={validationNormalInput}
                  setValidationNormalInput={setValidationNormalInput}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  checkForm={checkForm}
                  allSteps={data.steps}
                  maxwidth={maxwidth}
                  arrScreen={arrScreen}
                  inputValueUnique={inputValueUnique}
                  setInputValueUnique={setInputValueUnique}
                  validateReducer={validateReducer}
                  setValidateReducer={setValidateReducer}
                  reducerForm={reducerForm}
                  data={data}
                  dataModalButtonToTable={dataModalButtonToTable}
                />
              ) : null}
            </div>
          ) : null
        )}
        <div className="contain__button-save">
          <button onClick={handleCancel} className={'cancel_button'}>
            Cancelar
          </button>
          <button onClick={checkForm} className={crudType === 'edit' ? 'save_button' : selectedId === data.steps?.length ? 'save_button' : 'next_step'}>
            {crudType === 'edit' ? 'Guardar' : selectedId === data.steps?.length ? data.textButton : 'Siguiente paso'}
          </button>
        </div>
      </div>
    </FormScreenWrapper>
  );
};

FormScreen.propTypes = {
  data: object,
  onSubmit: func,
  form: func,
  crudType: string,
  maxwidth: string,
  arrScreen: array,
  reducerForm: object,
  dataTabs: array,
  title: bool,
  isEditMode: bool,
  dataModalButtonToTable: object,
  setCheckboxesActives: func,
  checkboxesActives: array,
};
