import Axios from 'axios';

import {
  getNumberingListAction,
  updateNumberingListAction,
  deleteNumberingListAction,
  getExtensionAction,
  createExtensionAction,
  updateExtensionAction,
  createNumberingListAction,
  getDdiLinkAction,
} from '../actions/numbering/actionCreator';

// const urlApi = import.meta.env.VITE_APP_URL;
const urlBack = import.meta.env.VITE_BACK_URL;
const token = import.meta.env.VITE_APP_TOKEN;

export const getNumberingListThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const arrayNumbering = await Axios.get(`${urlBack}numbering/numberingList`, {
      headers: {
        Authorization: token,
        //apiKey: 'po3oef9aoc7t35fglvpxl',
      },
    });
    const ddiLink = await Axios.get(`${urlBack}numbering/numberingList/link`, {
      headers: {
        Authorization: token,
        //apiKey: 'po3oef9aoc7t35fglvpxl',
      },
    });

    dispatch(getNumberingListAction(arrayNumbering.data));
    dispatch(getDdiLinkAction(ddiLink.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getExtensionThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayNumbering = await Axios.get(`${urlBack}numbering/extensions`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch(getExtensionAction(arrayNumbering.data));
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const createExtensionThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/extensions/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createExtensionAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteExtensionThunk = (payload, setLoading) => async () => {
  setLoading(true);

  try {
    const response = await Axios.delete(`${urlBack}numbering/extensions/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateExtensionThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/extensions/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateExtensionAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const changeExtensionPasswordThunk = (newPassword) => async () => {
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/extensions/updatePassword`,
      { data: newPassword },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(
      `${urlBack}numbering/numberingList/create`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(
      `${urlBack}numbering/numberingList/update`,
      {
        data: payload,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteNumberingListThunk = (payload, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlBack}numbering/numberingList/delete`, {
      headers: {
        Authorization: token,
      },
      data: payload,
    });
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(deleteNumberingListAction(payload));
    }
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const getSipPasswordThunk = async (extension) => {
  try {
    const response = await Axios.post(
      `${urlBack}numbering/extensions/password`,
      { data: extension },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) return response.data;
    else if (response.status === 404) return 'Not defined';
  } catch (error) {
    return error;
  }
};

export const updateFileExtensionThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}numbering/extensions/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    setLoading(false);
    return response;
  } catch (err) {
    return err;
  }
};

export const uploadFileListNumberingThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    const response = await Axios.post(`${urlBack}numbering/numberingList/updateFile`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: token } });
    return response;
  } catch (err) {
    return err;
  }
};
