import React, { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { motion, AnimatePresence } from 'framer-motion';

import { MultiFormPagesWrapper } from './MultiFormPages.style';
import useNumbering from '../../hooks/useNumbering';
import { BodyMultiForm } from './BodyMultiForm/BodyMultiForm';
import { useDispatch } from 'react-redux';
import { addDestinationCalendarAction, removeDestinationCalendarAction } from '../../redux/actions/numbering/actionCreator';
import usePreferences from '../../hooks/usePreferences';
import { array, func, object } from 'prop-types';

export const MultiFormPages = ({
  data,
  form,
  setInputValue,
  setErrorInput,
  setValidationNormalInput,
  validationNormalInput,
  allSteps,
  validateReducer,
  // setValidateReducer,
  reducerForm,
  firstData,
}) => {
  let dispatch = useDispatch();
  const { calendarArrayHook } = usePreferences();

  const [pageSelected, setPageSelected] = useState(1);
  const [pages, setPages] = useState(data.pages);
  const { FormListNumberingReducer } = useNumbering();
  const [removedDestinationOpen, setRemovedDestinationOpen] = useState(0);

  let destinationCalendars = FormListNumberingReducer.destinationCalendar;

  useEffect(() => {
    const newPages = destinationCalendars.map((calendar, index) => ({
      id: calendar.id,
      text: calendar && calendar.calendar && calendar.calendar.label && calendar.calendar.label !== '' ? calendar.calendar.label : `Calendario ${index + 1}`,
      dataPage: pages[index]?.dataPage || [], // Use existing dataPage value if it exists
    }));

    newPages.push({ text: '+' });

    setPages(newPages);
  }, [destinationCalendars]);

  let newDataForm = data.dataRef;

  let nameUniqueCalendar = [];

  destinationCalendars.forEach((item) => {
    if (item && item.calendar && item.calendar.value !== '') {
      nameUniqueCalendar.push(item.calendar);
    }
  });

  newDataForm.forEach((obj) => {
    obj.input?.forEach((obj2) => {
      if (obj2.ref.element === 'calendar') {
        calendarArrayHook.forEach((item2) => {
          if (!item2.status) {
            item2.forEach((item3) => {
              const calendarName = {
                label: item3.CalendarName.name,
                value: item3.CalendarName.id.toString(),
              };
              const found = obj2.options.some((e) => e.value === calendarName.value);
              const foundUnique = nameUniqueCalendar.some((e) => e.value === calendarName.value);
              if (!found && !foundUnique) {
                obj2.options.push(calendarName);
              }
              if (foundUnique) {
                obj2.options = obj2.options.filter((e) => e.value !== calendarName.value);
              }
            });
          }
        });
      }
    });
  });

  useEffect(() => {}, [pages, pageSelected]);

  if (pages[0] && pages[0].dataPage && pages[0].dataPage.length === 0) {
    data.pages[0].dataPage = data.dataRef;
  }

  const addPage = () => {
    if (pages && pages.lenght && pages.length > 6) return;
    let lastPage = pages[pages.length - 2];

    const newPages = [
      ...pages.slice(0, pages.length - 1),
      {
        id: lastPage?.id + 1,
        text: `Calendario ${lastPage?.id + 1}`,
        dataPage: newDataForm,
      },
      { text: '+' },
    ];

    setPages(newPages);
    setPageSelected(lastPage.id + 1);
    dispatch(addDestinationCalendarAction(lastPage.id + 1));
  };

  const handlePage = (index) => {
    setPageSelected(index);
  };

  const deleteDestination = (item) => {
    let indexPages = pages.findIndex((obj) => obj.id === item.id);
    if (indexPages >= 0) {
      const newObject = [...pages];
      newObject.splice(indexPages, 1);
      setPages(newObject);
    }
    dispatch(removeDestinationCalendarAction(item.id));
  };

  const idPage = validateReducer.map((obj) => obj.idPage);

  return (
    <MultiFormPagesWrapper>
      <div className="multiFormPages">
        <div className="multiFormPages__header">
          {pages.map((item, index) =>
            item.text === '+' ? (
              <div key={index} className="multiFormPages__header__title plus" onClick={() => addPage(index + 1)}>
                <p className={'multiFormPages__header__title__item-add'}> {item.text}</p>
              </div>
            ) : (
              <div
                key={index}
                className={`multiFormPages__header__title ${pageSelected === item.id ? 'active' : 'inactive'}  ${
                  pageSelected === item.id && idPage.includes(item.id) ? 'errorPageActive' : idPage.includes(item.id) ? 'errorPageInactive' : ''
                } `}
                onClick={() => handlePage(item.id)}>
                <p className={`multiFormPages__header__title__item`}> {item.text}</p>
                {item.text !== 'Predeterminado' && <IconX size={'20px'} onClick={() => deleteDestination(item)} className={'multiFormPages__header__title__icon'} />}
              </div>
            )
          )}
        </div>

        <div className="multiFormPages__body">
          {destinationCalendars.map(
            (item, index) =>
              item.id === pageSelected && (
                <AnimatePresence key={index} exitBeforeEnter>
                  <motion.div
                    key={index}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="multiFormPages__body__contain">
                    <BodyMultiForm
                      item={item}
                      form={form}
                      dataForm={newDataForm}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      idPage={item.id}
                      validateReducer={validateReducer}
                      //
                      // setValidateReducer={setValidateReducer}
                      reducerForm={reducerForm}
                      firstData={firstData}
                      setRemovedDestinationOpen={setRemovedDestinationOpen}
                      removedDestinationOpen={removedDestinationOpen}
                    />
                  </motion.div>
                </AnimatePresence>
              )
          )}
        </div>
      </div>
    </MultiFormPagesWrapper>
  );
};

MultiFormPages.propTypes = {
  data: object,
  form: func,
  setInputValue: func,
  setErrorInput: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  allSteps: array,
  validateReducer: array,
  setValidateReducer: func,
  reducerForm: object,
  firstData: object,
};
