import styled from 'styled-components';

export const EmergencyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  // padding-left: 240px;
  // padding-top: 120px;
`;
