import React, { useEffect, useState } from 'react';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import { NavTableWrapper } from './NavTable.style';
import { array, bool, func, number } from 'prop-types';
// import { DropDown } from '../DropDown/DropDown';
import { IconTrash } from '@tabler/icons-react';
// import { menuButtonsDropDownFiles } from '../../data/dataButtons';
// import { colors } from '../../theme/colors';

export const NavTable = ({ checkboxesActives, totalDocs }) => {
  const [, setSelectedItem] = useState([]);
  // const [selectedId, setSelectedId] = useState('');
  // const refContainer = useRef(null);

  useEffect(() => {
    setSelectedItem(checkboxesActives);
  }, [checkboxesActives]);

  // const handleDelete = () => {
  //   deleteFunction(checkboxesActives);
  // };

  return (
    <NavTableWrapper className="navtable__container">
      <div className={checkboxesActives.length > 0 ? 'icon_trash_container' : 'icon_trash_container__disabled'}>
        {/* <DropDown
          id={'file-button'}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          layout={'1'}
          data={{ id: 1 }}
          Icon={IconTrash}
          menuButtonsDropDown={menuButtonsDropDownFiles}
          width={'35px'}
          height={'35px'}
          backgroundColorHover={colors.primaryColor}
          colorHover={colors.primaryColor}
          iconColor={colors.black}
          iconHover={colors.white}
          position={'calc(5% + 0px);'}
          importFile={importFile}
          refContainer={refContainer}
          isFilter={isFilter}
          borderHover={`1px solid ${colors.secondaryColor}`}
        /> */}
        <IconTrash className={'icon_trash'} />
        <span>Borrar</span>
      </div>
      <p className="navTable__selectedItems">
        {/* <p className="navTable__selectedItems">{selectedItem && selectedItem.length ? selectedItem.length : ' 0'} seleccionados</p> */}
        {checkboxesActives && Array.isArray(checkboxesActives) ? checkboxesActives.length : ' 0'} / {totalDocs?.toString()} seleccionado
        {checkboxesActives && checkboxesActives.length > 1 ? 's' : ''}
      </p>
      {/* <ReactTooltip className="tooltip" anchorId="delete-button" place="right" content="Borrar seleccionado/s" delayShow={1000} /> */}
    </NavTableWrapper>
  );
};

NavTable.propTypes = { totalDocs: number, checkboxesActives: array, deleteFunction: func, importFile: func, isFilter: bool };
