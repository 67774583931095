import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const RandomCodeGeneratorWrapper = styled.div`
  border: 1px solid ${colors.greyLine};
  border-radius: 5px;
  box-shadow:
    26px 32px 17px rgba(73, 80, 87, 0.01),
    15px 18px 14px rgba(73, 80, 87, 0.04),
    7px 8px 10px rgba(73, 80, 87, 0.06),
    2px 2px 6px rgba(73, 80, 87, 0.07),
    2px 2px 6px rgba(73, 80, 87, 0.07),
    0px 0px 0px rgba(73, 80, 87, 0.07);
  width: 100%;
  height: 100%;
  //transition: all 0.3s;
  padding: 15px;

  &:hover {
    background-color: ${colors.greyHover};
    //transition: all 0.3s;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.96);
    //transition: all 0.1s;
  }

  .randomCodeGenerator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    h4,
    p {
      height: 30px;
      margin: 0;
    }
  }
`;
