import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tooltip as ReactTooltip } from 'react-tooltip';
// import { CardGird } from '../../../components/CardGrid/CardGird';
// import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
// import { dataScreenSongWaiting } from '../../../data/dataApplications';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import useApplications from '../../../hooks/useApplications';
import { useSwal } from '../../../hooks/useSwal';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import { SongWaitingWrapper } from './SongWaiting.style';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenSongWaiting } from '../../../data/dataApplications';
import { IconEye } from '@tabler/icons-react';
import { number, string } from 'prop-types';
// import { string } from 'prop-types';
// import ReactAudioPlayer from 'react-audio-player'; //...

export const SongWaitingScreen = () => {
  const { songWaiting, songWaitingLink, getSongWaitingLink, deleteSongWaiting, loading } = useApplications();
  const [search, setSearch] = useState([]);
  const { typeSwal } = useSwal();
  const { notFound } = dataScreenSongWaiting;

  const [, setIsBottom] = useState(false);

  const [checkBoxesActives, setCheckboxesActives] = useState([]);

  const navigate = useNavigate();

  // const location = useLocation();
  // const [objectSongWaiting, setObjectSongWaiting] = useState({
  //   arraySongWaiting: [],
  //   title: '',
  // });
  // const [enable, setEnable] = useState({});
  // const { notFound, title = [] } = dataScreenSongWaiting;

  useEffect(() => {
    getSongWaitingLink();
    setSearch([]);
  }, []);

  // useEffect(() => {
  // let newSongWaiting = songWaitingLink.filter((item) => item.Moh?.id === songWaiting[0]?.id);
  // setObjectSongWaiting({
  //   arraySongWaiting: newSongWaiting,
  //   title: songWaiting[0]?.name,
  // });
  // setEnable(songWaiting[0]?.id);
  // }, [songWaitingLink, songWaiting]);

  // const handleClick = (item) => {

  // setEnable(item.id);
  // };

  const handleOpenFormCreate = () => {
    navigate('/applications/songWaiting/create', {});
  };

  const handleOpenFormEdit = (item) => {
    // handleClick(item);
    // const newSongWaiting = songWaitingLink.filter((object) => object.Moh.id === item.original.id);
    // setObjectSongWaiting({
    //   arraySongWaiting: newSongWaiting,
    //   title: item.text,
    // });
    // item.original.arraySongWaiting = item.original.arraySongWaiting.map((ext) => {
    //   return { value: ext.Soundprompt.id, label: ext.Soundprompt.description };
    // });
    item.original.soundPrompt = item.original.soundPrompt.map((ext) => {
      return { value: ext.Soundprompt.id, label: ext.Soundprompt.description };
    });

    navigate('/applications/songWaiting/update', {
      state: item.original,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await deleteSongWaiting(item);

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getSongWaitingLink();
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  // const dataToCards = objectSongWaiting.arraySongWaiting.map((item) => {
  //   return {
  //     id: item.id,
  //     title: item.Soundprompt?.description,
  //     subtitle: item.Soundprompt?.filename,
  //     original: item,
  //   };
  // });

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) {
      item.function = handleOpenFormEdit;
    } else if (item.id === 2) {
      item.function = handleDelete;
    }
  });

  // const arrayMohLinks = songWaitingLink?.filter((object) => object.mohId === (location.state && Array.isArray(location.state) && location.state !== undefined ? location.state?.mohId : ''));
  // const option = songWaiting.map((item) => {
  //   return {
  //     id: item.id,
  //     text: item.name,
  //   };
  // });

  // const DATA = songWaitingLink?.map(({ mohId, Moh }) => ({
  //   id: mohId,
  //   name: Moh.name,
  //   company: Moh.Company.name,
  //   companyId: Moh.Company.id,
  //   soundPrompt: songWaitingLink?.filter((object) => object.mohId === mohId),
  // }));

  const DATA = songWaiting?.map(({ id, name, Company }) => ({
    id,
    name,
    company: Company.name,
    companyId: Company.id,
    soundPrompt: songWaitingLink?.filter((object) => object.mohId === id),
  }));

  const tableInstance = useRef(null);

  // const handleTooltipContent = (row) => {
  //   if (row?.original !== undefined) {
  //     const newArray = songWaitingLink?.filter((object) => object.mohId === row.original.id);

  //     // const result = [];
  //     const result = newArray?.map((ext, index) => {
  //       if (ext.mohId === row.original.id) {
  //         return (
  //           <div key={ext.id + index} className="tooltip__audio">
  //             <span>
  //               <br />
  //               {ext.Soundprompt.id} - {ext.Soundprompt.description}
  //               {/* <audio src={ext.path} controls className="tooltip__audio" /> */}
  //             </span>
  //             <audio src={import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + ext.Soundprompt.filename} controls />
  //           </div>
  //         );
  //       }
  //     });

  //     return result;
  //   } else {
  //     return [];
  //   }
  // };

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
    {
      Header: 'Locuciones',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => {
        const result = [];
        songWaitingLink?.find((object) => {
          if (object.mohId === row.original?.id) {
            result.push({ value: object.Soundprompt.id, label: object.Soundprompt.description, filename: object.Soundprompt.filename });
          }
        });

        return (
          <>
            <ReactTooltip
              className="tooltip"
              clickable={true}
              anchorSelect={'#icon__viewMohLinks' + row.index}
              place="bottom"
              content={() =>
                result.map((sound) => {
                  return (
                    <div key={sound.value + row.index} className="tooltip__audio">
                      <span>
                        <br />
                        {sound.value} - {sound.label}
                      </span>
                      {/* <audio src={import.meta.env.VITE_APP_UPLOAD_FOLDER + 'mp3/' + sound.filename} controls /> */}
                    </div>
                  );
                })
              }
              delayShow={1000}
            />
            <IconEye type="Change" row={row} id={'icon__viewMohLinks' + row.index} className={'icon__view'} />
          </>
        );
      },
    },
  ];
  return (
    <SongWaitingWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
          }}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : songWaiting[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={true}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </SongWaitingWrapper>
  );
};

/* <div className="distributionTable">
          <DistributionTable title={title} option={option} component={<CardGird data={dataToCards} />} handleClick={handleClick} menuButtonsDropDown={menuButtonsDropDownTable} enable={enable} />
        </div> */

// SongWaitingScreen.propTypes = { row: string };

SongWaitingScreen.propTypes = { row: string, index: number };
