import React from 'react';
// import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
// import { menuSettings } from '../../data/dataSettings';
import { UsersScreenWrapper } from './UsersScreen/UsersScreen.style';
import UsersNavigation from '../../navigation/UsersNavigation/UsersNavigation';
// import { menuUsers } from '../../data/dataUserProfile';

// import { menuPreferences } from '../../data/dataPreferences';
// import { useLocation } from 'react-router-dom';
// import '../../styles/Screens/SettingsScreen/SettingsScreen.css';

const UsersScreen = () => {
  // const location = useLocation();
  // const isPrefHorarias =
  //   location.pathname.includes('settings/calendar') ||
  //   location.pathname.includes('settings/schedule') ||
  //   location.pathname.includes('settings/company') ||
  //   location.pathname.includes('settings/department')
  //     ? true
  //     : false;

  return (
    <UsersScreenWrapper className="users-container">
      {/* <Header image={menuNavLeft.menuBottom[0]?.image} text={menuNavLeft.menuBottom[0]?.text} /> */}
      {/* {isPrefHorarias ? <MenuTop menu={menuPreferences} /> : null} */}
      <MenuTop menu={[]} menuType={'Usuarios'} />
      <UsersNavigation />
    </UsersScreenWrapper>
  );
};

export default UsersScreen;
