import React, { useState } from 'react';
// import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
// import { IconLogout } from '@tabler/icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Restricted from '../Permission/PermissionConsumer';
// import { useDispatch } from 'react-redux';
// import { loginUserAction } from '../../redux/actions/login/actionCreators';
// import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { array, bool, string } from 'prop-types';
import { useSwal } from '../../hooks/useSwal';
import PermissionProvider from '../Permission/PermissionProvider';
import { MenuTopWrapper } from './MenuTop.style';
import { useDispatch, useStore } from 'react-redux';
import { IconUser, IconLogout } from '@tabler/icons-react';
import { loginUserAction } from '../../redux/actions/login/actionCreators';
import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';
// import { ErrorBoundary } from 'react-error-boundary';
// import { wrongAction } from '../Toasters/Toasters';
// import { Button } from '../Button/Button';
// import { ToastContainer } from 'react-toastify';
// import { IconMenu2, IconArrowLeft, IconArrowRight, IconPin } from '@tabler/icons-react';
// import { isMenuOpenActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';

export const MenuTop = ({ menu, menuType, isRestricted = true }) => {
  //   return <h1>MenuTop</h1>;
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pathNameSeparate = pathname.split('/')[2];
  const pathNameSeparate2 = pathname.split('/')[1];

  const { typeSwal } = useSwal();
  const { getState } = useStore();
  // const { isMenuOpen } = getState()['system']['isMenuOpen'];
  const { isMenuOpen } = false;

  const permissions = getState()['arrayUsersPermission'];
  const userData = getState()['login']['user'];
  // const menuToDisplay = menu && menu.length > 0 ? menu : [];
  // const menuToDisplay = [];

  const [windowLocation1, setWindowLocation1] = useState(pathNameSeparate2);

  const initialState = {
    authChecked: false,
    loggedIn: false,
    user: {},
  };

  const initialStatePermissions = [];

  const logout = () => {
    typeSwal('logout').then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('tokenStorage');
        dispatch(loginUserAction(initialState));
        dispatch(getUsersPermissionAction(initialStatePermissions));
        navigate('/login', { replace: true });
      }
    });
  };

  const subtitlePermissionsUser = permissions.map((item) => item.subtitle);

  const isMenuPref = menuType === 'Preferencias';

  const changeView = () => {
    setWindowLocation1(windowLocation1 !== '' ? windowLocation1 : '');
  };

  const classPreffix = isMenuPref ? 'menupref__' : '';

  return (
    <PermissionProvider permissionsUser={subtitlePermissionsUser}>
      <MenuTopWrapper isMenuOpen={isMenuOpen} className={classPreffix + 'header__container'}>
        <div className={menuType !== 'Preferencias' ? 'buttons__container' : 'menupref__container'}>
          {menu.map((item, index) =>
            isRestricted === true ? (
              <Restricted key={index} to={item.restricted}>
                <Link to={'/' + pathNameSeparate2 + '/' + item.path} replace>
                  <div id={item.id} className={item.path !== pathNameSeparate && !isMenuPref ? classPreffix + 'container__link' : classPreffix + 'container__link-active'}>
                    <div className={item.path !== pathNameSeparate ? classPreffix + 'item-link' : classPreffix + 'link-active'} onClick={changeView}>
                      <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={classPreffix + 'menuTop__icon'} />
                      {item.text}
                    </div>
                  </div>
                </Link>
              </Restricted>
            ) : (
              <Link key={index} to={item.path} replace>
                <div id={item.id} className={item.path !== pathNameSeparate || isMenuPref ? 'container__link item-link' : 'container__link-active link-active'}>
                  <div className={item.path !== pathNameSeparate || isMenuPref ? 'item-link' : 'link-active'} onClick={changeView}>
                    <item.icon height={'30px'} width={'30px'} strokeWidth={2} className={'menuTop__icon'} />
                    {item.text}
                  </div>
                </div>
              </Link>
            )
          )}
          {menu.length === 0 ? <span className="title__Form_title">{menuType}</span> : null}
        </div>
        {menuType !== 'Preferencias' ? (
          <div className={classPreffix + 'header__buttons'}>
            <div className={classPreffix + 'item__contain'} id="user-button">
              <IconUser className={classPreffix + 'item__image'} height={'20px'} />
            </div>
            {/* <div className={classPreffix + 'item__contain'} id="settings-button">
              <IconSettings className={classPreffix + 'item__image'} height={'20px'} />
            </div> */}
            <div onClick={logout} className={classPreffix + 'item__contain'} id="logout-button">
              <IconLogout className={classPreffix + 'item__image'} height={'20px'} />
            </div>
          </div>
        ) : null}
      </MenuTopWrapper>
      <ReactTooltip className="tooltip" anchorId="logout-button" place="bottom-start" content="Cerrar sesion" delayShow={1000} />
      {/* <ReactTooltip className="tooltip" anchorId="settings-button" place="bottom" content="Configuración de usuario" delayShow={1000} /> */}
      <ReactTooltip className="tooltip" anchorId="user-button" place="bottom-start" content={userData.email} delayShow={1000} />
    </PermissionProvider>
  );
};

MenuTop.propTypes = { menu: array, isRestricted: bool, menuType: string };
