import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconRainbow, IconSettings, IconVariable } from '@tabler/icons-react';
import { FormScreen } from '../FormScreen/FormScreen';
import { PageScreenWrapper } from './PageScreen.style';
import { array, bool, func, object, string } from 'prop-types';
import { IconPhone } from '@tabler/icons-react';
import { IconCloud } from '@tabler/icons-react';
import { IconUsers } from '@tabler/icons-react';
import useNumbering from '../../hooks/useNumbering';
import { TableInfo } from '../TableInfo/TableInfo';
import PasswordWatcher from '../PasswordWatcher/PasswordWatcher';
import { ExtensionsFormScreen } from '../../screens/NumberingScreen/ExtensionsScreen/ExtensionsFormScreen';
import { NavButtons } from '../NavButtons/NavButtons';

export const PageScreen = ({ isEditMode, data, render, setRender, onSubmit, form, crudType, maxwidth, arrScreen, reducerForm, dataModalButtonToTable }) => {
  const [pageSelected, setPageSelected] = useState(1);

  const navigate = useNavigate();
  const [, setIsBottom] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { arrayNumberingExtensionsHook, formExtensionsReducer, getExtensions } = useNumbering();
  const [showPassword, setShowPassword] = useState(false);

  const [checkboxesActives, setCheckboxesActives] = useState([]);

  useEffect(() => {
    getExtensions();
  }, []);

  const handlePageSelect = (id) => {
    setRender(!render);
    setPageSelected(id);
  };

  const icon = (tab) => {
    let icon = {};
    // const result = () => {
    switch (tab.text) {
      case 'Teams / Rainbow':
        icon = <IconRainbow />;
        break;
      case 'Sístema':
        icon = <IconSettings />;
        break;
      case 'Variables':
        icon = <IconVariable />;
        break;
      case 'SBC SIP/SJSIP':
        icon = <IconPhone />;
        break;
      default:
        // icon = <IconSettings />;
        icon = null;
        break;
    }

    return icon;
  };

  const COLUMNS = [
    {
      Header: '  ',
      accessor: '',
      Cell: ({ row }) => {
        switch (row.original.typeExtension) {
          case 'rainbow':
            return <IconRainbow />;

          case 'virtual':
            return <IconCloud />;

          case 'SIP':
            return <IconPhone />;

          case 'teams':
            return <IconUsers />;
          default:
            break;
        }
      },
    },
    {
      Header: 'Identificador',
      accessor: 'identification',
    },
    {
      Header: 'Tipo',
      accessor: 'typeExtension',
    },
    { Header: 'Contraseña', accessor: 'password', Cell: ({ row }) => <PasswordWatcher row={row} showPassword={showPassword} text={'Contraseña'} />, disableSortBy: true },
  ];

  const tableInstance = useRef(null);

  const handleOpenFormEdit = (item) => {
    const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.name === item.original.identification);

    navigate('/numbering/extensions/update', {
      state: newExt[0],
    });
  };

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [extensionCrudType, setExtensionCrudType] = useState('createUserDispo');

  const handleClickAssign = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // setExtensionCrudType('createUserDispo');
    location.state = formExtensionsReducer;
    setExtensionCrudType('assignUserDispo');
    setIsModalOpen(true);
    setIsDropdownActive(false);
  };
  // const COLUMNS = [

  //   {
  //     Header: ' ',
  //     accessor: '',

  //     Cell: ({ row }) =>
  //       row.original.typeExtension === 'rainbow' ? <IconRainbow /> : row.original?.typeExtension === 'virtual' ? <IconCloud /> : row.original?.typeExtension === 'SIP' ? <IconPhone /> : null,
  //   },

  //   {
  //     Header: 'Id.',
  //     accessor: 'identification',
  //   },
  //   {
  //     Header: 'Tipo',
  //     accessor: 'typeExtension',
  //   },
  // ];

  // const tableInstance = useRef(null);

  // const handleOpenFormEdit = (item) => {
  //   const newExt = arrayNumberingExtensionsHook?.filter((item2) => item2.Ddi.ddi === item.original.identification);

  //   navigate('/numbering/extensions/update', {
  //     state: newExt[0],
  //   });
  // };

  // const [isDropdownActive, setIsDropdownActive] = useState(false);
  // const [extensionCrudType, setExtensionCrudType] = useState('createUserDispo');

  // const handleClickAssign = () => {
  //   // typeSwal(type, name, hookForm, action, path, form, hookFunction);
  //   // setExtensionCrudType('createUserDispo');
  //   // location.state = item.original;
  //   setExtensionCrudType('assignUserDispo');
  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const handleClickCreate = () => {

  //   setIsModalOpen(true);
  //   setIsDropdownActive(false);
  // };

  // const arrayRules = [];
  // const dataDispositivos = () => {
  //   arrayNumberingExtensionsHook?.forEach((row) => {
  //     if (row.userId === location.state?.id) {
  //       const newRow = {
  //         // id: row?.id,
  //         identification: row.Ddi?.ddi,
  //         // extension: row.extension,
  //         // userId: row?.User.fullName,
  //         typeExtension: row.typeExtension,
  //       };
  //       row.id ? arrayRules.push(newRow) : null;
  //     }
  //   });
  //   return arrayRules;
  // };

  // const handleOnClickOut = (event) => {
  //   event.preventDefault();
  //   setIsModalOpen(false);
  //   setIsDropdownActive(false);
  // };
  // const handleOnClickIn = (event) => {
  //   event.stopPropagation();
  // };
  const handleClickCreate = () => {
    // typeSwal(type, name, hookForm, action, path, form, hookFunction);
    // location.state = item.original;
    setExtensionCrudType('createUserDispo');
    setIsModalOpen(true);
    setIsDropdownActive(false);
  };

  const arrayRules = [];

  const dataDispositivos = (formData) => {
    arrayNumberingExtensionsHook?.forEach((row) => {
      if (row.User.email === formData?.tabs[0].steps[0].input[3].defaultValues) {
        const newRow = {
          // id: formData.tabs[0].steps[0].input[3].defaultValues,
          identification: row.name,
          // extension: row.extension,
          // userId: row?.User.fullName,
          typeExtension: row.typeExtension,
          password: row.AstSipPeer.pbxpassword || row.password,
        };
        row.id ? arrayRules.push(newRow) : null;
      }
    });
    return arrayRules;
  };
  const handleOnClickOut = (event) => {
    event.preventDefault();
    setIsModalOpen(false);
    setIsDropdownActive(false);
  };

  const handleOnClickIn = (event) => {
    event.stopPropagation();
  };

  const arrayDispositivos = dataDispositivos(reducerForm);

  return (
    <PageScreenWrapper id="modalRoot">
      {/* <FormTitle formTitle={['Config']} type={crudType} /> */}

      <div className="pageScreen">
        <div className="pageScreen__form">
          <div className="pageScreen__tabs">
            {/* {arrScreen && arrScreen[0] !== '' && arrScreen[0] !== 'Preferencias' ? <FormTitle formTitle={arrScreen} type={crudType} /> : null} */}
            {data.tabs
              ? data.tabs.map((tab) => (
                  <div className={`pageScreen__tabs__tab ${pageSelected === tab.id ? 'active' : 'inactive'}`} key={tab.id} onClick={() => handlePageSelect(tab.id)}>
                    {icon(tab)}
                    <p className={`pageScreen__tabs__tab__item`}>{tab.text}</p>
                  </div>
                ))
              : null}
          </div>
          <FormScreen
            data={data.tabs ? data.tabs[pageSelected - 1] : data.steps[pageSelected - 1]}
            // data={data.tabs}
            reducerForm={reducerForm}
            dataTabs={data.tabs || []}
            onSubmit={onSubmit}
            form={form}
            crudType={crudType}
            maxwidth={maxwidth}
            arrScreen={arrScreen}
            title={false}
            dataModalButtonToTable={dataModalButtonToTable}
            isEditMode={isEditMode}
            setCheckboxesActives={setCheckboxesActives}
            checkboxesActives={checkboxesActives}
          />
        </div>

        {arrScreen[0] === 'Usuario' || arrScreen[0] === 'Numeración externa' ? (
          <div className="user__contain__table">
            {isModalOpen ? (
              <div className="modal__form__container" onClick={handleOnClickOut}>
                {/* onClick={handleOnClickOut}> */}
                <div className="modal__form__body" onClick={handleOnClickIn}>
                  <ExtensionsFormScreen crudType={extensionCrudType} user={reducerForm} />
                </div>
              </div>
            ) : (
              <>
                <NavButtons
                  optionButtons={{
                    add: {
                      position: 'left',
                      function: handleOpenFormEdit,
                    },
                  }}
                  onClickCreate={handleClickCreate}
                  onClickAssign={handleClickAssign}
                  isDropdownActive={isDropdownActive}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  isNavTable={false}
                  handleShowEditRow={handleClickCreate}
                  DATA={arrayDispositivos}
                  // data?.tabs ? data.tabs : data}
                />
                <TableInfo
                  format={'list'}
                  // handleEditRule={handleEditRule}
                  COLUMNS={COLUMNS}
                  // isNavTable={true}
                  DATA={arrayDispositivos}
                  askedBy={'UserForm'}
                  // deleteFunction={handleDelete}
                  updateFunction={handleOpenFormEdit}
                  setShowPassword={setShowPassword}
                  ref={tableInstance}
                  onClick={handleOpenFormEdit}
                  setIsBottom={setIsBottom}
                  checkboxesActives={checkboxesActives}
                  setCheckboxesActives={setCheckboxesActives}
                  isDropdownActive={isDropdownActive}
                  handleOpenFormEdit={handleOpenFormEdit}
                  handleClickCreate={handleClickCreate}
                  handleClickAssign={handleClickAssign}
                  setDataCard={() => {
                    false;
                  }}
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    </PageScreenWrapper>
  );
};

PageScreen.propTypes = {
  data: object,
  render: bool,
  setRender: func,
  onSubmit: func,
  form: func,
  crudType: string,
  maxwidth: string,
  arrScreen: array,
  reducerForm: object,
  dataModalButtonToTable: object,
  isEditMode: bool,
  row: string,
  checkboxesActives: func,
  setCheckboxesActives: func,
};
