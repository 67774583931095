import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { newFontSize, weight } from '../../../../theme/fonts';

export const EditPermissionsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 80%;
  margin: auto;
  margin-top: 60px;

  .edit-permissions {
    margin-left: 50px;
    height: 100%;
  }

  .permission {
    box-sizing: border-box;
    // height: calc(100% - 55px);
    height: 100%;
    width: 100%;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;

      // border-bottom: 1px solid ${colors.greyLine};

      h3 {
        font-weight: ${weight.bold};
        color: ${colors.blue};
        font-size: ${newFontSize.h3};
      }

      img {
        margin-right: 2px;
      }
    }

    &__contain-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      width: 100%;
      max-width: 90%;
      height: 50%;
      column-gap: 10px;
      row-gap: 20px;
      justify-content: flex-start;
      ${'' /* overflow-y: auto; */}
      margin: auto;
      margin-top: 40px;
    }

    &__card {
      ${'' /* height:100px; */}
    }
  }
`;
