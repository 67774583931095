import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const SearchWrapper = styled.div`
  height: 34px;
  color: ${colors.blueCheckbox};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 34px;
  padding: 5px;
  cursor: ${(props) => (props.active ? 'text' : 'pointer')};
  border: 1px solid ${colors.blueCheckbox};

  &:hover {
    cursor: pointer;
  }

  ${
    '' /* span {
    border-radius: 8px;
    width: 80.12px;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  } */
  }

  input {
    border: none;
    padding: 5px;
    padding-right: 5px;
    color: ${colors.black};
    font-weight: ${weight.medium};
    font-size: ${newFontSize.body};
    font-family: ${fontFamily.font};
    width: auto;
    ${
      '' /* border-right: 0;
    border-left: 0; */
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.black};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.body};
      font-family: ${fontFamily.font};
    }
  }

  .search__icon {
    height: 100%;
    ${'' /* border-right: 0; */}
  }

  .search__delete__icon {
    width: 30px;
    height: 100%;
    color: ${colors.blueCheckbox};
    ${'' /* border-left: 0; */}
  }
`;
