import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ApiScreen } from '../../screens/SettingsScreen/ApiScreen/ApiScreen';
import { ApiScreenForm } from '../../screens/SettingsScreen/ApiScreen/ApiScreenForm';

// import { CompanyInformationScreen } from '../../screens/SettingsScreen/CompanyInformationScreen/CompanyInformationScreen';
import { ConfigurationAsteriskScreen } from '../../screens/SettingsScreen/ConfigurationAsteriskScreen/ConfigurationAsteriskScreen';
import { Domains } from '../../screens/SettingsScreen/Domains/Domains';
import { DomainsForm } from '../../screens/SettingsScreen/Domains/DomainsForm';
import { ModulesScreen } from '../../screens/SettingsScreen/ModulesScreen/ModulesScreen';
// import { OverviewSettingsScreen } from '../../screens/SettingsScreen/OverviewSettingsScreen/OverviewSettingsScreen';
import { EditPermissions } from '../../screens/SettingsScreen/PermissionsScreen/EditPermissions/EditPermissions';
import PermissionsScreen from '../../screens/SettingsScreen/PermissionsScreen/PermissionsScreen';
// import UserScreenForm from '../../screens/UsersScreen/UsersScreen/UserScreenForm';
import UsersScreen from '../../screens/UsersScreen/UsersScreen/UsersScreen';
import { WhiteList } from '../../screens/SettingsScreen/WhiteList/WhiteList';
import WhiteListForm from '../../screens/SettingsScreen/WhiteList/WhiteListForm';
import { routesSettings } from '../routes';

import { CalendarFormScreen } from '../../screens/PreferencesScreen/CalendarScreen/CalendarFormScreen';
import { CalendarScreen } from '../../screens/PreferencesScreen/CalendarScreen/CalendarScreen';
// import { OverviewPreferencesScreen } from '../../screens/PreferencesScreen/OverviewPreferencesScreen/OverviewPreferencesScreen';
import { ProtocolSetupScreen } from '../../screens/PreferencesScreen/ProtocolSetupScreen/ProtocolSetupScreen';
import ScheduleScreen from '../../screens/PreferencesScreen/ScheduleScreen/ScheduleScreen';
import ScheduleFormScreen from '../../screens/PreferencesScreen/ScheduleScreen/ScheduleFormScreen';
// import { routesPreferences } from '../routes';
// import { PreferencesNavigationWrapper } from './PreferencesNavigation.style';
import { CompanyScreen } from '../../screens/PreferencesScreen/CompanyScreen/CompanyScreen';
import { DepartmentScreen } from '../../screens/PreferencesScreen/DepartmentScreen/DepartmentScreen';
import { DepartmentForm } from '../../screens/PreferencesScreen/DepartmentScreen/DepartmentForm';
import { CompanyForm } from '../../screens/PreferencesScreen/CompanyScreen/CompanyForm';
// import { dataSettingsNotFound, menuSettings } from '../../data/dataSettings';
import { SettingsNavigationWrapper } from './SettingsNavigation.style';
// import { NotFoundScreen } from '../../screens/NotFoundScreen/NotFoundScreen';

const SettingsNavigation = () => {
  // const { notFound } = dataSettingsNotFound;

  return (
    <SettingsNavigationWrapper>
      <Routes>
        <Route path="/" element={<Navigate to={routesSettings.company} />} />
        <Route path={routesSettings.users} element={<UsersScreen />} />
        <Route path={routesSettings.modules} element={<ModulesScreen />} />
        <Route path={routesSettings.permissions} element={<PermissionsScreen />} />
        <Route path={routesSettings.permissionsEdit} element={<EditPermissions />} />
        <Route path={routesSettings.permissionsCreate} element={<EditPermissions />} />
        {/* <Route path={routesSettings.companyInformation} element={<CompanyInformationScreen />} />
        <Route path={routesSettings.createUser} element={<UserScreenForm crudType={'create'} />} />
        <Route path={routesSettings.updateUser} element={<UserScreenForm crudType={'edit'} />} /> */}
        <Route path={routesSettings.configurationAsterisk} element={<ConfigurationAsteriskScreen />} />
        {/* <Route path={routesPreferences.company} element={<CompanyScreen />} /> */}
        <Route path={routesSettings.api} element={<ApiScreen />} />
        <Route path={routesSettings.createApi} element={<ApiScreenForm crudType={'create'} />} />
        <Route path={routesSettings.domains} element={<Domains />} />
        <Route path={routesSettings.createDomains} element={<DomainsForm crudType={'create'} />} />
        <Route path={routesSettings.updateDomains} element={<DomainsForm crudType={'edit'} />} />
        <Route path={routesSettings.whitelist} element={<WhiteList />} />
        <Route path={routesSettings.createWhitelist} element={<WhiteListForm crudType={'create'} />} />
        <Route path={routesSettings.updateWhitelist} element={<WhiteListForm crudType={'edit'} />} />

        <Route path={routesSettings.company} element={<CompanyScreen />} />
        <Route path={routesSettings.createCompany} element={<CompanyForm crudType={'create'} />} />
        <Route path={routesSettings.updateCompany} element={<CompanyForm crudType={'edit'} />} />
        <Route path={routesSettings.department} element={<DepartmentScreen />} />
        <Route path={routesSettings.createDepartment} element={<DepartmentForm crudType={'create'} />} />
        <Route path={routesSettings.updateDepartment} element={<DepartmentForm crudType={'edit'} />} />

        <Route path={routesSettings.calendar} element={<CalendarScreen />} />
        <Route path={routesSettings.createCalendar} element={<CalendarFormScreen crudType={'create'} />} />
        <Route path={routesSettings.updateCalendar} element={<CalendarFormScreen crudType={'edit'} />} />
        <Route path={routesSettings.schedule} element={<ScheduleScreen />} />
        <Route path={routesSettings.createSchedule} element={<ScheduleFormScreen crudType={'create'} />} />
        <Route path={routesSettings.updateSchedule} element={<ScheduleFormScreen crudType={'edit'} />} />
        <Route path={routesSettings.protocolSetup} element={<ProtocolSetupScreen />} />
        <Route path={routesSettings.company} element={<CompanyScreen />} />
        <Route path={routesSettings.createCompany} element={<CompanyForm crudType={'create'} />} />
        <Route path={routesSettings.updateCompany} element={<CompanyForm crudType={'edit'} />} />
        <Route path={routesSettings.department} element={<DepartmentScreen />} />
        <Route path={routesSettings.createDepartment} element={<DepartmentForm crudType={'create'} />} />
        <Route path={routesSettings.updateDepartment} element={<DepartmentForm crudType={'edit'} />} />
      </Routes>
    </SettingsNavigationWrapper>
  );
};

export default SettingsNavigation;
