import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateNumberingList, modalNumberingList } from '../../../data/dataNumbering';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import usePreferences from '../../../hooks/usePreferences';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
// import { addDestinationCalendarAction, addDestinationOpenAction } from '../../../redux/actions/numbering/actionCreator';
import { string } from 'prop-types';
import { useDepartments } from '../../../hooks/useDepartments';
import { optionsDestination } from '../../../data/dataApplications';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
// import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { isRowDestEditableActionCreator } from '../../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
// import { ExtensionsFormScreen } from '../ExtensionsScreen/ExtensionsFormScreen';
// import { optionsDestination } from '../../../data/dataApplications';

export const ListNumberFormScreen = ({ crudType }) => {
  const { listNumberingForm, createNumberingList, FormListNumberingReducer, updateNumberingList, getNumberingList } = useNumbering();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { getDepartments, departmentsHook } = useDepartments();

  const { getCalendar, getScheduleDB, calendarArrayHook, scheduleArrayHook } = usePreferences();
  const { getApplicationsLocutions, arrayLocutionsHook, getDestination } = useApplications();
  const { getState } = useStore();
  const { isRowDestEditable } = getState().system.isRowDestEditable;
  const { typeSwal } = useSwal();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkboxesActives, setCheckboxesActives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  let destinationCalendarEdit = [];

  const [newCrudType, setNewCrudType] = useState(crudType);

  if (crudType === 'edit') {
    location.state.destinationCalendar?.forEach((item) => {
      const calendar = {
        value: item.CalendarName === null ? 'Predeterminado' : item.CalendarName.id.toString(),
        label: item.CalendarName === null ? 'Predeterminado' : item.CalendarName.name,
      };

      const found = destinationCalendarEdit.find((obj) => obj.calendar.value === calendar.value && obj.calendar.label === calendar.label);

      if (!found) {
        destinationCalendarEdit.push({
          id: destinationCalendarEdit.length + 1,
          calendar,
          destinationOpen: [],
        });

        const soundPrompt = {
          value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
          label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
        };

        const destination = {
          label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
          value:
            item.Destination?.destinationType === 'external'
              ? item.Destination?.destinationType
              : item.Destination?.destinationType === 'hangup'
                ? item.Destination?.destinationType
                : item.Destination?.destinationId.toString(),
        };

        const schedule = {
          value: item.Schedule?.id.toString(),
          label: item.Schedule?.name,
        };

        const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();

        for (let i = 0; i < destinationCalendarEdit.length; i++) {
          if (destinationCalendarEdit[i].calendar?.value === calendarId) {
            destinationCalendarEdit[i].destinationOpen.push({
              id: destinationCalendarEdit[i].destinationOpen.length + 1,
              soundPrompt,
              destination,
              schedule,
            });
          }
        }
      } else {
        if (item.Schedule === null) {
          const soundPromptClose = {
            value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
            label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
          };

          const destinationClose = {
            label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
            value:
              item.Destination?.destinationType === 'external'
                ? item.Destination?.destinationType
                : item.Destination?.destinationType === 'hangup'
                  ? item.Destination?.destinationType
                  : item.Destination?.destinationId.toString(),
          };

          const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();

          for (let i = 0; i < destinationCalendarEdit.length; i++) {
            if (destinationCalendarEdit[i].calendar?.value === calendarId) {
              destinationCalendarEdit[i].soundPromptClose = soundPromptClose;
              destinationCalendarEdit[i].destinationClose = destinationClose;
            }
          }
        } else {
          const soundPrompt = {
            value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
            label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
          };

          const destination = {
            label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
            value:
              item.Destination?.destinationType === 'external'
                ? item.Destination?.destinationType
                : item.Destination?.destinationType === 'hangup'
                  ? item.Destination?.destinationType
                  : item.Destination?.destinationId.toString(),
          };

          const schedule = {
            value: item.Schedule.id.toString(),
            label: item.Schedule.name,
          };

          const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();

          for (let i = 0; i < destinationCalendarEdit.length; i++) {
            if (destinationCalendarEdit[i].calendar?.value === calendarId) {
              destinationCalendarEdit[i].destinationOpen.push({
                id: destinationCalendarEdit[i].destinationOpen.length + 1,
                soundPrompt,
                destination,
                schedule,
              });
            }
          }
        }
      }
    });
  }

  const data = newCrudType === 'assignRule' ? modalNumberingList : formCreateNumberingList;

  useEffect(() => {
    getHeadquarter();
    getDepartments();
    getCalendar();
    getScheduleDB();
    getApplicationsLocutions();
    getDestination();
    setSearch([]);

    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'numberingList',
      }
    );

    if (crudType === 'edit' || newCrudType === 'assignRule') {
      // destinationCalendarEdit.forEach((item) => {
      //   if (item.id !== 1) {
      //     dispatch(addDestinationCalendarAction(item.id));
      //   }
      //   item.destinationOpen.forEach((item2) => {
      //     let newDestinationOpen = { id: item2.id };
      //     let refId = item.id;
      //     let newObjectWithRef = {
      //       newDestinationOpen,
      //       refId,
      //     };
      //     dispatch(addDestinationOpenAction(newObjectWithRef));
      //   });
      // });

      listNumberingForm(
        {
          action: 'input',
          value: String(location.state?.id),
        },
        {
          form: 'numberingList',
          element: 'id',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: String(location.state?.ddi),
        },
        {
          form: 'numberingList',
          element: 'ddi',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: String(location.state?.name),
        },
        {
          form: 'numberingList',
          element: 'name',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: String(location.state?.companyId),
        },
        {
          form: 'numberingList',
          element: 'companyId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'Company',
        },
        {
          value: String(location.state?.companyId),
          label: location.state?.company,
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: String(location.state?.departmentId),
        },
        {
          form: 'numberingList',
          element: 'departmentId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'Department',
        },
        {
          value: String(location.state?.departmentId),
          label: location.state?.department,
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.soundPromptCloseId,
        },
        {
          form: 'numberingList',
          element: 'soundPromptCloseId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'soundPromptClose',
        },
        {
          value: location.state?.destinationCalendar[0]?.Soundprompt?.id,
          label: location.state?.destinationCalendar[0]?.Soundprompt?.description,
          optionRefPage: location.state?.id,
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.destinationCloseTypeId,
        },
        {
          form: 'numberingList',
          element: 'destinationCloseTypeId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'destinationCloseType',
        },
        {
          value: location.state.destinationCloseType[0]?.value,
          label: location.state.destinationCloseType[0]?.label,
          // optionRefPage: location.state?.id,
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.destinationCloseId,
        },
        {
          form: 'numberingList',
          element: 'destinationCloseId',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'destinationClose',
        },
        {
          value: location.state.destinationClose[0]?.value || location.state.destinationClose[0]?.id,
          label: location.state.destinationClose[0]?.label || location.state.destinationClose[0]?.extension || location.state?.destinationClose[0]?.name,
          // optionRefPage: location.state?.id,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'calendar',
        },
        {
          value: calendarArrayHook?.length > 0 ? calendarArrayHook[0][0]?.CalendarName.id : '',
          label: calendarArrayHook?.length > 0 ? calendarArrayHook[0][0]?.CalendarName.name : '',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'schedule',
        },
        {
          value: scheduleArrayHook?.length > 0 ? scheduleArrayHook[0][0]?.Schedule.id : '',
          label: scheduleArrayHook?.length > 0 ? scheduleArrayHook[0][0]?.Schedule.name : '',
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'soundPrompt',
        },
        {
          value: arrayLocutionsHook[0]?.id,
          label: arrayLocutionsHook[0]?.description,
          // optionRefPage: item2.id,
          // destinationOpen: location.state?.id,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'destinationType',
        },
        {
          value: location.state.destinationType[0]?.value,
          // || location.state.destinationCloseType?.value,
          label: location.state.destinationType[0]?.label,
          // || location.state.destinationCloseType?.label,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'destination',
        },
        {
          value: location.state.destination?.value || 243,
          label: location.state.destination?.label || 'IVR 3',
        }
      );

      listNumberingForm(
        {
          action: 'toggle',
          value: location.state?.isDefault || true,
        },
        {
          form: 'numberingList',
          element: 'isDefault',
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
          value: location.state?.calendars,
        },
        {
          form: 'numberingList',
          element: 'calendars',
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
          value: location.state?.schedules,
        },
        {
          form: 'numberingList',
          element: 'schedules',
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
          value: location.state?.soundPrompts,
        },
        {
          form: 'numberingList',
          element: 'soundPrompts',
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
          value: location.state?.destinationsType,
        },
        {
          form: 'numberingList',
          element: 'destinationsType',
        }
      );

      listNumberingForm(
        {
          action: 'multiselect',
          value: location.state?.destinations,
        },
        {
          form: 'numberingList',
          element: 'destinations',
        }
      );
    }

    listNumberingForm(
      {
        action: 'multiselect',
        value: location.state?.isDefaults,
      },
      {
        form: 'numberingList',
        element: 'isDefaults',
      }
    );
    // location.state &&
    //   Object.keys(location.state).map((item) =>
    //     data.steps?.map((item2) =>
    //       item2.input?.map((item3) => {
    //         if (item === item3.ref?.element) {
    //           return listNumberingForm(
    //             {
    //               action: item3.cardType,
    //               value: location.state[item],
    //             },
    //             {
    //               form: item3.ref.form,
    //               element: item3.ref.element,
    //             }
    //           );
    //         } else {
    //           return null;
    //         }
    //       })
    //     )
    //   );
  }, [newCrudType]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof FormListNumberingReducer === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.forEach((item) => {
          item.input?.forEach((item2) => {
            switch (item2.ref?.element) {
              case 'calendar':
                item2.options = [];
                calendarArrayHook.forEach((itemCal) => {
                  itemCal.forEach((itemCal2) => {
                    const destinationName = {
                      value: itemCal2.CalendarName.id,
                      label: itemCal2.CalendarName.name,
                    };
                    const found = item2.options.some((e) => e.label === destinationName.label);
                    if (!found) {
                      item2.options.push(destinationName);
                    }
                  });
                });

                break;
              case 'schedule':
                item2.options = [];
                scheduleArrayHook.forEach((itemCal) => {
                  itemCal.forEach((itemCal2) => {
                    const destinationName = {
                      value: itemCal2.Schedule?.id,
                      label: itemCal2.Schedule?.name,
                    };
                    const found = item2.options.some((e) => e.label === destinationName.label);
                    if (!found) {
                      item2.options.push(destinationName);
                    }
                  });
                });
                break;
              case 'destination':
                item2.options = [];
                arrayLocutionsHook.forEach((itemCal) => {
                  itemCal.forEach((itemCal2) => {
                    const destinationName = {
                      value: itemCal2.Schedule?.id,
                      label: itemCal2.Schedule?.name,
                    };
                    const found = item2.options.some((e) => e.label === destinationName.label);
                    if (!found) {
                      item2.options.push(destinationName);
                    }
                  });
                });
                break;
              // case 'calendars':
              //   item2.options = location.state.calendars;
              //   break;
              // case 'destinationCalendar':
              //   item2.options = [];
              //   location.state.destinationCalendar.forEach((item6) => {
              //     const destinationName = {
              //       value: item6.CalendarName.id,
              //       label: item6.CalendarName.name,
              //     };
              //     // const found = item6.options.some((e) => e.value === destinationName.value);
              //     // if (!found) {
              //     item2.options.push(destinationName);
              //     // }
              //   });

              //   break;
              case 'companyId':
                item2.options = [];
                arrayHeadquarterHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const company = {
                    value: item3.id?.toString(),
                    label: item3.name,
                  };
                  // setSelectedCompany(company.value);
                  const found = item2.options.some((e) => e.value === company.value);
                  if (!found && company.value !== undefined) {
                    item2.options.push(company);
                  }
                });

                break;
              case 'departmentId':
                item2.options = [];
                departmentsHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const depart = {
                    value: item3.id?.toString(),
                    label: item3.name,
                  };
                  // setSelectedCompany(company.value);
                  const found = item2.options.some((e) => e.value === depart.value);
                  if (!found && depart.value !== undefined) {
                    item2.options.push(depart);
                  }
                });

                break;
              case 'soundPromptCloseId':
                item2.options = [];
                arrayLocutionsHook.forEach((item6) => {
                  const soundPrompt = {
                    label: item6.description,
                    value: item6.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === soundPrompt.value);
                  if (!found && soundPrompt.value !== undefined) {
                    item2.options.push(soundPrompt);
                  }
                });
                break;
              case 'destinationCloseId':
                item2.options = [];
                arrayLocutionsHook.forEach((item6) => {
                  const soundPrompt = {
                    label: item6.description,
                    value: item6.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === soundPrompt.value);
                  if (!found && soundPrompt.value !== undefined) {
                    item2.options.push(soundPrompt);
                  }
                });
                break;
              case 'soundPrompt':
                item2.options = [];
                arrayLocutionsHook.forEach((item6) => {
                  const soundPrompt = {
                    label: item6.description,
                    value: item6.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === soundPrompt.value);
                  if (!found && soundPrompt.value !== undefined) {
                    item2.options.push(soundPrompt);
                  }
                });
                break;
              case 'destinationType':
                item2.options = [];
                arrayLocutionsHook.forEach((item6) => {
                  const soundPrompt = {
                    label: item6.description,
                    value: item6.id?.toString(),
                  };
                  const found = optionsDestination?.some((e) => e.id?.toString() === soundPrompt.value);
                  if (!found && soundPrompt.value !== undefined) {
                    item2.options.push(soundPrompt);
                  }
                });
                break;
              case 'multiFormPages':
                item2.dataRef.forEach((item4) => {
                  item4.destinationOpen?.forEach((item5) => {
                    item5.input?.forEach((item8) => {
                      if (item8.ref.element === 'soundPromptCloseId') {
                        arrayLocutionsHook.forEach((item6) => {
                          const soundPrompt = {
                            label: item6.description,
                            value: item6.id?.toString(),
                          };
                          const found = item8.options.some((e) => e.value === soundPrompt.value);
                          if (!found && soundPrompt.value !== undefined) {
                            item8.options.push(soundPrompt);
                          }
                        });
                      }
                      if (item8.ref.element === 'soundPrompt') {
                        arrayLocutionsHook.forEach((item6) => {
                          const soundPrompt = {
                            label: item6.description,
                            value: item6.id?.toString(),
                          };
                          const found = item8.options.some((e) => e.value === soundPrompt.value);
                          if (!found && soundPrompt.value !== undefined) {
                            item8.options.push(soundPrompt);
                          }
                        });
                      }
                      // if (item8.ref.element === 'destinationType') {
                      //   destination.forEach((item6) => {
                      //     const destinationName = {
                      //       label: item6.destinationType,
                      //       value: item6.id?.toString(),
                      //     };
                      //     const found = item8.options.some((e) => e.value === destinationName.value);
                      //     if (!found) {
                      //       item8.options.push(destinationName);
                      //     }
                      //   });
                      // }
                    });
                  });
                  // item4.input?.forEach((item5) => {

                  // });
                });

                break;
              default:
                // item2.options = location.state[item2.ref.element];
                break;
            }
          });
        });

        if ((crudType === 'edit' || newCrudType === 'assignRule') && (FormListNumberingReducer.ddi === '' || FormListNumberingReducer.ddi !== location.state.ddi)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' || newCrudType === 'assignRule') {
          data.steps.map((item) => {
            item.input?.map((item2) => {
              switch (item2.ref?.element) {
                case 'ddi':
                  item2.defaultValues = FormListNumberingReducer.ddi;
                  break;
                case 'name':
                  item2.defaultValues = FormListNumberingReducer.name;
                  break;
                case 'companyId':
                  item2.options.filter((item3) => {
                    if (Number(item3.value) === FormListNumberingReducer.companyId) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'departmentId':
                  item2.options.filter((item3) => {
                    if (Number(item3.value) === FormListNumberingReducer.departmentId) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'soundPromptCloseId':
                  item2.options.filter((item3) => {
                    if (Number(item3.value) === FormListNumberingReducer.soundPromptCloseId) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'soundPrompt':
                  // item2.options.filter((item3) => {
                  // if (Number(item3.value) === FormListNumberingReducer.soundPrompt) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = FormListNumberingReducer.soundPrompt;
                  break;
                case 'destinationCloseType':
                  // item2.options.filter((item3) => {
                  //   if (Number(item3.value) === FormListNumberingReducer.destinationCloseType.value) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = FormListNumberingReducer.destinationCloseType;
                  break;
                case 'destinationCloseTypeId':
                  // item2.options.filter((item3) => {
                  // if (Number(item3.value) === FormListNumberingReducer.destinationCloseTypeId) {
                  item2.defaultValues = FormListNumberingReducer.destinationCloseTypeId;
                  //   }
                  //   return false;
                  // });
                  break;
                case 'destinationCloseId':
                  item2.options.filter((item3) => {
                    if (Number(item3.value) === FormListNumberingReducer.destinationCloseId) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'destinationClose':
                  item2.defaultValues = FormListNumberingReducer.destinationClose;
                  break;
                case 'destinationType':
                  item2.defaultValues = FormListNumberingReducer.destinationType;
                  location.state.destination = FormListNumberingReducer.destination;
                  break;
                // case 'destinationsType':
                //   item2.defaultValues = FormListNumberingReducer.destinationsType;
                //   break;
                case 'destination':
                  item2.defaultValues = FormListNumberingReducer.destination;
                  break;
                case 'calendar':
                  item2.defaultValues = FormListNumberingReducer.calendar;
                  break;
                case 'schedule':
                  item2.defaultValues = FormListNumberingReducer.schedule;
                  break;
                case 'calendars':
                  item2.defaultValues = FormListNumberingReducer.calendars;
                  break;
                case 'soundPrompts':
                  item2.defaultValues = FormListNumberingReducer.soundPrompts;
                  break;
                // .forEach((itemCalendar) =>
                //   calendarArrayHook.find((itemCal) => {
                //     if (itemCal[0].CalendarName.id === itemCalendar) {
                //       listNumberingForm(
                //         {
                //           action: 'select-option',
                //         },
                //         {
                //           form: 'numberingList',
                //           element: 'calendars',
                //         },
                //         {
                //           value: itemCal[0].CalendarName.id,
                //           label: itemCal[0].CalendarName.name,
                //         }
                //       );
                //     }
                //   })
                // );

                // item2.options.filter((item3) => {
                //   if (Number(item3.value) === FormListNumberingReducer.destinationCloseType) {
                //     return (item2.defaultValues = item3);
                //   }
                //   return false;
                // });
                // break;
                // case 'departmentId':
                //   item2.defaultValues = { label: location.state.Department, value: FormListNumberingReducer.departmentId.toString() };
                //   break;
                // case 'Company':
                //   item2.options.filter((item3) => {
                //     if (item3.value === FormListNumberingReducer.Company) {
                //       return (item2.defaultValues = item3);
                //     }
                //     return false;
                //   });
                //   break;
                // case 'Department':
                //   item2.options.filter((item3) => {
                //     if (item3.value === FormListNumberingReducer.Department) {
                //       return (item2.defaultValues = item3);
                //     }
                //     return false;
                //   });
                //   break;
                // case 'Department':
                //   item2.options.filter((item3) => {
                //     if (item3.value === FormListNumberingReducer.departmentId) {
                //       return (item2.defaultValues = item3);
                //     }
                //     return false;
                //   });
                //   break;
                case 'destinationsType':
                  item2.defaultValues = location.state.destinationsType;

                  break;
                case 'destinations':
                  item2.defaultValues = location.state.destinations;

                  break;
                default:
                  item2.defaultValues = FormListNumberingReducer[item2.ref?.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              item2.defaultValues = '';
            });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter, newCrudType]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', createNumberingList);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingList);
    } else if (crudType === 'assignRule') {
      handleAddRule();
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingList);
    }
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            // response = await deleteNumberingList(item.original);
          } else {
            // response = await deleteNumberingList(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getNumberingList();
            typeSwal('delete');
          }
          if (response.status === 203) {
            // let table = Object.keys(nameTables)
            //   .filter((p) => p.includes(response.data.table))
            //   .reduce((obj, key) => {
            //     obj[key] = nameTables[key];
            //     return obj[key];
            //   }, {});
            // typeSwal('error', table);
            getNumberingList();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const COLUMNS = [
    // {
    //   Header: 'id',
    //   accessor: 'id',
    // },
    {
      Header: ' ',
      accessor: 'isDefault',
    },
    {
      Header: 'Calendario',
      accessor: 'Calendario',
    },
    {
      Header: 'Horario',
      accessor: 'Horario',
    },
    {
      Header: 'Locución',
      accessor: 'Locucion',
    },
    // {
    //   Header: 'Destino',
    //   accessor: 'TipoDestino',
    // },
    {
      Header: 'Destino',
      accessor: 'Destino',
    },
  ];

  const tableInstance = useRef(null);
  // const [showEditRow, setShowEditRow] = useState(false);

  const handleAddRule = (item) => {
    // setNewCrudType('assignRule');
    const newCalendar = item.Calendario;

    const newCalendarData = location.state.calendars.find((cal) => cal.label === newCalendar);

    console.log(newCalendarData);

    // setIsDropdownActive(false);
    FormListNumberingReducer.calendar = { value: item.id, label: newCalendar };
    listNumberingForm(
      {
        action: 'select-option',
      },
      {
        form: 'numberingList',
        element: 'calendar',
      },
      {
        value: newCalendarData.value,
        label: newCalendarData.label,
      }
    );

    // setIsModalOpen(false);
    return false;
  };

  const handleEditRule = () => {
    // const newCalendar = item.Calendario;

    // const newCalendarData = location.state.calendars.find((cal) => cal.label === newCalendar);

    // console.log(newCalendarData);
    setNewCrudType('assignRule');

    setIsModalOpen(true);
    // setIsModalOpen(true);
    // setIsDropdownActive(false);
    // console.log(newSchedule);
    // console.log(newSoundprompt);
    // console.log(newDestType);
    // console.log(newDest);
    // console.log(newIsDefault);
    // FormListNumberingReducer.calendar = { value: item.id, label: newCalendar };
    // listNumberingForm(
    //   {
    //     action: 'select-option',
    //   },
    //   {
    //     form: 'numberingList',
    //     element: 'calendar',
    //   },
    //   {
    //     value: newCalendarData.value,
    //     label: newCalendarData.label,
    //   }
    // );

    // return false;
  };

  const dataRules = () => {
    const arrayRules = [];
    location.state &&
      location.state.calendars?.forEach((row, index) => {
        const newRow = {
          id: row.id,
          Calendario: row?.label,
          Horario: location.state.schedules[index]?.label,
          Locucion: location.state.soundPrompts[index]?.label,
          // TipoDestino: ,
          Destino: location.state.destinationsType[index]?.label + ': ' + location.state.destinations[index]?.label,
          isDefault: location.state?.isDefaults && location.state?.isDefaults[index] !== undefined ? location.state?.isDefaults[index] : '',
        };
        row.label ? arrayRules.push(newRow) : null;
      });
    return arrayRules;
  };

  const [isEditMode, setIsEditMode] = useState(false);

  // const setIsEditMode = () => {
  //   return true;
  // };
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const [isDropdownActive, setIsDropdownActive] = useState(false);

  const handleClickCreate = () => {
    setIsEditMode(true);
    dispatch(isRowDestEditableActionCreator(!isRowDestEditable));

    // setExtensionCrudType('assignRule');
    // setIsModalOpen(true);
    // setIsDropdownActive(false);
  };

  const handleOnClickOut = (event) => {
    event.preventDefault();
    switch (event.target.className) {
      case 'modal__container':
        setIsModalOpen(false);
        setNewCrudType('edit');
        break;
      default:
        event.stopPropagation();
        break;
    }
    // setIsModalOpen(false);
  };

  const handleOnClickIn = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={newCrudType !== 'assignRule' ? 'form__container' : 'modal__container'} onClick={handleOnClickOut}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          arrScreen={['Numeración externa']}
          data={data}
          onSubmit={onSubmit}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={newCrudType}
          maxwidth={'1000px'}
          reducerForm={formCreateNumberingList}
          isEditMode={isEditMode}
        />
      )}
      {newCrudType !== 'assignRule' ? (
        <div className="numb_contain__table">
          <NavButtons
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            DATA={[data]}
            onClickCreate={handleEditRule}
            optionButtons={{
              add: {
                position: 'left',
                function: handleEditRule,
              },
            }}
          />
          <TableInfo
            format={'list'}
            handleEditRule={handleEditRule}
            COLUMNS={COLUMNS}
            isNavTable={true}
            DATA={search.length === 0 ? dataRules() : search}
            deleteFunction={handleDelete}
            ref={tableInstance}
            setIsBottom={setIsBottom}
            askedBy={'ListNumberForm'}
            isOptions={false}
            checkboxesActives={checkboxesActives}
            setCheckboxesActives={setCheckboxesActives}
            onClickCreate={handleClickCreate}
            setDataCard={() => {
              false;
            }}
          />
          {isModalOpen === true ? (
            <div className="modal__form__container" onClick={handleOnClickOut}>
              <div className="modal__form__body" onClick={handleOnClickIn}>
                <ListNumberFormScreen crudType={'assignRule'} onClick={handleOnClickOut} />;
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

ListNumberFormScreen.propTypes = { crudType: string };
