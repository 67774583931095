// import { arraysAreEqual } from '../../../utils/AppUtils';
import { actionsTypesEM } from '../../actions/EM/actionTypes';

const initialState = {
  items: [],
  offset: 0,
  limit: 50,
};

export const EMReducer = (arrayEM = [], action) => {
  let newArrayEM;

  switch (action.type) {
    case actionsTypesEM.getEM:
      newArrayEM = [...action.arrayEM];
      break;
    default:
      newArrayEM = arrayEM;
  }

  return newArrayEM;
};

export const EMGetAlarmsReducer = (arrayEMAlarms = [], action) => {
  let newArrayEMAlarms;
  switch (action.type) {
    case actionsTypesEM.getEMAlarms:
      newArrayEMAlarms = [...action.arrayEMAlarms];
      break;
    default:
      newArrayEMAlarms = arrayEMAlarms;
  }
  return newArrayEMAlarms;
};

export const EMListExtensionReducer = (arrayEM = [], action) => {
  let newArrayEM;

  switch (action.type) {
    case actionsTypesEM.getEMListExtension:
      newArrayEM = [...action.arrayEMListExtension];
      break;
    default:
      newArrayEM = arrayEM;
  }

  return newArrayEM;
};

export const EMFormListExtensionsReducer = (state = { id: null, listName: '', extensions: [], value: null }, action) => {
  const arrayExtensions = { ...state }.extensions;

  let indexEMList = -1;

  switch (action.type) {
    case actionsTypesEM.changeEMListFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypesEM.changeEMListFormName:
      state = {
        ...state,
        listName: action.payload,
      };
      break;

    case actionsTypesEM.addEMListFormExtension:
      // if (arrayExtensions === '') {
      //   arrayExtensions.extensions = action.payload;
      // } else if (action.payload && action.payload.length > 0 && arraysAreEqual(arrayExtensions.extensions, action.payload) === false) {
      //   arrayExtensions.extensions = action.payload;
      // } else {
      //   action.payload ? arrayExtensions.push(action.payload) : null;
      // }

      state = {
        ...state,
        extensions: arrayExtensions,
        // .extensions || arrayExtensions,
      };
      break;
    case actionsTypesEM.removeEMListFormExtension:
      indexEMList = arrayExtensions?.map((p) => p.value).indexOf(action.payload?.value);
      // const unique = arrayExtensions.filter((item, index) => arrayExtensions.findIndex((item2) => item.value === item2.value) === index);
      //  const found = item.input[0].options.some((e) => e.value === extension.value);
      arrayExtensions.filter((item, index) => arrayExtensions.findIndex((item2) => item.value === item2.value) === index).findIndex((obj) => obj.label === action.payload.label);
      // const index = unique.some((e) => e.label === action.payload.label);
      // .map((p) => p.label !== action.payload.label);

      if (indexEMList > -1) {
        arrayExtensions.splice(indexEMList, 1);
      }
      state = {
        ...state,
        extensions: arrayExtensions,
      };
      break;

    case actionsTypesEM.clearEMListFormExtension:
      state = { id: null, listName: '', extensions: [] };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const EMFormAlertsReducer = (
  state = {
    id: '',
    name: '',
    keyCall: '',
    listDiffusion: {},
    locution: {},
    callAndRecord: 0,
    secondaryListDiffusion: {},
    confirmationNumber: '',
    external: 0,
  },
  action
) => {
  switch (action.type) {
    case actionsTypesEM.changeEMAlertsFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionsTypesEM.changeEMAlertsName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionsTypesEM.changeEMAlertsCode:
      state = {
        ...state,
        keyCall: action.payload,
      };
      break;
    case actionsTypesEM.changeEMAlertsConfirmationNumber:
      state = {
        ...state,
        confirmationNumber: action.payload,
      };
      break;
    case actionsTypesEM.addEMAlertsListDiffusion:
      state = {
        ...state,
        listDiffusion: action.payload,
      };
      break;
    case actionsTypesEM.addSecondaryEMAlertsListDiffusion:
      state = {
        ...state,
        secondaryListDiffusion: action.payload,
      };
      break;
    case actionsTypesEM.removeSecondatEMAlertsListDiffusion:
      state = {
        ...state,
        secondaryListDiffusion: '',
      };
      break;
    case actionsTypesEM.addEMAlertsLocutions:
      state = {
        ...state,
        locution: action.payload,
      };
      break;
    case actionsTypesEM.removeEMAlertsListDiffusion:
      state = {
        ...state,
        listDiffusion: '',
      };
      break;
    case actionsTypesEM.removeEMAlertsLocutions:
      state = {
        ...state,
        locution: '',
      };
      break;
    case actionsTypesEM.changeEMAlertsCallAndRecord:
      state = {
        ...state,
        callAndRecord: action.callAndRecord,
      };
      break;
    case actionsTypesEM.changeEMAlertsExternal:
      state = {
        ...state,
        external: action.payload,
      };
      break;
    case actionsTypesEM.clearEMAlertsListDiffusion:
      state = {
        ...state,
        listDiffusion: {},
      };
      break;
    case actionsTypesEM.clearEMAlertsSecondaryListDiffusion:
      state = {
        ...state,
        secondaryListDiffusion: {},
      };
      break;
    case actionsTypesEM.clearEMAlertsLocutions:
      state = {
        ...state,
        locution: {},
      };
      break;
    case actionsTypesEM.clearEMAlertsForm:
      state = {
        name: '',
        code: '',
        listDiffusion: {},
        locution: {},
        callAndRecord: 0,
        secondaryListDiffusion: {},
        confirmationNumber: '',
        external: 0,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const EMAlertsCallAndRecordReducer = (callAndRecord = {}, action) => {
  let newCallAndRecord;
  switch (action.type) {
    case actionsTypesEM.getEMAlertsCallAndRecord:
      newCallAndRecord = { ...action.callAndRecord };
      break;
    default:
      newCallAndRecord = callAndRecord;
  }
  return newCallAndRecord;
};

export const EMAlertsDeleteReducer = (alert = {}, action) => {
  let newAlertDelete;
  switch (action.type) {
    case actionsTypesEM.deleteEMAlerts:
      newAlertDelete = { ...action.alert };
      break;
    default:
      newAlertDelete = alert;
  }
  return newAlertDelete;
};

//History

export const EMHistoryReducer = (histories = [], action) => {
  let newHistories;
  switch (action.type) {
    case actionsTypesEM.getHistory:
      newHistories = [...action.histories];
      break;
    default:
      newHistories = histories;
  }
  return newHistories;
};

export const loadListHistoryReducer = (state = initialState, action) => {
  const nexItems = action.histories?.slice(state.offset, state.offset + state.limit);
  switch (action.type) {
    case actionsTypesEM.loadFirstItemsHistory:
      return {
        ...state,
        items: action.histories.slice(0, state.limit),
        offset: 50,
      };
    case actionsTypesEM.loadNextItemsHistory:
      nexItems.forEach((item) => {
        state.items.push(item);
      });
      return {
        ...state,
        items: [...state.items],
        offset: state.offset + state.limit,
      };
    default:
      return state;
  }
};
