import styled from 'styled-components';
import { colors } from '../../theme/colors.js';

export const StaticScreenWrapper = styled.div`
  width: 100%;
  height: 100%;

  .form-contain {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    left: ${(p) => (!p.isMenuOpen ? '70px' : '228px')};
  }

  .static__screen {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
  .static__container {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: ${colors.primaryColor};
  }
  .toaster__modal {
    position: absolute;
    text-align: center;
    top: 40px;
    left: 50%;
    margin: auto;
  }

  .body__container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    ${'' /* border-left: 0.995763px solid ${colors.greyLine}; */}
    position: fixed;
    left: ${(p) => (p.isMenuOpen ? '229px' : '68px')};
  }
`;
