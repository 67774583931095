import file from '../../assets/images/buttons/file.svg';
import more from '../../assets/images/icons/more.svg';
import fileExport from '../../assets/images/buttons/fileExport.svg';
import fileImport from '../../assets/images/buttons/fileImport.svg';
import search from '../../assets/images/icons/search.svg';
import copy from '../../assets/images/buttons/clipboard-copy.svg';
import arrowBack from '../../assets/images/buttons/arrowBack.svg';
import filter from '../../assets/images/buttons/filter.svg';
import closed from '../../assets/images/buttons/closed.svg';
import passwordWatcher from '../../assets/images/buttons/passwordWatcher.svg';
// import copy from '../../assets/images/buttons/copy.svg';

import { IconPlus, IconClick } from '@tabler/icons-react';

export const iconButton = {
  file: {
    icon: file,
    alt: 'file',
  },
  more: {
    icon: more,
    alt: 'three points',
  },
  plus: {
    text: 'Nuevo',
    icon: IconPlus,
    alt: 'plus',
  },
  click: {
    icon: IconClick,
    alt: 'click',
  },
  search: {
    icon: search,
    alt: 'search',
    notFound: 'No se encontraron resultados',
    placeholder: 'Buscar...',
  },
  return: {
    icon: arrowBack,
    alt: 'return',
    text: 'Atrás',
  },
  filter: {
    icon: filter,
    alt: 'filter',
    text: 'Filtros',
    textButton: 'Aplicar filtros',
    placeholder: 'Sin filtro',
  },
  closed: {
    icon: closed,
    alt: 'closed',
    text: 'Cerrar',
  },
  passwordWatcher: {
    icon: passwordWatcher,
    alt: 'Mirar contraseña',
    text: 'Cambiar contraseña',
  },
  copy: {
    icon: copy,
    alt: 'Copiar',
    text: 'Copiar',
  },
};

export const menuButtonsDropDownTable = [
  {
    name: 'Editar',
    function: null,
    id: 1,
    active: true,
  },
  {
    name: 'Eliminar',
    function: null,
    id: 2,
    active: true,
  },
];

export const menuButtonsDropDownFiles = [
  {
    id: 1,
    header: 'Importar',
    icon: fileImport,
    active: true,

    buttons: [
      // {
      //     id: 1,
      //     name: 'CSV',
      //     // component: '',
      // },
      {
        id: 2,
        name: 'XLS',
        location: undefined,
        // component: '',
      },
      {
        id: 3,
        name: 'Descargar plantilla',
        data: null,
      },
    ],
  },
  {
    id: 2,
    header: 'Exportar',
    icon: fileExport,
    active: true,
    buttons: [
      {
        id: 1,
        name: 'CSV',
        //function: exportToCSV,
        data: null,
        headers: null,
      },
      {
        id: 2,
        name: 'XLS',
        // component: '',
      },
      // {
      //     id: 3,
      //     name: 'PDF',
      //     data: null,
      // },
    ],
  },
];
