import React from 'react';

import { NavMenuLeft } from '../../components/NavMenuLeft/NavMenuLeft';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { bool, func, object } from 'prop-types';
import { useStore } from 'react-redux';

import { NavMenuLeftWrapper } from './NavMenuLeftScreen.style.jsx';

export const NavMenuLeftScreen = ({ user }) => {
  // export const NavMenuLeftScreen = ({ user, setIsClosed }) => {
  // const { isDataModified, isLocationModified } = useSelector((state) => state.system);
  // const [isWarning, setIsWarning] = useState(false);
  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  // const setShowWarning = (status) => {
  //   event.preventDefault(event);
  //   if (status !== undefined) {
  //     setIsWarning(status);

  //     console.log('setShowWarning updated: ' + status);
  //   }
  // };

  // const handleOnClick = () => {
  //   event.stopPropagation();
  //   navigator.block;
  //   isDataModified && isLocationModified ? setIsWarning(true) : '';
  // };

  return (
    <NavMenuLeftWrapper isMenuOpen={isMenuOpen} className="navMenuLeft__container">
      <NavMenuLeft isMenuOpen={isMenuOpen} menu={menuNavLeft} user={user} />
    </NavMenuLeftWrapper>
  );
};

NavMenuLeftScreen.propTypes = { user: object, isClosed: bool, setIsClosed: func };
