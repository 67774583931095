import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { formCreateQueue_Wide4, optionsDestination } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
// import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import useNumbering from '../../../hooks/useNumbering';
import { string } from 'prop-types';
// import { useStore } from 'react-redux';
import { arraysAreEqual } from '../../../utils/AppUtils';

export const QueueFormScreen = ({ crudType }) => {
  const { applicationsForm, destination, getDestination, getQueues, createQueue, updateQueue, getSongWaitingLink, songWaiting, getApplicationsLocutions, arrayLocutionsHook, queuesForm } =
    useApplications();

  const { getExtensions, arrayNumberingExtensionsHook, getNumberingList } = useNumbering();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const data = formCreateQueue_Wide4;

  useEffect(() => {
    applicationsForm({ action: 'clearForm' }, { form: 'queue' });
  }, []);

  const form = 'queue';

  useEffect(() => {
    // getHeadquarter();
    getExtensions();
    getDestination();
    getSongWaitingLink();
    getNumberingList();
    getApplicationsLocutions();

    if (crudType === 'edit') {
      setRefresh(true);

      applicationsForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        { form, element: 'id' }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.number,
        },
        {
          form,
          element: 'number',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form,
          element: 'name',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.maxWaitTime,
        },
        {
          form,
          element: 'maxWaitTime',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.announceInterval,
        },
        {
          form,
          element: 'announceInterval',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.annouceWaitTime,
        },
        {
          form,
          element: 'annouceWaitTime',
        }
      );

      applicationsForm(
        {
          action: 'input',
          value: location.state?.queueMaxWaitTime,
        },
        {
          form,
          element: 'queueMaxWaitTime',
        }
      );

      applicationsForm(
        {
          action: 'input',
          value: location.state?.timeHangup,
        },
        {
          form,
          element: 'timeHangup',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.time_retry,
        },
        {
          form,
          element: 'time_retry',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.time_ring,
        },
        {
          form,
          element: 'time_ring',
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.displayNameEdited,
        },
        {
          form,
          element: 'displayNameEdited',
        }
      );

      const newExtension = arrayNumberingExtensionsHook?.find((dest) => dest.id === location.state?.extensionId);

      newExtension
        ? applicationsForm(
            {
              action: 'select-option',
              value: newExtension?.id || location.state.extensionId,
            },
            {
              form: 'queue',
              element: 'extensionId',
            }
          )
        : null;

      applicationsForm(
        {
          action: 'input',
          value: location.state.destinationId,
        },
        {
          form: 'queue',
          element: 'destinationId',
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'Destination',
        },
        {
          value: location.state?.DestinationData?.id,
          label: location.state?.DestinationData.name,
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'DestinationType',
        },
        {
          label: location.state?.DestinationType.label,
          value: location.state?.DestinationType.value,
        }
      );

      applicationsForm(
        {
          action: 'select-option',
          value: location.state?.destinationTypeId,
        },
        {
          form: 'queue',
          element: 'destinationTypeId',
        }
      );
      // : null;

      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isCustomWelcome,
        },
        {
          form: 'queue',
          element: 'isCustomWelcome',
        }
      );

      // const newQueueCustomWelcome = arrayLocutionsHook?.find((dest) => dest.id === location.state?.customWelcomeId);

      // newQueueCustomWelcome
      //   ? applicationsForm(
      //       {
      //         action: 'select-option',
      //       },
      //       {
      //         form: 'queue',
      //         element: 'customWelcomeId',
      //       },
      //       {
      //         label: newQueueCustomWelcome?.description || arrayQueues.name,
      //         value: newQueueCustomWelcome?.id || location.state.customWelcomeId,
      //       }
      //     )
      //   : null;

      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isCustomPrompt,
        },
        {
          form: 'queue',
          element: 'isCustomPrompt',
        }
      );

      const newQueueCustomPrompt = arrayLocutionsHook?.find((dest) => dest.id === location.state?.customPromptId);

      newQueueCustomPrompt
        ? applicationsForm(
            {
              action: 'select-option',
            },
            {
              form: 'queue',
              element: 'customPromptId',
            },
            {
              label: newQueueCustomPrompt?.description,
              value: newQueueCustomPrompt?.id,
            }
          )
        : null;

      const newsurvey_soundpromptId = arrayLocutionsHook.find((dest) => dest.id === location.state?.survey_soundpromptId);

      newsurvey_soundpromptId
        ? applicationsForm(
            {
              action: 'select-option',
            },
            {
              form: 'queue',
              element: 'survey_soundpromptId',
            },
            {
              label: newsurvey_soundpromptId?.description,
              value: newsurvey_soundpromptId?.id,
            }
          )
        : null;

      // applicationsForm(
      //   {
      //     action: 'input',
      //     value: location.state?.survey_soundpromptId,
      //   },
      //   {
      //     form: 'queue',
      //     element: 'survey_soundpromptId',
      //   }
      // );

      // const newPromptDestination = destination.find((dest) => dest.id === location.state?.promptDestinationId);

      // newPromptDestination
      //   ?
      applicationsForm(
        {
          action: 'input',
          value: location.state?.promptDestinationId,
        },
        {
          form: 'queue',
          element: 'promptDestinationId',
        }
        // {
        //   label: location.state?.PromptDestination.label,
        //   value: location.state?.PromptDestination.value,
        // }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'promptDestination',
        },
        {
          value: location.state.PromptDestinationData.id,
          label: location.state.PromptDestinationData.name,
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'promptDestinationType',
        },
        {
          label: location.state?.PromptDestinationType.label,
          value: location.state?.PromptDestinationType.value,
        }
      );
      applicationsForm(
        {
          action: 'input',
          value: location.state?.promptDestinationTypeId,
        },
        {
          form: 'queue',
          element: 'promptDestinationTypeId',
        }
        // {
        //   label: location.state?.PromptDestinationType.label,
        //   value: location.state?.PromptDestinationType.value,
        // }
      );
      // : null;

      // const newPromptFall = destination.find((dest) => dest.id === location.state?.falloverDestinationId);
      // newPromptFall
      //   ?
      applicationsForm(
        {
          action: 'input',
          value: location.state?.falloverDestinationId,
        },
        {
          form: 'queue',
          element: 'falloverDestinationId',
        }
        // {
        //   label: location.state?.FalloverDestinationData?.id,
        // }
      );
      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'FalloverDestination',
        },
        {
          value: location.state?.FalloverDestinationData.id,
          label: location.state?.FalloverDestinationData.name || location.state?.FalloverDestinationData.AstVoicemail.mailbox,
        }
      );
      // const newPromptFallType = optionsDestination.find((dest) => dest.id === location.state?.falloverDestinationTypeId);
      // newPromptFallType
      //   ?
      applicationsForm(
        {
          action: 'input',
          value: location.state?.falloverDestinationTypeId,
        },
        {
          form: 'queue',
          element: 'falloverDestinationTypeId',
        }
        // {
        //   label: location.state?.FalloverDestinationType?.label,
        // }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'FalloverDestinationType',
        },
        {
          value: location.state?.FalloverDestinationType.value,
          label: location.state?.FalloverDestinationType.label,
        }
      );
      // : null;

      applicationsForm(
        {
          action: 'multiselect',
          value: location.state?.extensions,
        },
        {
          form: 'queue',
          element: 'extensions',
        }
      );

      const dataAlert = arrayLocutionsHook.find((ext) => ext.id === location.state?.alertinfo);

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'alertinfo',
        },
        {
          value: dataAlert?.id || location.state?.alertinfo,
          label: dataAlert?.name || location.state?.alertinfo,
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'dialEmpty',
        },
        {
          value: location.state?.dialEmpty.value,
          label: location.state?.dialEmpty.label,
        }
      );

      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form: 'queue',
          element: 'leaveWhenEmpty',
        },
        {
          value: location.state?.leaveWhenEmpty.value,
          label: location.state?.leaveWhenEmpty.label,
        }
      );

      const newmohId = songWaiting.find((dest) => dest.id === location.state?.mohId);

      newmohId
        ? applicationsForm(
            {
              action: 'select-option',
            },
            {
              form: 'queue',
              element: 'mohId',
            },
            {
              label: newmohId?.name,
              value: newmohId?.id,
            }
          )
        : null;

      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isAgentLog,
        },
        {
          form: 'queue',
          element: 'isAgentLog',
        }
      );
      applicationsForm(
        {
          action: 'select-option',
          value: location.state?.strategyId,
        },
        {
          form: 'queue',
          element: 'strategyId',
        }
      );
      applicationsForm(
        {
          action: 'select-option',
          value: location.state?.Strategy,
        },
        {
          form: 'queue',
          element: 'Strategy',
        }
      );
      // applicationsForm(
      //   {
      //     action: 'toggle',
      //     value: location.state?.isvirtual === 'Sí' ? 1 : 0,
      //   },
      //   {
      //     form: 'queue',
      //     element: 'isvirtual',
      //   }
      // );
      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isDisplayNameEdited,
        },
        {
          form: 'queue',
          element: 'isDisplayNameEdited',
        }
      );
      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isCallerAnnouce,
        },
        {
          form: 'queue',
          element: 'isCallerAnnouce',
        }
      );

      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isRecord,
        },
        {
          form: 'queue',
          element: 'isRecord',
        }
      );
      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isHoldLog,
        },
        {
          form: 'queue',
          element: 'isHoldLog',
        }
      );
      applicationsForm(
        {
          action: 'toggle',
          value: location.state?.isSurvey,
        },
        {
          form: 'queue',
          element: 'isSurvey',
        }
      );

      // applicationsForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'queue',
      //     element: 'promptDestinationId',
      //   },
      //   {
      //     label: location.state?.promptDestinationId,
      //     value: location.state?.promptDestinationId,
      //   }
      // );

      // data.tabs.map((tab) => {
      //   tab.steps.map((item) => {
      //     item.input.map((item2) => {
      //       if (item2.carType === 'input' && item2.ref.element != 'timeHangup' && item2.ref.element != 'maxWaitTime') {
      //         return applicationsForm(
      //           {
      //             action: item2.cardType,
      //             value: item2.defaultValues,
      //           },
      //           {
      //             form: 'queue',
      //             element: item2.ref.element,
      //           }
      //         );
      //       }
      //     });
      //   });
      // });
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayLocutionsHook && arrayLocutionsHook.length > 0) {
        data.tabs.map((item) => {
          item.steps.map((step) => {
            step.input?.map((item2) => {
              if (item2.ref.element === 'mohId') {
                item2.options = [];
                songWaiting.forEach((item3) => {
                  const moh = {
                    label: item3.name,
                    value: item3.id?.toString(),
                  };
                  const found = songWaiting.some((e) => e.id.toString() === moh.value);
                  if (found && moh.value !== undefined) {
                    item2.options.push(moh);
                  }
                });
              }
              if (item2.ref.element === 'Destination') {
                item2.options = [];
                optionsDestination.forEach((item6) => {
                  const destinationName = {
                    label: item6.label,
                    value: item6.id?.toString(),
                  };
                  const found = destination?.some((e) => e.id.toString() === destinationName.value);
                  if (found) {
                    item2.options.push(destinationName);
                  }
                });
              }
              if (item2.ref.element === 'destinationTypeId') {
                item2.options = [];
                optionsDestination.forEach((item6) => {
                  const destinationName = {
                    value: item6.id?.toString(),
                    label: item6.label,
                  };
                  const found = destination?.some((e) => e.id.toString() === destinationName.value);
                  if (found) {
                    item2.options.push(destinationName);
                  }
                });
              }
              if (item2.ref.element === 'extensionId') {
                item2.options = [];
                arrayNumberingExtensionsHook?.forEach((item6) => {
                  const extension = {
                    value: item6.id?.toString(),
                    label: item6.name,
                  };
                  const found = arrayNumberingExtensionsHook.some((e) => e.id.toString() === extension.value);
                  if (found && extension.value !== undefined) {
                    item2.options.push(extension);
                  }
                });
              }
              if (item2.ref.element === 'falloverDestinationTypeId') {
                item2.options = [];
                optionsDestination.forEach((item6) => {
                  const destinationName = {
                    value: item6.id?.toString(),
                    label: item6.label,
                  };
                  const found = destination?.some((e) => e.id.toString() === destinationName.value);
                  if (found) {
                    item2.options.push(destinationName);
                  }
                });
              }

              if (item2.ref.element === 'extensions') {
                item2.options = [];
                arrayNumberingExtensionsHook.forEach((item6) => {
                  const extension = {
                    value: item6.id?.toString() || item6.toString(),
                    label: item6.name,
                  };
                  if (extension.value !== undefined) {
                    item2.options.push(extension);
                  }
                });
              }
              if (item2.ref.element === 'alertinfo') {
                item2.options = [];
                arrayLocutionsHook.forEach((item) => {
                  const alert = {
                    value: item.id,
                    label: item.description,
                  };
                  item2.options.push(alert);
                });
              }
              if (item2.ref.element === 'customWelcomeId') {
                item2.options = [];
                arrayLocutionsHook.forEach((item) => {
                  const newValue = {
                    value: item.id,
                    label: item.description,
                  };

                  item2.options.push(newValue);
                });
              }
              if (item2.ref.element === 'customPromptId') {
                item2.options = [];
                arrayLocutionsHook.forEach((item) => {
                  const newValue = {
                    value: item.id,
                    label: item.description,
                  };

                  item2.options.push(newValue);
                });
              }
              if (item2.ref.element === 'survey_soundpromptId') {
                item2.options = [];
                arrayLocutionsHook.forEach((item) => {
                  const newValue = {
                    value: item.id,
                    label: item.description,
                  };

                  item2.options.push(newValue);
                });
              }
              if (item2.ref.element === 'promptDestinationTypeId') {
                item2.options = [];
                optionsDestination.forEach((item6) => {
                  const destinationName = {
                    value: item6.id?.toString(),
                    label: item6.label,
                  };
                  const found = destination?.some((e) => e.id.toString() === destinationName.value);
                  if (found) {
                    item2.options.push(destinationName);
                  }
                });
              }
              setRefresh(true);
              return item2;
            });
          });

          return item;
        });

        if (crudType === 'edit' && (queuesForm.id === '' || queuesForm.id !== location.state.id)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.tabs.map((step) => {
            step.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'mohId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.mohId.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.mohId);
                    }
                    break;
                  case 'destinationId':
                    item2.defaultValues = queuesForm?.destinationId;
                    break;
                  case 'destinationTypeId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value === location.state?.destinationTypeId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.destinationTypeId);
                    }
                    break;
                  case 'Destination':
                    item2.defaultValues = location.state?.Destination;
                    break;
                  case 'DestinationType':
                    return (item2.defaultValues = location.state?.DestinationType);
                  case 'promptDestinationId':
                    return (item2.defaultValues = location.state?.promptDestinationId);
                  case 'promptDestinationTypeId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value === location.state?.promptDestinationTypeId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.promptDestinationTypeId);
                    }
                    break;
                  case 'promptDestination':
                    return (item2.defaultValues = location.state?.promptDestination);
                  case 'promptDestinationType':
                    return (item2.defaultValues = location.state?.promptDestinationType);

                  case 'extensions':
                    if (item2.options) {
                      if (arraysAreEqual(location.state.extensions, queuesForm?.extensions) === false) {
                        location.state.extensions = queuesForm?.extensions;
                        applicationsForm(
                          {
                            action: 'select-option',
                            value: location.state?.extensions,
                          },
                          {
                            form: 'queue',
                            element: 'extensions',
                          }
                        );
                      }
                      return (item2.defaultValues = location.state?.extensions);
                    }
                    break;
                  case 'customPromptId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.customPromptId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.customPromptId);
                    }
                    break;
                  case 'customWelcomeId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.customWelcomeId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.customWelcomeId);
                    }
                    break;
                  case 'alertinfo':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.alertinfo.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.alertinfo);
                    }
                    break;

                  case 'strategyId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.strategyId.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.strategyId);
                    }
                    break;
                  case 'leaveWhenEmpty':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.leaveWhenEmpty.value.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.leaveWhenEmpty.value);
                    }
                    break;
                  case 'dialEmpty':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.dialEmpty.value.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.dialEmpty.value);
                    }
                    break;
                  case 'survey_soundpromptId':
                    if (item2.options) {
                      item2.options?.filter((item3) => {
                        if (item3.value.toString() === location.state?.survey_soundpromptId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.survey_soundpromptId);
                    }
                    break;
                  case 'falloverDestinationTypeId':
                    if (item2.options) {
                      optionsDestination?.filter((item3) => {
                        if (item3.id.toString() === location.state?.falloverDestinationTypeId?.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    } else {
                      return (item2.defaultValues = location.state?.falloverDestinationTypeId);
                    }
                    break;

                  // case 'falloverDestinationId':
                  //   if (item2.options) {
                  //     optionsDestination?.filter((item3) => {
                  //       if (item3.id.toString() === location.state?.falloverDestinationId?.toString()) {
                  //         return (item2.defaultValues = item3);
                  //       }
                  //       return false;
                  //     });
                  //   } else {
                  //     return (item2.defaultValues = location.state?.falloverDestinationId);
                  //   }
                  //   break;

                  default:
                    item2.defaultValues = queuesForm[item2.ref.element];
                    break;
                }
                setRefresh(true);
                return item2;
              });
              return item;
            });
          });
          setLoading(false);
        } else if (crudType === 'create') setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getQueues, `/${routesWide.applications}/${routesApplications.queue}`, 'queues', createQueue);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getQueues, `/${routesWide.applications}/${routesApplications.queue}`, 'queues', updateQueue);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PageScreen
          data={data}
          arrScreen={['Cola']}
          onSubmit={onSubmit}
          form={applicationsForm}
          dataSave={dataSave}
          crudType={crudType}
          maxwidth={'800px'}
          setRender={setRefresh}
          render={refresh}
          reducerForm={formCreateQueue_Wide4}
          // dataModalButtonToTable={dataModalButtonToTable}
        />
      )}
    </>
  );
};

QueueFormScreen.propTypes = { crudType: string };
