import React, { useEffect, useState, useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { LayoutGrid, LayoutList } from 'tabler-icons-react';
import { menuButtonsDropDownFiles } from '../../data/dataButtons';
import { colors } from '../../theme/colors';
import { DropDown } from '../DropDown/DropDown';
// import { Filter } from '../Filter/Filter';
import { Search } from '../Search/Search';
// import Dropdown2 from 'react-multilevel-dropdown';
import { NavButtonsWrapper } from './NavButtons.style';
import 'react-tooltip/dist/react-tooltip.css';
import { array, bool, func, object } from 'prop-types';
import { IconArrowLeft, IconPlus, IconDownload } from '@tabler/icons-react';
// import { IconArrowsDiff } from '@tabler/icons-react';
import { NavTable } from '../NavTable/NavTable';
// import { useMemo } from 'react';
// import { IconTrash } from '@tabler/icons-react';

export const NavButtons = ({
  isNavTable,
  // onClickCreate,
  deleteFunction,
  handleOpenFormCreate,
  // setIsFilter,
  // setIsEditMode,
  DATA,
  // isEditMode,
  // onClickAssign,
  optionButtons,
  importFile,
  setSearch,
  isFilter,
  checkboxesActives,
  setCheckboxesActives,
}) => {
  const [selectedId, setSelectedId] = useState('');
  const refContainer = useRef(null);
  // const refFilter = useRef(null);

  // const { users } = useSettings();
  const [, setSelectedItem] = useState([]);

  // const data = useMemo(() => DATA, [DATA]);

  menuButtonsDropDownFiles.forEach((item) => {
    if (item.id === 1) {
      if (optionButtons.file?.import === false) {
        item.active = false;
      } else {
        item.active = true;
        item.buttons.forEach((item2) => {
          if (item2.id === 3) {
            item2.data = optionButtons.file?.template;
          }
        });
      }
    }

    if (item.id === 2) {
      item.buttons.forEach((item2) => {
        if (item2.id === 1) {
          item2.data = optionButtons.file?.data;
          item2.headers = optionButtons.file?.headers;
        } else if (item2.id === 2) {
          item2.data = optionButtons.file?.data;
          item2.headers = optionButtons.file?.headers;
        } else if (item2.id === 3) {
          item2.data = optionButtons.file?.data;
        }
      });
    }
  });

  useEffect(() => {
    if (!isNavTable) {
      isNavTable = isNavTable === undefined ? true : isNavTable;
      // setDataCard(checkboxesActives);
    }
    setSelectedItem(checkboxesActives);
  }, [checkboxesActives]);

  // if (this.state.longList) {
  //   menuItems.push(<MenuItem key="3">3rd LONG SUPER LONG item</MenuItem>);
  // }
  // const menu = <DropDown>{menuItems}</DropDown>;
  const isUserForm = location.pathname.includes('usersList');
  const isNumbForm = location.pathname.includes('numberingList/update');
  //  || location.pathname.includes('numbering/numberingList');
  // const onClick = () => {
  //   console.log('click');
  // };

  // const handleOnClick = () => {
  //   isNumbForm ? setIsEditMode(!isEditMode) : onClickCreate;
  // };

  return (
    <NavButtonsWrapper className="nav__buttons__container">
      {/* <div className="container"> */}
      <div className="left">
        {optionButtons.return?.position === 'left' && <IconArrowLeft className={'iconActionMenu__close'} id="return-button" onClick={() => optionButtons.return.function()} />}
        {optionButtons.add?.position === 'left' ? (
          // <Dropdown2
          //  title={<IconPlus width={'30px'} height={'30px'} className="icon-plus" />}
          // position="right"
          // openOnHover={true}
          // buttonClassName={'dropdown__add__button'}
          // id="add-button"
          // menuClassName={'dropdown__add'}
          //className="icon-menu"> */}
          // {isUserForm ? (
          //  <Dropdown2.Item key="1" className="icon-plus2" onClick={onClickCreate}>
          //    <Plus className="icon-add" />
          //    Crear nuevo
          //  </Dropdown2.Item>
          //) : (
          // <Dropdown2.Item key="2" className="icon-plus2" onClick={onClickAssign}>
          //   <IconArrowsDiff className="icon__Form_title1" />
          //   Asignar dispositivo
          // </Dropdown2.Item>
          // )}
          //<Dropdown2.Item key="3" className="icon-separator" onClick={onClickAssign}></Dropdown2.Item>
          //<Dropdown2.Item key="4" className="icon-plus2" onClick={onClickAssign}>
          //<IconTrash />
          //Borrar
          //</Dropdown2.Item>
          // </Dropdown2>
          // ) : (
          <div className={'icon__action__container'}>
            <div id="add-button__container" className="icon_add_container" onClick={handleOpenFormCreate}>
              <IconPlus id="add-button" border={'0px'} width={'50px'} content={!isUserForm ? 'Nuevo usuario' : 'Nuevo dispositivo'} className="icon_add" />
              <span>{!isUserForm ? 'Nuevo usuario' : 'Nuevo dispositivo'}</span>
            </div>
            {/* <div className="icon_trash_container">
              <IconTrash className="icon_trash" />
              <span>Borrar</span>
            </div> */}
          </div>
        ) : null}

        {/* {isUserForm ? <span className="section__name">Dispositivos asignados</span> : null} */}
        {isNumbForm ? <span className="section__name">Reglas definidas</span> : null}
        {isNavTable ? <NavTable totalDocs={DATA?.length} setCheckboxesActives={setCheckboxesActives} checkboxesActives={checkboxesActives} deleteFunction={deleteFunction} /> : null}
      </div>
      {/* {!isUserForm ? ( */}
      <div className="right">
        {optionButtons.search?.position === 'right' && (
          <Search className={'search-button'} id={'search-button'} data={optionButtons.search.data} setSearch={setSearch} columns={optionButtons.search.columns} />
        )}
        {/* {optionButtons.filter?.position === 'right' && <Filter id={'filter-button'} isFilter={isFilter} setIsFilter={setIsFilter} refContainer={refFilter} />} */}
        {optionButtons.changeView?.position === 'right' && (
          <div id="changeView-button" className="changeView" onClick={() => optionButtons.changeView.function()}>
            {!optionButtons.changeView.isCardView ? <LayoutGrid strokeWidth={2} width={'28px'} height={'28px'} /> : <LayoutList strokeWidth={2} color={colors.black} />}
          </div>
        )}

        {optionButtons.file?.position === 'right' && (
          <DropDown
            id={'file-button'}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            layout={'1'}
            data={{ id: 1 }}
            Icon={IconDownload}
            menuButtonsDropDown={menuButtonsDropDownFiles}
            width={'35px'}
            height={'35px'}
            backgroundColorHover={colors.primaryColor}
            colorHover={colors.primaryColor}
            iconColor={colors.black}
            iconHover={colors.white}
            position={'calc(5% + 0px);'}
            importFile={importFile}
            refContainer={refContainer}
            isFilter={isFilter}
            borderHover={`1px solid ${colors.secondaryColor}`}
          />
        )}
        {/* {!users[0]?.status ? (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA?.tabs ? DATA.tabs : DATA} filter={dataFilterUsers} setSearch={setSearch} />
          </div>
        ) : null} */}
      </div>
      {/* ) : null} */}
      <ReactTooltip className="tooltip" anchorSelect="#add-button__container" place="right" effect="float" content="Crear nuevo" />
      <ReactTooltip className="tooltip" anchorSelect="#edit-button" place="bottom" content="Editar documento" delayShow={1000} />
      <ReactTooltip className="tooltip" anchorSelect="#return-button" place="bottom" content="Volver atrás" delayShow={1000} />
      <ReactTooltip className="tooltip" anchorSelect="#file-button" place="bottom" content="Importar / Exporta archivos" delayShow={1000} />
      <ReactTooltip className="tooltip" anchorSelect="#search-button" place="bottom" content="Buscar coincidencias" delayShow={1000} />
      <ReactTooltip className="tooltip" anchorSelect="#filter-button" place="bottom" content="Filtrar resultados" delayShow={1000} />
      <ReactTooltip className="tooltip" anchorSelect="#changeView-button" place="bottom" content="Cambiar tipo de vista" delayShow={1000} />
      {/* </div> */}
    </NavButtonsWrapper>
  );
};

NavButtons.propTypes = {
  isDropdownActive: bool,
  onClickCreate: func,
  onClickAssign: func,
  optionButtons: object,
  importFile: func,
  setSearch: func,
  setIsFilter: func,
  isFilter: object,
  setIsEditMode: func,
  isEditMode: bool,
  checkboxesActives: array,
  setCheckboxesActives: func,
  isNavTable: bool,
  DATA: array,
  deleteFunction: func,
  handleOpenFormCreate: func,
};
