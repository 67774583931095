import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { useDepartments } from '../../../hooks/useDepartments';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { optionsAllow } from '../../../data/dataOutbound';
// import { arraysAreEqual } from '../../../utils/AppUtils';
import useSettings from '../../../hooks/useSettings';
import { formCreateNumberingExtensions, modalAssignNumberingExtensions } from '../../../data/dataNumbering';
// import { getArrayFormatted } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const ExtensionsFormScreen = ({ crudType }) => {
  const { createExtension, arrayNumberingExtensionsHook, formExtensionsReducer, arrayNumberingHook, updateExtensions, listNumberingForm, getExtensions } = useNumbering();

  // getNumberingList;
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { departmentsHook, getDepartments } = useDepartments();
  const { getCategoriesGroupHook, categoriesGroupHook } = useOutbound();
  const { typeSwal } = useSwal();
  // const [checkboxesActives, setCheckboxesActives] = useState([]);
  // const [, setRefresh] = useState(false);
  useEffect(() => {
    listNumberingForm({ action: 'clearForm' }, { form: 'extension' });
    // getNumberingList();
  }, []);

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { getUsers, users } = useSettings();

  const location = useLocation();

  const data = crudType === 'assignUserDispo' || crudType === 'createUserDispo' ? modalAssignNumberingExtensions : formCreateNumberingExtensions;

  const handleRecord = (record) => {
    if (record === '' || record === undefined || record === null) return { empty: true, record: [] };
    else {
      const allRecords = record.split('');

      const auxRecords = { empty: false, record: [] };

      // allRecords.forEach((rec) => {
      if (allRecords[0] === '1')
        auxRecords.record.push({
          label: 'Internas',
          value: 'internas',
          id: 1,
        });
      else if (allRecords[1] === '1')
        auxRecords.record.push({
          label: 'Salientes',
          value: 'salientes',
          id: 2,
        });
      else if (allRecords[2] === '1')
        auxRecords.record.push({
          label: 'Externas',
          value: 'externas',
          id: 3,
        });
      // else if (import.meta.env.VITE_DEBUG === 'true') console.log('error');
      // });

      return auxRecords;
    }
  };

  // const optionsAllow = [
  //   { label: 'Allaw', value: 'alaw', id: 1 },
  //   { label: 'Ulaw', value: 'ulaw', id: 2 },
  //   { label: 'g729', value: 'g729', id: 3 },
  // ];

  useEffect(() => {
    getHeadquarter();
    getDepartments();
    getCategoriesGroupHook();
    getUsers();
    getExtensions();

    if (crudType === 'edit' || crudType === 'assignUserDispo' || crudType === 'createUserDispo') {
      const finalRecord = handleRecord(location.state?.record);
      const namedpickupgroup = location.state?.pickupgroup === 'null' || location.state?.pickupgroup === undefined ? '' : location.state?.pickupgroup;
      const depArr = namedpickupgroup?.split(', ');
      const newNamePickupGroup = [];
      const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };
      if (depArr[0] !== '') {
        for (let dep of depArr) {
          const match = departmentsHook.find((obj) => removeAccents(obj.name.toLowerCase()) === removeAccents(dep.toLowerCase()));
          if (match) {
            newNamePickupGroup.push({ label: match.name, value: match.id });
          } else {
            newNamePickupGroup.push({ label: 'not found', value: 'not found' });
          }
        }
      }

      // let newAllow = [];

      // if (Array.isArray(location.state?.allow)) {
      //   newAllow = location.state?.allow;
      // } else {
      // newAllow = getArrayFormatted(location.state?.AstSipPeer?.allow, optionsAllow, 'allow');
      // }

      // newAllow.forEach((itemAllow) => {
      // newAllow
      // ?
      listNumberingForm(
        {
          action: 'select-option',
          // value: newAllow.value,
          // label: newAllow.label,
          // itemAllow,
        },
        {
          form: 'extension',
          element: 'allow',
        },
        location.state?.allow || ''
      );
      // : null;
      // });

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'pikedGroup',
        },
        newNamePickupGroup
      );

      // listNumberingForm(
      //   {
      //     action: 'select-option',
      //     value: newAllow,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'allow',
      //   }
      // );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.sipPeerId,
        },
        {
          form: 'extension',
          element: 'sipPeerId',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.id,
        },
        {
          form: 'extension',
          element: 'id',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.extension,
        },
        {
          form: 'extension',
          element: 'extension',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.name,
        },
        {
          form: 'extension',
          element: 'name',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.password,
        },
        {
          form: 'extension',
          element: 'password',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.ringTimeout,
        },
        {
          form: 'extension',
          element: 'ringTimeout',
        }
      );

      let newIdentification = {};
      let newAction = '';

      if (location.state.ddiId) {
        newIdentification = {
          value: location.state?.ddiId,
          label: location.state?.identification,
          type: location.state?.typeExtension,
        };
        newAction = 'input';
      } else {
        newIdentification = {
          value: location.state?.ddiId,
          label: arrayNumberingExtensionsHook[0].name + ' - ' + arrayNumberingExtensionsHook[0].typeExtension,
          type: arrayNumberingExtensionsHook[0].typeExtension,
        };
        newAction = 'select-option';
      }

      listNumberingForm(
        {
          action: newAction,
        },
        {
          form: 'extension',
          element: 'identification',
        },
        {
          value: newIdentification.value,
          label: newIdentification.label,
          type: newIdentification.typeExtension,
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'typeExtension',
        },
        {
          value: location.state.typeExtension || 1,
          //  || formExtensionsReducer.typeExtension.value,
          label: location.state.typeExtension || 'Todos',
          // || formExtensionsReducer.typeExtension.label,
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: location.state?.callForward,
        },
        {
          form: 'extension',
          element: 'callForward',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: location.state?.callForwardNA,
        },
        {
          form: 'extension',
          element: 'callForwardNA',
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'companyId',
        },
        {
          label: location.state?.company,
          value: String(location.state?.companyId),
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'userId',
        },
        {
          value: location.state.User?.id,
          label: location.state?.User?.fullName,
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'department',
        },
        {
          label: location.state?.department,
          value: String(location.state?.departmentId),
        }
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'categories',
        },
        {
          label: location.state?.categoryGroup,
          value: String(location.state?.categoryGroupId),
        }
      );

      listNumberingForm(
        {
          action: 'toggle',
          value: location.state?.voicemailActive,
        },
        {
          form: 'extension',
          element: 'voicemailActive',
        }
      );
      if (finalRecord.empty === false)
        finalRecord.record.forEach((rec) => {
          listNumberingForm({ action: 'select-option', value: rec }, { form: 'extension', element: 'record' });
        });

      listNumberingForm(
        {
          action: 'toggle',
          // value: { label: 'Activa', value: 1, id: 6 },
          value: location.state?.callWaiting,
        },
        {
          form: 'extension',
          element: 'callWaiting',
        }
      );

      // listNumberingForm(
      //   {
      //     action: 'button',
      //     // value: { label: 'Activa', value: 1, id: 6 },
      //     value: location.state?.password,
      //   },
      //   {
      //     form: 'extension',
      //     element: 'password',
      //   }
      // );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof formExtensionsReducer === 'object' && arrayNumberingHook.length > 0 && arrayHeadquarterHook.length > 0 && departmentsHook.length > 0 && categoriesGroupHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'userId') {
              item2.options = [];
              users?.forEach((item3) => {
                if (item3?.firstname === null) {
                  item3.firstname = 'not defined';
                }
                const company = {
                  label: item3?.firstname + ' ' + item3?.lastname,
                  value: item3?.id,
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref.element === 'identification') {
              item2.options = [];
              arrayNumberingExtensionsHook?.forEach((item3) => {
                if (item3?.Ddi === undefined) {
                  item3.ddi = 'not defined';
                }
                const company = {
                  label: item3?.name + ' - ' + item3.typeExtension,
                  value: item3?.Ddi.id,
                  type: item3?.typeExtension,
                };
                const typeFilter = location.state?.filterType === 1 || formExtensionsReducer.filterType === 1 ? formExtensionsReducer.typeExtension.value : '';
                const found = item2.options.some((e) => e.value === company.value);

                if (!found && typeFilter === '' && (formExtensionsReducer.typeExtension.value === item3.typeExtension || formExtensionsReducer.typeExtension.label === 'Todos')) {
                  item2.options.push(company);
                } else {
                  formExtensionsReducer.typeExtension.value === typeFilter && company.value && company.value !== undefined ? item2.options.push(company) : null;
                }
              });
            }
            // if (item2.ref.element === 'companyId') {
            //   arrayHeadquarterHook.forEach((item3) => {
            //     if (item3.name === null) {
            //       item3.name = 'not defined';
            //     }
            //     const company = {
            //       label: item3.name,
            //       value: item3.id?.toString(),
            //     };
            //     const found = item2.options.some((e) => e.value === company.value);
            //     if (!found && company.value !== undefined) {
            //       item2.options.push(company);
            //     }
            //   });
            // }
            // if (item2.ref.element === 'department') {
            //   item2.options = [];
            // }
            if (
              item2.ref.element === 'allow' &&
              (location.state?.Extension || formExtensionsReducer.typeExtension) &&
              (location.state?.Extension?.typeExtension === 'SIP' ||
                formExtensionsReducer.typeExtension.value === 'SIP' ||
                item2.defaultValues?.label === 'Todos' ||
                formExtensionsReducer.typeExtension.label === 'Todos')
            ) {
              item2.options = [];
              optionsAllow.forEach((item6) => {
                const newAllowItem = {
                  value: item6.id,
                  label: item6.label,
                };

                const found = item2.options.some((e) => e.value === newAllowItem.value);
                if (!found && newAllowItem.value !== undefined) {
                  item2.options.push(newAllowItem);
                }
              });
            }
            // if (item2.ref.element === 'categories') {
            //   categoriesGroupHook.forEach((item3) => {
            //     if (item3.description === null) {
            //       item3.description = 'not defined';
            //     }
            //     const category = {
            //       label: item3?.description,
            //       value: item3.id?.toString(),
            //     };
            //     const found = item2.options.some((e) => e.value === category.value);
            //     if (!found && category.value !== undefined) {
            //       item2.options.push(category);
            //     }
            //   });
            // }

            return item2;
          });
          return item;
        });

        if ((crudType === 'edit' && formExtensionsReducer.id === '') || formExtensionsReducer.id !== location.state?.id) {
          // formExtensionsReducer.number === '' ||
          // formExtensionsReducer.number !== location.state?.extension ||
          // formExtensionsReducer.callForward !== location.state?.callForward ||
          // formExtensionsReducer.department.value === '' ||
          // +formExtensionsReducer.category.value !== +location.state?.categoryGroupId)
          // (formExtensionsReducer.pikedGroup.length === 0 && formExtensionsReducer.typeExtension.label === 'sip')
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' || crudType === 'assignUserDispo') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'userId':
                  item2.defaultValues = formExtensionsReducer.userId;
                  break;
                case 'extension':
                  item2.defaultValues = formExtensionsReducer.number;
                  break;
                case 'name':
                  item2.defaultValues = formExtensionsReducer.name;
                  break;
                case 'password':
                  item2.defaultValues = formExtensionsReducer.password;
                  break;
                case 'ringTimeout':
                  item2.defaultValues = formExtensionsReducer.ringTimeout;
                  break;
                case 'identification':
                  // item2.options.filter((item3) => {
                  //   if (item3.label === formExtensionsReducer.identification.label) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = formExtensionsReducer.identification;
                  break;
                case 'typeExtension':
                  // listNumberingForm(
                  //   {
                  //     action: 'input',
                  //     value: location.state?.id + '_' + location.state?.typeExtension + '_' + location.state.extension,
                  //   },
                  //   {
                  //     form: 'extension',
                  //     element: 'identification',
                  //   }

                  // );
                  // item2.options.filter((item3) => {
                  //   if (item3.label === formExtensionsReducer.typeExtension.label) {
                  //     // location.state.identification = location.state?.id + '_' + location.state?.typeExtension + '_' + location.state.extension;
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = formExtensionsReducer.typeExtension;
                  break;
                case 'callForward':
                  item2.defaultValues = formExtensionsReducer.callForward;
                  break;
                case 'callForwardNA':
                  item2.defaultValues = formExtensionsReducer.callForwardNA;
                  break;

                case 'companyId':
                  item2.options.filter((item3) => {
                    if (item3.value === formExtensionsReducer.companyId.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'department':
                  item2.defaultValues = formExtensionsReducer.department;

                  break;
                case 'categories':
                  item2.options.filter((item3) => {
                    if (item3.value === formExtensionsReducer.category.value) {
                      item2.defaultValues = item3;
                    }
                    return false;
                  });
                  break;
                case 'record':
                  item2.defaultValues = [];
                  if (formExtensionsReducer.record.length !== 0) {
                    formExtensionsReducer.record.map((rec) =>
                      item2.options.forEach((rec2) => {
                        if (rec.value === rec2.value) item2.defaultValues = [...item2.defaultValues, rec];
                      })
                    );
                  }

                  break;
                case 'voicemailActive':
                  item2.defaultValues = formExtensionsReducer.voicemailActive;

                  break;
                case 'callWaiting':
                  item2.defaultValues = location.state?.callWaiting;

                  break;
                case 'pikedGroup':
                  item2.defaultValues = formExtensionsReducer.pikedGroup;
                  break;

                case 'allow':
                  // if (item2.options) {
                  //   if (arraysAreEqual(location.state.allow, formExtensionsReducer?.allow) === false) {
                  //     location.state.allow = formExtensionsReducer?.allow;
                  //     listNumberingForm(
                  //       {
                  //         action: 'select-option',
                  //         value: location.state?.allow,
                  //       },
                  //       {
                  //         form: 'extension',
                  //         element: 'allow',
                  //       }
                  //     );
                  //   }
                  // }
                  // return (item2.defaultValues = location.state?.allow);
                  // item2.defaultValues = location.state?.allow;
                  // break;
                  // if (item2.options) {
                  // if (arraysAreEqual(location.state.allow, formExtensionsReducer?.allow) === false) {
                  //   location.state.allow = formExtensionsReducer?.allow;
                  //   listNumberingForm(
                  //     {
                  //       action: 'multiselect',
                  //     },
                  //     {
                  //       form: 'extension',
                  //       element: 'allow',
                  //     },
                  //     location.state?.allow
                  //   );
                  // }
                  // if (formExtensionsReducer?.allow && arraysAreEqual(location.state?.allow, formExtensionsReducer?.allow) === false) {
                  //   location.state.allow = formExtensionsReducer?.allow;
                  //   listNumberingForm(
                  //     {
                  //       action: 'select-option',
                  //       value: location.state.allow,
                  //     },
                  //     {
                  //       form: 'extension',
                  //       element: 'allow',
                  //     }
                  //   );
                  // }
                  item2.defaultValues = location.state?.allow;

                  // break;
                  // if (arraysAreEqual(location.state?.allow, formExtensionsReducer?.allow) === false) {
                  //   // location.state.allow = formExtensionsReducer?.allow;
                  //   listNumberingForm(
                  //     {
                  //       action: 'select-option',
                  //       value: location.state.allow,
                  //     },
                  //     {
                  //       form: 'extension',
                  //       element: 'allow',
                  //     }
                  //   );

                  // }
                  // return (item2.defaultValues = location.state?.allow);

                  break;

                default:
                  // item2.defaultValues = formExtensionsReducer[item2.ref.element];
                  break;
              }
              setDataSave(true);
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'createUserDispo' || crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'userId':
                  item2.defaultValues = formExtensionsReducer?.userId;
                  break;
                case 'typeExtension':
                  item2.defaultValues = formExtensionsReducer?.typeExtension;
                  //  location.state?.typeExtension ||
                  break;
                case 'identification':
                  item2.defaultValues = location.state?.id + '_' + location.state?.Extension?.typeExtension + '_' + location.state?.SIPExtensionId;
                  //  location.state?.typeExtension ||
                  break;
                case 'password':
                  item2.defaultValues = formExtensionsReducer?.password;
                  //  location.state?.typeExtension ||
                  break;
                default:
                  item2.defaultValues = '';
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        }
        // } else {
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getExtensions, `/${routesWide.numbering}/${routesNumbering.extensions}`, 'extension', createExtension);
    } else if (crudType === 'edit') {
      if (location.route) typeSwal('updateLoader', '', listNumberingForm, getExtensions, `${location.route}`, 'extension', updateExtensions);
      else typeSwal('updateLoader', '', listNumberingForm, getExtensions, `/${routesWide.numbering}/${routesNumbering.extensions}`, 'extension', updateExtensions);
    }
  };
  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          // checkboxesActives={checkboxesActives}
          // setCheckboxesActives={setCheckboxesActives}
          arrScreen={['Dispositivo']}
          data={data}
          onSubmit={onSubmit}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={crudType}
          reducerForm={formCreateNumberingExtensions}
        />
        //<PageScreen
        //data={data}
        //arrScreen={['Cola']}
        //onSubmit={onSubmit}
        //form={ExtensionsFormScreen}
        //dataSave={dataSave}
        //crudType={crudType}
        //maxwidth={'1000px'}
        //setRender={setRefresh}
        //render={refresh}
        //reducerForm={formCreateNumberingExtensions}
        ///>
      )}
    </>
  );
};

ExtensionsFormScreen.propTypes = { crudType: string };
