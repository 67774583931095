import styled from 'styled-components';

export const DestinationSelectWrapper = styled.div`
  .basic-single {
    flex: 1;
    color: black;
    box-sizing: border-box;
    height: 35px;
    border: none;
  }

  .basic {
    flex: 1;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    height: 35px;
    width: 50%;
    border: none;
    margin-left: 10px;
  }

  .basic__destination {
    color: black;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    top: -20px;
    left: 260px;
    border: none;
  }

  .destination-select {
    width: 220px;
  }

  .btn-Crear {
    margin: auto;
    padding: 5px;
    color: grey;
    cursor: pointer;
  }
`;
