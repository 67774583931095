import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formGroupCategories } from '../../../data/dataOutbound';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesOutbound, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
// import { arraysAreEqual } from '../../../utils/AppUtils';

export const GroupCategoriesForm = ({ crudType }) => {
  const { outboundRoutesForm, outboundRoutesFormCategoriesGroup, createCategoriesGroup, categoriesHook, getCategoriesHook, updateCategoriesGroup, getCategoriesGroupHook } = useOutbound();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formGroupCategories;
  // outboundRoutesFormCategoriesGroup.categories = [];

  useEffect(() => {
    getCategoriesHook();
    getCategoriesGroupHook();

    if (crudType === 'edit') {
      setDataSave(true);

      outboundRoutesForm(
        {
          action: 'id',
          value: location.state?.CategoriesGroup.id,
        },
        {
          form: 'groupCategories',
          element: 'id',
        }
      );

      outboundRoutesForm(
        {
          action: 'input',
          value: location.state?.CategoriesGroup.description,
        },
        {
          form: 'groupCategories',
          element: 'name',
        }
      );
      // location.state?.arrayCategories.length > 0
      //   ?
      // location.state?.categories .map((item) =>
      outboundRoutesForm(
        {
          action: 'multiselect',
          value: location.state?.categories,
          //  outboundRoutesFormCategoriesGroup?.categories.length > 0 ? outboundRoutesFormCategoriesGroup?.categories :
        },
        {
          form: 'groupCategories',
          element: 'categories',
        }
      );
      // );
      //   :
      // outboundRoutesForm(
      //   {
      //     action: 'select-option',
      //     value: location.state?.arrayCategoriesGroup,
      //   },
      //   {
      //     form: 'groupCategories',
      //     element: 'listCategories',
      //   }
      // );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (categoriesHook.length === 0) {
        setLoadingCounter(loadingCounter + 1);
      }
      if (categoriesHook.length > 0) {
        data.steps.map((item) => {
          if (item.input[0].cardType === 'multiselect') {
            item.input[0].options = [];
          }
          const result =
            item.input[0].cardType === 'multiselect'
              ? categoriesHook.forEach((item2) => {
                  if (item2.description === null) {
                    item2.description = 'not defined';
                  }
                  const category = {
                    value: item2.id,
                    label: item2.description,
                  };
                  const found = item.input[0].options.some((e) => e.value === category.value);
                  if (!found && category.value !== undefined) {
                    item.input[0].options.push(category);
                  }
                })
              : null;

          return result;
        });
        if (crudType === 'edit' && (outboundRoutesFormCategoriesGroup.name === '' || outboundRoutesFormCategoriesGroup.name !== location.state.CategoriesGroup.description)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' && outboundRoutesFormCategoriesGroup.name === location.state?.CategoriesGroup.description) {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = outboundRoutesFormCategoriesGroup.name;
                  break;
                case 'categories':
                  // if (item2.options) {
                  //   item2.options?.filter((item3) => {
                  //     if (item3.value === location.state?.categories?.toString()) {
                  //       return (item2.defaultValues = item3);
                  //     }
                  //     return false;
                  //   });
                  // } else {
                  item2.defaultValues = location.state?.categories;
                  // }

                  // if (item2.options) {
                  //   if (item2.defaultValues !== outboundRoutesFormCategoriesGroup.categories) {
                  //     outboundRoutesForm(
                  //       {
                  //         action: 'multiselect',
                  //       },
                  //       {
                  //         form: 'groupCategories',
                  //         element: 'categories',
                  //       },
                  //       outboundRoutesFormCategoriesGroup.categories
                  //     );
                  //   }

                  //   return (item2.defaultValues = location.state?.categories);
                  // }
                  // if (item2.options) {
                  //   if (arraysAreEqual(location.state.categories, outboundRoutesFormCategoriesGroup?.categories) === false) {
                  //     location.state.categories = outboundRoutesFormCategoriesGroup?.categories;
                  //     outboundRoutesForm(
                  //       {
                  //         action: 'multiselect',
                  //         // value: location.state?.categories,
                  //       },
                  //       {
                  //         form: 'groupCategories',
                  //         element: 'categories',
                  //       },
                  //       outboundRoutesFormCategoriesGroup?.categories
                  //     );
                  //   }
                  //   return (item2.defaultValues = location.state?.categories);
                  // }
                  break;
                default:
                  item2.defaultValues = outboundRoutesFormCategoriesGroup[item2.ref.element];
                  break;
              }
              // setDataSave(true);
              setDataSave(true);
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      try {
        typeSwal('createLoader', '', formGroupCategories, getCategoriesGroupHook, `/${routesWide.outboundRoutes}/${routesOutbound.groupCategories}`, 'groupCategories', createCategoriesGroup);
      } catch (err) {
        return err;
      }
    } else if (crudType === 'edit') {
      try {
        typeSwal('createLoader', '', formGroupCategories, getCategoriesGroupHook, `/${routesWide.outboundRoutes}/${routesOutbound.groupCategories}`, 'groupCategories', updateCategoriesGroup);
      } catch (err) {
        return err;
      }
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen arrScreen={['Grupo categoría']} data={data} onSubmit={onSubmit} form={outboundRoutesForm} dataSave={dataSave} crudType={crudType} />}</>;
};

GroupCategoriesForm.propTypes = { crudType: string };
