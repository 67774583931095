import styled from 'styled-components';

export const CompanyScreenWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${'' /* position: relative; */}
  left: 70px;
  ${'' /* top: 70px; */}
  padding: 10px;
  width: 100%;
  ${'' /* margin-right: 10px; */}

  // .distributionTable {
  //   height: calc(100% - 55px);
  //   overflow-y: hidden;
  //   padding-left: 23px;
  //   padding-right: 23px;
  // }

  ${
    '' /* .contain__buttons {
    position: relative;
    top: 120px;
  } */
  }

  ${
    '' /* .contain__table {
    position: relative;
    top: 120px;
    width: 80%;
  } */
  }

  .navtable__container {
    top: 120px;
  }
`;
