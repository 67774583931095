import styled from 'styled-components';

export const ListNumberWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;

  .contain__cardGrid {
    height: calc(100% - 55px);
    box-sizing: border-box;
    padding: 20px 36px 0;
    //transition: all 1s easy-out;
  }

  .contain__notFound {
    height: calc(100% - 55px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
