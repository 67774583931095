import React from 'react';
// import { IconClick } from '@tabler/icons-react';
// import { Button } from '../../components/Button/Button';
// import { colors } from '../../theme/colors';
import { NotFoundWrapper } from './NotFoundScreen.style';
import { array, func, object, string } from 'prop-types';
import { MenuTop } from '../../components/MenuTop/MenuTop';

// export const NotFoundScreen = ({ icon, title, subtitle, textButton, onClick }) => {
export const NotFoundScreen = ({ icon, title, subtitle, menu }) => {
  return (
    <NotFoundWrapper>
      <MenuTop menu={menu ? menu : []} />
      <div className="contain-notFound">
        <div className="contain-img">
          <img src={icon.img} alt={icon.alt} />
        </div>
        <div className="contain-title">
          <h4>{title}</h4>
        </div>
        <div className="contain-subtitle">
          <p>{subtitle}</p>
        </div>
        {/* {textButton && (
          <div className="contain-button">
            <Button
              backgroundColor={colors.primaryColor}
              border={'0px'}
              colorText={colors.white}
              width={'100%'}
              Icon={IconClick}
              onClick={() => onClick()}
              hoverBackgroundColor={colors.secondaryColor}
              hoverColorText={colors.primaryColor}
              hoverIcon={colors.primaryColor}
              text={textButton}
            />
          </div>
        )} */}
      </div>
    </NotFoundWrapper>
  );
};

NotFoundScreen.propTypes = { icon: object, title: string, subtitle: string, textButton: string, onClick: func, menu: array };
