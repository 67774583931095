import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  padding: 5px;
  cursor: ${(props) => (props.active ? 'text' : 'pointer')};
  ${'' /* width: -web; */}

  &:hover {
    cursor: pointer;
    border-radius: 10px;
    background-color: ${colors.blue};
    color: ${colors.white};
  }

  span {
    background-color: #fff;
    border: 0.5px solid #7b7e82;

    border-radius: 8px;
    width: 80.12px;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    gap: 10px;
  }

  input {
    border: none;
    padding: 5px;
    padding-right: 5px;
    color: ${colors.black};
    font-weight: ${weight.medium};
    font-size: ${newFontSize.body};
    font-family: ${fontFamily.font};
    width: auto;
    border-radius: 10px;
    border: 1px solid ${colors.grey};

    &:focus {
      outline: none;
    }
    ${
      '' /* &:active {
      width: 1200px;
    } */
    }

    &::placeholder {
      color: ${colors.black};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.body};
      font-family: ${fontFamily.font};
    }
  }

  .search__icon {
    height: 30px;
    width: 30px;
    ${'' /* padding: 5px; */}
  }

  .search__delete__icon {
    width: 30px;
    height: 30px;
    position: relative;
    left: -35px;
    color: grey;
  }
`;
