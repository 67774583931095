import styled from 'styled-components';
import { colors } from '../../theme/colors';

import { weight } from '../../theme/fonts';

export const SettingsScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  padding: 10px;
  width: auto;
  margin-left: 70px;

  .menupref__link-active {
    box-sizing: border-box;
    width: max-content;
    color: ${colors.blue};
    font-weight: ${weight.bold};
    line-height: 16px;
    padding: 5px;
    letter-spacing: 0.25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid ${colors.blue};

    &:hover {
      cursor: pointer;
    }
  }

  .menupref__header__container {
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    position: fixed;
    top: 60px;
    display: flex;
    align-items: center;
    color: black;
    justify-content: center;
    padding: 5px;
    // font-size: 20px;
    border-radius: 15px;
    z-index: 102;
  }

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .contain__table {
    width: 100%;
    box-sizing: border-box;
    height: calc(100% - 55px);
    //transition: all 1s easy-out;
    display: flex;
    justify-content: space-between;
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    width: ${(p) => (p.isFilter ? '300px' : '0px')};

    //transition: width 0.3s ease-in-out;
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }

  // audio {
  //   text-align: right;
  // }
`;
