import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const ButtonPasswordWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  .icon__password {
    height: 30px;
    width: 30px;
    border: 1px solid ${colors.blue};
    padding: 3px;
    color: ${colors.blue};
    border-radius: 10px;
    margin: auto;

    &:hover {
      color: ${colors.white};
      background-color: ${colors.blue};
      border-radius: 10px;
    }
  }

  .text__password {
    font-weight: ${weight.regular};
    font-size: ${newFontSize.label};
    line-height: 1.1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

    p {
      margin: 0;
    }
  }
`;
