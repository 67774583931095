import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${
    '' /* position: relative;
  top: 120px; */
  }

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .pageScreen__title {
    position: fixed;
    background-color: white;
    left: 13%;
    box-sizing: border-box;
    width: auto;
    // min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      background-color: white;
      margin-left: 10px;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.bold};
      letter-spacing: 0.25px;
    }
  }

  .pageScreen {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    top: 10px;

    &__tabs {
      background-color: #ffffff;
      display: -webkit-box;
      border: none;
      top: 65px;
      ${'' /* width: 80%; */}
      margin: auto;
      max-width: 800px;

      &__tab {
        min-width: 130px;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${colors.blue};

        &:hover {
          cursor: pointer;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          // font-size: ${newFontSize.h3};

          border: none;
        }
      }

      .active {
        min-height: 35px;
        padding: 10px;
        background-color: ${colors.blue};
        color: ${colors.white};
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid ${colors.blue};
        font-weight: ${weight.bold};
      }

      .inactive {
        min-height: 35px;
        padding: 10px;
        color: ${colors.blue};
        border-radius: 10px 10px 0 0;

        ${'' /* border-bottom: 1px solid ${colors.blue}; */}

        &:hover {
          cursor: pointer;
          border-bottom: 1px solid ${colors.blue};
          border-left: 3px solid ${colors.blue};
        }
      }
    }

    &__form {
      height: 100%;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;

      .contain__button-save {
        opacity: 1;
        height: 35px;
        position: fixed;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 75px;
        right: 10px;
        z-index: 718;

        .cancel_button {
          color: lightgrey;
          /* background-color: #c142269a; */
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #c14326;
          }
        }

        .save_button {
          color: lightgrey;
          background-color: #29903b;
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }
  }
`;
