import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const NavMenuLeftWrapper = styled.div`
  box-sizing: border-box;
  // //transition: all 1.5s;
  position: fixed;
  // top: ${(p) => (!p.isMenuOpen ? '60px' : '60px')};
  left: 0;
  z-index: 950;

  background-color: ${colors.primaryColor};
  // box-shadow: ${(p) => (!p.isMenuOpen ? '7px 5px 13px -5px rgba(0, 0, 0, 0.68);' : '228px')};
  // border-radius: ${(p) => (!p.isMenuOpen ? '10px' : '')};
  width: ${(p) => (!p.isMenuOpen ? '70px' : '228px')};
  // height: ${(p) => (!p.isMenuOpen ? '' : '100%')};
  height: 100%;

  .contain__logo {
    box-sizing: border-box;
    height: 60px;
    padding-top: 25px;
    width: 232px;

    img {
      width: 183.2px;
    }
  }

  .button__icon {
    color: ${colors.white};
    height: 45px;
    width: ${(p) => (!p.isMenuOpen ? '65px' : '65px')};
    display: flex;
    justify-content: flex-start;

    &:hover {
      color: ${colors.black};
      background-color: ${colors.white};
      border-radius: 10px;
    }
  }

  .contain__logo-close {
    box-sizing: border-box;
    // //transition: all 1s;
    // transition-delay: ${(p) => (!p.isMenuOpen ? 0.5 : 0)};
    height: 60px;
    padding-top: 25px;
    width: 53px;

    img {
      width: 183.2px;
    }
  }

  // .navMenuLeft__container {
  //   box-sizing: border-box;
  //   //transition: all 1.5s;
  //   position: fixed;
  //   top: 70px;
  //   z-index: 1;
  //   margin-left: 10px;
  //   background-color: ${colors.blue};
  //   box-shadow: 7px 5px 13px -5px rgba(0, 0, 0, 0.68);
  //   border-radius: 15px;
  //   width: ${(p) => (!p.isMenuOpen ? '65px' : '228px;')};
  // }
`;
