// import React from 'react';
// import { IconArrowAutofitContent } from '@tabler/icons-react';
import { optionsDestination } from '../data/dataApplications';

export const getDispo = (arrayNumberingExtensionsHook, userId) => {
  if (arrayNumberingExtensionsHook.length > 0) {
    let newArray = '';

    arrayNumberingExtensionsHook?.forEach((itemExt) => {
      itemExt.userId === userId ? (newArray = newArray !== '' ? newArray + ', ' + itemExt.typeExtension : newArray + itemExt.typeExtension) : null;
    });

    return newArray;
  }
};

export const getArrayFiltered = (arrayToFilter, itemName, itemValue) => {
  const stringToSearch = itemValue !== 'SIP' ? itemValue.toLowerCase() : itemValue;
  const newArray = arrayToFilter?.filter((item) => item[itemName] === stringToSearch);

  return newArray;
};

export const getArrayFormatted = (arrayToFormat, fromData, formatType) => {
  const paramIsArray = Array.isArray(arrayToFormat);

  let newArray = [];

  if ((paramIsArray && arrayToFormat.length) || formatType === 'allow') {
    switch (formatType) {
      case 'allow':
        if (fromData) {
          const allowArr = arrayToFormat?.split(',');

          // : [arrayToFormat];

          allowArr?.forEach((newValue) => {
            const valueToSearch = newValue.replace(' ', '').toLowerCase();
            const newExt = fromData?.find((ext2) => ext2?.value === valueToSearch);

            newExt ? newArray.push({ value: newExt.id, label: newExt.label }) : null;
          });
        }

        break;
      case 'ini':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find(
                (ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value ? newValue?.value?.toString() : newValue?.toString())
              );

              newExt
                ? newArray.push({ value: newExt?.id ? newExt?.id : newValue?.id ? newValue.id : newValue, label: newExt?.name ? newExt?.name : newValue?.name ? newValue?.name : newValue })
                : null;
            })
          : null;

        break;
      case 'state':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find((ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value?.toString() || newValue?.toString()));

              newExt
                ? newArray.push({ value: newExt?.id ? newExt?.id : newValue?.id ? newValue.id : newValue, label: newExt?.name ? newExt?.name : newValue?.name ? newValue?.name : newValue })
                : null;
            })
          : null;

        break;
      case 'sort':
        fromData
          ? arrayToFormat.forEach((newValue) => {
              const newExt = fromData?.find(
                (ext2) => ext2?.id.toString() === (newValue?.id !== undefined ? newValue.id?.toString() : newValue?.value ? newValue?.value?.toString() : newValue?.toString())
              );

              newExt ? newArray.push({ id: newValue.value ? newValue.value : newValue, name: newExt?.name }) : null;
            })
          : null;

        break;
      case 'onlyFormat':
        newArray = arrayToFormat.map((newValue) => {
          return { id: newValue.value, name: newValue.label };
        });
        break;

      default:
        break;
    }
  }
  return newArray.length > 0 ? newArray : arrayToFormat;
};

// export const getDestinationInfo = (destinationId, destinationTypeId, arrayDestination) => {
//   const idType = optionsDestination[destinationTypeId - 1];
//   let arrayToSearch = [];

//   switch (idType.ref) {
//     case 'queue':
//       arrayToSearch = arrayDestination;
//       break;
//     case 'conference':
//       arrayToSearch = arrayDestination;
//       break;
//     case 'voicemail':
//       arrayToSearch = arrayDestination;
//       break;
//     default:
//       arrayToSearch = arrayDestination;
//       break;
//   }
//   const newValue = arrayToSearch.length ? arrayToSearch?.find((item) => item?.id === destinationId) : '';
//   return newValue;
// };

export const getDestination = (itemId, itemType) => {
  // const options = [
  //   { value: 'conference', label: 'Conferencia', ref: 'conference', options: [] },
  //   {
  //     value: 'voicemail',
  //     label: 'Búzon',
  //     ref: 'voicemail',
  //     options: [],
  //     arrayName: 'voicemail',
  //   },
  //   {
  //     value: 'ivr',
  //     label: 'IVR',
  //     ref: 'ivr',
  //     options: [],
  //     arrayName: 'ivr',
  //   },
  //   {
  //     value: 'soundPrompt',
  //     label: 'Locución',
  //     options: [],
  //     ref: 'soundPrompt',
  //     arrayName: 'soundPrompt',
  //   },
  //   {
  //     value: 'queue',
  //     label: 'Cola',
  //     options: [],
  //     ref: 'queue',
  //     arrayName: 'queue',
  //   },
  //   {
  //     value: 'extension',
  //     label: 'Usuarios',
  //     options: [],
  //     ref: 'extension',
  //     arrayName: 'extension',
  //   },
  //   {
  //     value: 'emergencyManager',
  //     label: 'Emergency Manager',
  //     options: [],
  //     ref: 'emergencyManager',
  //     arrayName: 'emergencyManager',
  //   },
  //   {
  //     value: 'external',
  //     label: 'Numero externo',
  //     type: 'input',
  //     ref: 'external',
  //     arrayName: 'external',
  //   },
  //   {
  //     value: 'hangup',
  //     label: 'Colgar llamada',
  //     ref: 'hangup',
  //     arrayName: 'hangup',
  //   },
  // ];

  if (itemId && itemType) {
    // let itemTable = '';
    // switch (options[itemType].value) {
    //   case 'queue':
    const itemTable = optionsDestination[itemType].ref;
    let found = '';

    found = optionsDestination.find((e) => e.value === itemTable);
    if (found) {
      // optionsDestination.push(found);
      return { value: found.id, label: found.label };
    }
    return false;
  }
};

export const arraysAreEqual = (object1, object2) => {
  let equals = false;

  if (object1 && object2) {
    equals = object1 === object2 ? true : false;

    if (!equals) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        equals = false;
      } else {
        for (let key of keys1) {
          if (object1[key] !== undefined && object2[key] !== undefined) {
            if (object1[key].value !== object2[key].value) {
              equals = false;
            } else {
              equals = true;
            }
          }
        }
      }
    }
  }
  return equals;
};
