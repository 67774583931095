import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { CostumerServiceScreenWrapper } from './CostumerServiceScreen.style';

// import { CardGird } from '../../../components/CardGrid/CardGird';
// import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
// import { NavTable } from '../../../components/NavTable/NavTable';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { useSwal } from '../../../hooks/useSwal';
// import { routesCallRegister, routesWide } from '../../../navigation/routes';
// import { nameTables } from '../../../data/dataTable';

const CostumerServiceScreen = () => {
  // const [, setIsBottom] = useState(false);
  // const [checkBoxesActives] = useState([]);
  // const navigate = useNavigate();

  const [, setCheckboxesActives] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const { typeSwal } = useSwal();
  const [search, setSearch] = useState([]);

  useEffect(() => {
    setSearch([]);
  }, [setSearch]);

  const DATA = [
    {
      id: 1,
      name: 'CS 1 ',
      title: 'CS 1 '.toUpperCase(),
      subtitle: '426 Llamadas',
      soundprompt: 'Locu 1',
      company: 'MADRID',
      text: 'Llamadas entrantes 213',
      text2: 'Llamadas salientes 213',
      // icon: icono,
    },
    {
      id: 2,
      name: 'CS 2 ',
      title: 'CS 2'.toUpperCase(),
      subtitle: '426 Llamadas',
      soundprompt: 'Locu 1',
      company: 'BARCELONA',
      text: 'Llamadas entrantes 213',
      text2: 'Llamadas salientes 213',
      // icon: icono,
    },
  ];

  // const handleClick = () => {
  //   navigate(`/${routesWide.cdr}/${routesCallRegister.customer}/create`, {});
  // };

  const dataExport = [];
  const template = [];
  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Locución',
      accessor: 'soundprompt',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
  ];

  const handleDelete = async () => {
    typeSwal('wantDelete');
    // .then(async (result) => {
    // if (result.isConfirmed) {
    //   try {
    //     let response;
    //     if (item.original) {
    // response = await deleteExtensions(item.original);
    // } else {
    // response = await deleteExtensions(item);
    // }
    // if (response.status === 200 || response.status === 201 || response.status === 204) {
    // getExtensions();
    //   typeSwal('delete');
    // } else if (response.status === 205) {
    //   let table = Object.keys(nameTables)
    //     .filter((p) => p.includes(response.data.table))
    //     .reduce((obj, key) => {
    //       obj[key] = nameTables[key];
    //       return obj[key];
    //     }, {});
    // getExtensions();
    // typeSwal('error', table);
    //     }
    //   } catch (err) {
    //     return err;
    //   }
  };

  // const handleOpenFormCreate = () => {
  //   navigate(`/${routesWide.numbering}/${routesCallRegister.customer}`, {});
  // };
  const tableInstance = useRef(null);

  const handleOpenFormEdit = () => {
    // navigate(`/${routesWide.cdr}/${routesCallRegister.customer}/update`, {
    //   state: {
    //     state: item.original ? item.original : item,
    //   },
    // });
    return false;
  };

  return (
    <CostumerServiceScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            // add: {
            //   position: 'left',
            //   function: handleClick,
            // },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            filter: {
              position: 'right',
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
        />
        {/* <NavTable checkboxesActives={checkBoxesActives} /> */}
      </div>
      {/* {false ? (
        <LoaderSpinner />
      ) : ( */}

      <div className="contain__cardGrid">
        {/* <CardGird data={DATA} onClick={handleClick} setIsBottom={setIsBottom} /> */}
        <TableInfo
          format={'list'}
          COLUMNS={COLUMNS}
          DATA={search.length === 0 ? DATA : search}
          deleteFunction={handleDelete}
          updateFunction={handleOpenFormEdit}
          ref={tableInstance}
          onClick={handleOpenFormEdit}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
          isNavTable={false}
          setCheckboxesActives={setCheckboxesActives}
          isCheckbox={true}
          setDataCard={() => {
            false;
          }}
        />
      </div>
      {/* )} */}
    </CostumerServiceScreenWrapper>
  );
};

export default CostumerServiceScreen;
