import React, { useEffect, useState } from 'react';

import { MyDropzone } from '../MyDropzone/MyDropzone';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch';
import { Radio } from '../Radio/Radio';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import ColorPicker from '../ColorPicker/ColorPicker';
import { MySelect } from '../MySelect/MySelect';
import { Input } from '../Input/Input';
import Calendar from '../Calendar/Calendar';
import { monthNames, weekDays } from '../../data/dataPreferences';
import { StepFormWrapper } from './StepForm.style';
import { colors } from '../../theme/colors';
import { Phone } from '../Phone/Phone';
import { RandomCodeGenerator } from '../RandomCodeGenerator/RandomCodeGenerator';
import { InputRandom } from '../InputRandom/InputRandom';
import { MultiFormPages } from '../MultiFormPages/MultiFormPages';
import { DestinationSelect } from '../DestinationSelect/DestinationSelect';
import { CardSlider } from '../CardSlider/CardSlider';
import { TitleInputPair } from '../TitleInputPair/TitleInputPair';
import { ToggleButton } from '../ToggleButton/ToggleButton';
import { array, bool, func, number, object, string } from 'prop-types';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import useApplications from '../../hooks/useApplications';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { ReactSortable } from 'react-sortablejs';
import { IconArrowsSort, IconArrowRight, IconEye, IconArrowsMoveVertical, IconClipboardCopy } from '@tabler/icons-react';

import { tooltipsQueue } from '../../data/dataApplications/tooltipsQueue,js';
import { IconDotsVertical } from '@tabler/icons-react';
import { getArrayFormatted } from '../../utils/AppUtils';
import useNumbering from '../../hooks/useNumbering';
import { useStore } from 'react-redux';
// import { getSipPasswordThunk } from '../../redux/thunks/numberingThunks';
import Swal from 'sweetalert2';
import { useSwal } from '../../hooks/useSwal';
// import PasswordWatcher from '../PasswordWatcher/PasswordWatcher';
// import { useStore } from 'react-redux';

export const StepForm = React.forwardRef(
  (
    {
      dataForm,
      dataTabs,
      // initial,
      // animate,
      // exit,
      isOpen,
      form,
      crudType,
      stepForm,
      layoutId,
      // variants,
      errorInput,
      setErrorInput,
      validationNormalInput,
      setValidationNormalInput,
      inputValue,

      setInputValue,
      allSteps,
      maxwidth,
      arrScreen,
      inputValueUnique,
      setInputValueUnique,
      validateReducer,
      setValidateReducer,
      reducerForm,
      data,
      dataModalButtonToTable,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const [loadingCounter, setLoadingCounter] = useState(0);

    const arrayToProcess = data.steps ? data.steps[0] : data;

    const [toggleIsActive, setToggleIsActive] = useState({
      isRainbow: arrayToProcess?.input[3]?.label === 'Rainbow' ? arrayToProcess?.input[3]?.defaultValues : 0,
      isTeams: arrayToProcess?.input[0].label === 'Teams' ? arrayToProcess?.input[0]?.defaultValues : 0,
    });

    const { listNumberingForm, FormListNumberingReducer } = useNumbering();
    const [editRadio, setEditRadio] = useState('');
    const [voiceMail, setVoiceMail] = useState({});
    const [callWaiting, setCallWaiting] = useState({});
    const [selectedDays, setSelectedDays] = useState([
      {
        name: 'Calendario creado',
        color: '',
        days: [],
      },
    ]);

    const location = useLocation();
    const [checkboxesActives, setCheckboxesActives] = useState([]);

    useEffect(() => {
      setTimeout(() => {
        try {
          if (crudType === 'edit') {
            const hasTabs = Array.isArray(dataTabs?.tabs) ? true : false;

            if (hasTabs) {
              dataTabs?.forEach((tab) => {
                tab.steps[0].input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element !== 'password') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }

                        break;
                      case 'titleInputPair':
                        item.inputs.forEach((input) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: input.defaultValues,
                          }));
                        });
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues.label,
                        }));

                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues?.label,
                        }));

                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        if (!item.defaultValues?.label === undefined) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues || '',
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValue && item.defaultValues[0]?.label !== null ? item.defaultValues[0] : '',
                          }));
                        }
                        // setState2(getFormatted(state2));
                        break;
                      case 'toggle':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'toggleButton':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues,
                        }));
                        break;
                      case 'radio':
                        if (item.ref.element === 'record') setEditRadio(item.defaultValues);
                        else if (item.ref.element === 'voicemailActive') setVoiceMail(item.defaultValues);
                        else if (item.ref.element === 'callWaiting') setCallWaiting(item.defaultValues);
                        else if (item.ref.element === 'preferences') setEditRadio(item.defaultValues);

                        break;
                      default:
                        if (item.ref) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }
                        break;
                    }
                  }
                });
              });
            } else {
              data?.steps.forEach((step) => {
                step.input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element !== 'password') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        }

                        break;
                      case 'titleInputPair':
                        item.inputs.forEach((input) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [input.ref.element]: input.defaultValues,
                          }));
                        });
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        if (item.defaultValues) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues?.label,
                          }));
                        }

                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues?.label,
                        }));

                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        if (!item.defaultValues?.label === undefined) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues || '',
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValue && item.defaultValues[0]?.label !== null ? item.defaultValues[0] : '',
                          }));
                        }
                        // setState2(getFormatted(state2));
                        break;
                      case 'toggle':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'toggleButton':
                        setToggleIsActive({
                          ...toggleIsActive,
                          [item.ref.element]: item.defaultValues,
                        });
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: item.defaultValues,
                        }));
                        break;
                      case 'radio':
                        if (item.ref.element === 'record') setEditRadio(item.defaultValues);
                        else if (item.ref.element === 'voicemailActive') setVoiceMail(item.defaultValues);
                        else if (item.ref.element === 'callWaiting') setCallWaiting(item.defaultValues);
                        else if (item.ref.element === 'preferences') setEditRadio(item.defaultValues);

                        break;
                      default:
                        if (item.ref) {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));

                          if (item.ref.element === 'destinationType') {
                            setInputValue((prevState) => ({
                              ...prevState,
                              destination: '',
                            }));
                            setInputValue((prevState) => ({
                              ...prevState,
                              destination: { value: location.state.destination, label: location.state.destination?.name || location.state.destination?.extension },
                              // item.defaultValues,
                            }));
                            // setValueSelect2(location.state.destination.name || location.state.destination.extension);
                          }
                          if (item.ref.element === 'destinationCloseType') {
                            setInputValue((prevState) => ({
                              ...prevState,
                              destinationClose: '',
                            }));
                            setInputValue((prevState) => ({
                              ...prevState,
                              destinationClose: { value: location.state.destinationClose?.id, label: location.state.destinationClose?.label },
                            }));
                            // setValueSelect2(location.state.destinationClose.name);
                          }
                        }
                        break;
                    }
                  }
                });
              });
            }
            setLoading(false);
          } else if (crudType === 'create') {
            const hasTabs = dataTabs.tabs ? true : false;

            if (hasTabs) {
              dataTabs.tabs.forEach((tab) => {
                tab.input.forEach((item) => {
                  if (!stepForm.inputFull) {
                    switch (item.cardType) {
                      case 'input':
                        if (item.ref.element === 'pin') {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: item.defaultValues,
                          }));
                        } else {
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                        }
                        break;
                      case 'select':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = {};
                        break;
                      case 'select-option':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = {};
                        break;
                      case 'multiselect':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        item.defaultValues = [];
                        break;
                      case 'dropzone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      case 'phone':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      case 'toggle':
                        setInputValue((prevState) => ({
                          ...prevState,
                          [item.ref.element]: '',
                        }));
                        break;
                      default:
                        break;
                    }
                  }
                });
              });
            } else {
              dataTabs.forEach((tab) => {
                tab.steps.forEach((step) => {
                  step.input.forEach((item) => {
                    if (!stepForm.inputFull) {
                      switch (item.cardType) {
                        case 'input':
                          if (item.ref.element === 'pin') {
                            setInputValue((prevState) => ({
                              ...prevState,
                              [item.ref.element]: item.defaultValues,
                            }));
                          } else {
                            setInputValue((prevState) => ({
                              ...prevState,
                              [item.ref.element]: '',
                            }));
                          }
                          break;
                        case 'select':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = {};
                          break;
                        case 'select-option':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = {};
                          break;
                        case 'multiselect':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          item.defaultValues = [];
                          break;
                        case 'dropzone':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        case 'phone':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        case 'toggle':
                          setInputValue((prevState) => ({
                            ...prevState,
                            [item.ref.element]: '',
                          }));
                          break;
                        default:
                          break;
                      }
                    }
                  });
                });
              });
            }
            setLoading(false);
          } else {
            setLoadingCounter(loadingCounter + 1);
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }, 100);
    }, [loadingCounter]);

    const handleToggleSwitchChange = (item, isOn, setIsOn) => {
      setIsOn(isOn === 1 ? 0 : 1);
      setToggleIsActive({
        ...toggleIsActive,
        [item.ref.element]: !isOn,
      });
      const action = { action: 'toggle', value: !isOn === false ? 0 : 1 };

      form(action, item.ref);
    };

    const handleTimeOutChange = (newTimeOut) => {
      const actionTimeOut = { action: 'addTimeOut' };
      const itemTimeOut = { label: newTimeOut.label, name: newTimeOut.label, ref: { form: 'ivr' }, value: newTimeOut.value };
      // newTimeOut.target.value
      const inputValueTimeOut = {
        itemTimeOut,
        value: 'Ivr',
      };
      form(actionTimeOut, { form: 'ivr' }, inputValueTimeOut);

      setInputValue((prevState) => ({
        ...prevState,
        ['timeout']: Number(newTimeOut.value),
      }));

      // setInputValue((prevState) => ({
      //   ...prevState,
      //   timeOut: newTimeOut.value,
      // }));

      console.log('handleTimeOutChange: ' + newTimeOut.value);
    };

    const handlePhoneChange = (item, value, refForm) => {
      if (!item.title && item.ref !== 'timeout') {
        let action = { action: 'addDestination' };
        let ref = {
          form: refForm,
        };
        let inputValue = {
          item,
          value,
        };
        form(action, ref, inputValue);
      } else if (item.ref === 'timeout') {
        handleTimeOutChange(item);
        console.log('handlePhoneChange timeout: ');
      } else {
        let action = { action: 'removeDestination' };
        let ref = {
          form: refForm,
        };
        let inputValue = {
          item: {
            label: item.label,
            value: item.title,
            ref: item.name,
            name: item.name,
          },
          value: item.title,
        };
        form(action, ref, inputValue);
      }
    };

    const handleRandomCodeGenerator = (item, refForm) => {
      const ref = { form: refForm, element: refForm };

      form(item, ref);
    };

    const handleDestinationSelect = (newData, name, item, idPage) => {
      // handleChange();

      if (name) {
        const arg = {
          action: data.ref,
        };
        const ref = {
          element: idPage.ref.element,
          form: idPage.ref.form,
        };

        setValueSelect2(newData.label);

        switch (idPage.ref.element) {
          case 'destinationTypeId':
            form(
              {
                action: 'input',
                value: Number(newData.value),
              },
              {
                form: idPage.ref.form,
                element: 'destinationId',
              }
            );
            form(
              {
                action: 'select-option',
              },
              {
                element: 'destinationTypeId',
                form: idPage.ref.form,
              },
              { value: Number(newData.value), label: newData.name }
            );
            reducerForm.tabs[0].steps[3].input[2].defaultValues = Number(newData.value);
            // location.state.destinationId = Number(newData.value);

            // form({ action: 'select-option' }, { element: 'DestinationType', form: idPage.ref.form }, { value: Number(newData.value), label: newData.name });
            // form({ action: 'select-option' }, { element: 'destinationId', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
            form({ action: 'select-option' }, { element: 'Destination', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
            break;
          case 'destinationCloseType':
            // setValueSelect2(newData.label);
            form(
              {
                action: 'select-option',
              },
              {
                form: idPage.ref.form,
                element: 'destinationClose',
              },
              {
                value: Number(newData.value),
                label: newData.label,
              }
            );

            form(
              {
                action: 'input',
                value: Number(newData.value),
              },
              {
                element: 'destinationCloseTypeId',
                form: idPage.ref.form,
              }
            );

            form(
              {
                action: 'select-option',
              },
              {
                element: 'destinationCloseType',
                form: idPage.ref.form,
              },
              {
                value: Number(newData.value),
                label: newData.name,
              }
            );
            // reducerForm.tabs[0].steps[3].input[2].defaultValues = Number(newData.value);
            // location.state.destinationId = Number(newData.value);

            // form({ action: 'select-option' }, { element: 'DestinationType', form: idPage.ref.form }, { value: Number(newData.value), label: newData.name });
            // form({ action: 'select-option' }, { element: 'destinationId', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
            // form({ action: 'select-option' }, { element: 'destinationCloseType', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });

            break;
          case 'destinationType':
            // setValueSelect2(newData.label);
            form(
              {
                action: 'select-option',
              },
              {
                form: idPage.ref.form,
                element: 'destination',
              },
              {
                value: Number(newData.value),
                label: newData.label,
              }
            );
            form(
              {
                action: 'select-option',
              },
              {
                element: 'destinationTypeId',
                form: idPage.ref.form,
              },
              { value: Number(newData.value), label: newData.name }
            );
            // reducerForm.tabs[0].steps[3].input[2].defaultValues = Number(newData.value);
            // location.state.destinationId = Number(newData.value);

            // form({ action: 'select-option' }, { element: 'DestinationType', form: idPage.ref.form }, { value: Number(newData.value), label: newData.name });
            // form({ action: 'select-option' }, { element: 'destinationId', form: idPage.ref.form }, { value: Number(newData.value), label: newData.label });
            form({ action: 'select-option' }, { element: 'destinationType', form: idPage.ref.form }, { value: idPage.idRef, label: name });

            break;
          default:
            form(arg, ref);
            break;
        }

        // setValueSelect2(Number(newData.value));
      } else {
        const arg = {
          action: idPage.cardType,
        };
        const ref = {
          element: idPage.ref.element,
          form: idPage.ref.form,
        };

        form(arg, ref, data);
      }

      // handleOnChange();
    };

    const [isActiveToggleButton, setIsActiveToggleButton] = useState(false);

    const handleToggleButtonChange = (item) => {
      setIsActiveToggleButton(!isActiveToggleButton);
      const action = {
        action: item.cardType,
      };
      const ref = {
        form: item.ref.form,
        element: item.ref.element,
      };

      form(action, ref, !isActiveToggleButton);
    };

    const [isAudioPlayerOpen, setIsAudioPlayerOpen] = useState(false);

    const handleOnChange = () => {
      console.log('data changed: ');
    };

    const handleSelectedTracks = (value) => {
      const newSelectedTracks = value.length > 0 ? value : checkboxesActives;
      const newLength = newSelectedTracks.length;

      if (newLength > 0) {
        console.log('selectedTracks updated to: ' + newLength.toString() + ' items, new value: ' + newSelectedTracks[newLength - 1].description);
        setCheckboxesActives(newSelectedTracks);
        setInputValue({ checkboxesActives, value });
        return newSelectedTracks;
      }
    };

    const [objectSongWaiting, setObjectSongWaiting] = useState({
      arraySongWaiting: [],
      title: '',
    });

    const isSongWaiting = location.pathname?.includes('songWaiting');
    const { songWaitingLink, songWaitingForm, queuesForm } = useApplications();

    const setAudioPlayer = (status) => {
      event.preventDefault(event);
      if (status !== undefined && isSongWaiting) {
        // console.log('IsAudioPlayerOpen status: ' + isAudioPlayerOpen);
        setIsAudioPlayerOpen(!isAudioPlayerOpen);
        const newSongWaiting = songWaitingLink.filter((object) => (object.mohId !== undefined ? object.mohId === songWaitingForm.id : ''));
        setObjectSongWaiting({
          arraySongWaiting: newSongWaiting,
          title: status.original?.filename,
        });
        console.log('IsAudioPlayerOpen status: ' + isAudioPlayerOpen);
      }
    };
    const isUpdate = location.pathname.includes('update');

    const [valueSelect2, setValueSelect2] = useState(location.state?.destinationClose && location.state.destinationClose[0] ? location.state.destinationClose[0]?.label : '');
    const [isExtEdit, setIsExtEdit] = useState(false);
    const [state2, setState2] = useState(queuesForm?.extensions ? queuesForm.extensions : location.state?.extensions);

    const handleIsExtEdit = () => {
      setIsExtEdit(!isExtEdit);
    };
    // const { getState } = useStore();
    // const { isRowDestEditable } = getState().system.isRowDestEditable;

    const handleEndSorting = (event) => {
      const { oldIndex, newIndex } = event;

      if (oldIndex !== newIndex) {
        const newArray = arrayMoveImmutable(queuesForm?.extensions ? queuesForm.extensions : location.state?.extensions, oldIndex - 1, newIndex - 1);

        setState2(newArray);

        setInputValue((prevState) => ({
          ...prevState,
          extensions: newArray,
        }));

        form(
          {
            action: 'multiselect',
            value: newArray,
          },
          {
            form: 'queue',
            element: 'extensions',
          }
        );
        // setState2(newArray);
      }
    };

    const handleClassInput = () => {
      let newClass = 'stepForm__contain-typeInput';
      switch (arrScreen[0]) {
        case 'Cola':
          newClass = data.id === 3 ? 'queue__contain-typeInput' : newClass;
          break;
        case 'Ivr':
          newClass = 'ivr__contain-typeInput';
          break;
        case 'Música en espera':
          newClass = 'soundw__contain-typeInput';
          break;
        case 'Numeración externa':
          newClass = 'numb__contain-typeInput';
          break;
        default:
          newClass = 'stepForm__contain-typeInput';
          break;
      }
      // newClass = isRowDestEditable ? 'stepForm__contain-typeInput__hidden' : false;
      return newClass;
    };

    // const handleDestinationLabel = (item) => {
    //   let newClass = 'stepForm__contain-typeInput';

    //   switch (item?.ref.element) {
    //     case 'destinationTypeId':
    //       newClass = location.state.DestinationData.name;
    //       break;
    //     case 'promptDestinationTypeId':
    //       newClass = location.state.PromptDestinationData.name || location.state.PromptDestinationData.extension;
    //       break;
    //     case 'falloverDestinationTypeId':
    //       newClass = location.state.FalloverDestinationData.name || location.state.FalloverDestinationData.extension;
    //       break;
    //     case 'destinationClose':
    //       newClass = location.state.destinationClose?.label || location.state.destinationClose?.extension;
    //       break;
    //     case 'destinationCloseType':
    //       newClass = location.state.destinationClose?.label || location.state.destinationClose?.extension;
    //       break;
    //     case 'destinationType':
    //       newClass = location.state.destination?.name || location.state.destinationType?.extension;
    //       break;
    //     case 'destination':
    //       newClass = location.state.destination?.label || location.state.destination?.name || location.state.destination?.extension;
    //       break;
    //     // case 'soundPromptClosedestinationCloseId':
    //     //   newClass = location.state.destinationClose.label;
    //     //   break;
    //     default:
    //       newClass = valueSelect2;
    //       break;
    //   }
    //   return newClass;
    // };
    const handleAddRule = () => {
      const newCalendar = FormListNumberingReducer.calendar;
      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'calendars',
        },
        {
          value: newCalendar?.value,
          label: newCalendar?.label,
        }
      );

      const newSchedule = FormListNumberingReducer.schedule;
      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'schedules',
        },
        {
          value: newSchedule?.value,
          label: newSchedule?.label,
        }
      );

      const newSound = FormListNumberingReducer.soundPrompt;
      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'soundPrompts',
        },
        {
          value: newSound?.value,
          label: newSound?.label,
        }
      );

      const newDestType = FormListNumberingReducer.destinationType;
      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'destinationsType',
        },
        {
          value: newDestType?.value,
          label: newDestType?.label,
        }
      );

      const newDest = FormListNumberingReducer.destination;
      listNumberingForm(
        {
          action: 'multiselect',
        },
        {
          form: 'numberingList',
          element: 'destinations',
        },
        {
          value: newDest?.value,
          label: newDest?.label,
        }
      );

      const newIsDefault = FormListNumberingReducer.isDefault;
      listNumberingForm(
        {
          action: 'multiselect',
          value: newIsDefault ? 1 : 0,
        },
        {
          form: 'numberingList',
          element: 'isDefaults',
        }
        // {
        // label: newIsDefault?.label,
        // }
      );
    };

    // const [, setItemIsDisabled] = useState(false);
    const { getState } = useStore();
    const { typeExtension } = getState()['formExtensionsReducer'];

    const handleItemIsDisabled = (item) => {
      const element = item.ref?.element;
      let newStatus = false;
      switch (element) {
        // case 'SIPExtension':
        //   newStatus = toggleIsActive?.isSIPExtension === 1 || toggleIsActive?.isSIPExtension === true ? false : true;
        //   break;

        // case 'identification':
        //   newStatus = true;
        //   break;
        case 'allow':
          newStatus = typeExtension?.label === 'SIP' || typeExtension?.label === 'Todos' ? false : true;
          //  || location.state?.typeExtension === 'SIP' || dataForm.input[1].defaultValues.value === 'SIP' || inputValue.typeExtension === 'SIP' ? false : true;
          //  || dataForm.input[1].defaultValues.value === 'SIP' || inputValue.typeExtension === 'SIP' ? false : true;
          // dataForm.input[3].defaultValues?.label !== 'SIP' && dataForm.input[1].defaultValues.value !== 'SIP' && dataForm.input[1].defaultValues.value !== 'SIP' ? true : false;

          break;
        case 'destinationType':
          newStatus = reducerForm?.isSIPExtension === 0 ? true : false;
          break;
        case 'callForward':
          newStatus = toggleIsActive.isCallForward === 0 || toggleIsActive.isCallForward === false || reducerForm.tabs[2].steps[1].input[0].defaultValues === 0 ? true : false;
          break;
        case 'notAnswer':
          newStatus = toggleIsActive.isNotAnswer === 1 || toggleIsActive.isNotAnswer === true ? false : true;
          break;
        case 'teamsTroncalId':
          newStatus = toggleIsActive.isTeams === 1 || toggleIsActive.isTeams === true ? false : true;
          break;
        case 'rainbowTroncalId':
          newStatus = toggleIsActive.isRainbow === 1 || toggleIsActive.isRainbow === true ? false : true;
          break;
        default:
          newStatus = false;
          break;
      }
      // setItemIsDisabled(newStatus);
      return newStatus;
    };

    // const handleAddExtension = () => {};

    // const handleButtonIsDisabled = (item) => {
    //   const element = item.ref?.element;
    //   let newStatus = true;

    //   switch (element) {
    //     case 'SIPExtension':
    //       newStatus = location.state?.isSIPExtension ? false : true;
    //       break;
    //     case 'identification':
    //       newStatus = true;
    //       break;
    //     case 'allow':
    //       newStatus = location.state?.typeExtension !== 'extension' ? true : false;
    //       break;
    //     case 'destinationType':
    //       newStatus = reducerForm?.isSIPExtension === 0 ? true : false;
    //       break;
    //     default:
    //       newStatus = true;
    //       break;
    //   }
    //   return newStatus;
    // };

    const { typeSwal } = useSwal();

    const handlePassword = () => {
      // async () => {
      // const sipPassword = await getSipPassword(row.original);

      if (location.state.AstSipPeer?.pbxpassword) {
        typeSwal('password', location.state.AstSipPeer?.pbxpassword || 'No tiene');
      } else {
        typeSwal('password', location.state.AstSipPeer?.pbxpassword || 'No tiene');
      }
    };

    const handleCopyPSW = () => {
      // async () => {
      // const sipPassword = await getSipPasswordThunk(location.state.AstSipPeer);

      Swal.fire({
        html: '¡Contraseña copiada al portapapeles!',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        timer: 2000,
      });

      if (navigator.clipboard && location.state.AstSipPeer?.pbxpassword) {
        navigator.clipboard.writeText(location.state.AstSipPeer?.pbxpassword);
      }
      //  else {
      //   navigator.clipboard.writeText(sipPassword.password);
      // }
    };

    return (
      //  initial={initial} variants={variants} animate={animate} exit={exit}
      <StepFormWrapper layoutId={layoutId} ref={ref} data={dataForm} maxwidth={maxwidth}>
        {loading && crudType === 'edit' ? (
          <LoaderSpinner />
        ) : !isUpdate && arrScreen[0] !== 'Cola' ? (
          // && arrScreen[0] !== 'Preferencias'
          <div className="stepForm">
            <div className="stepForm__header"></div>
          </div>
        ) : null}
        {isOpen ? (
          <div className={handleClassInput()}>
            {dataForm.input.map((item, index) => {
              const result =
                item.cardType === 'select' || item.cardType === 'select-option' ? (
                  <div className={item.ref.element === 'calendar' ? 'editRow' : 'basicRow'}>
                    <div key={item.id + item.ref.element} className={item.ref.element === 'calendar' ? 'contain__select__calendar' : 'contain__select'}>
                      {item.required && (
                        <span className="textRequired" id={item.ref.element}>
                          {item.required.text}
                        </span>
                      )}
                      <MySelect
                        item={item}
                        placeholder={item.placeholder}
                        options={item.options}
                        inputRef={ref}
                        // defaultValue={Array.isArray(item.defaultValues) ? item.defaultValues : []}
                        defaultValue={[item.defaultValues]}
                        isMulti={false}
                        isSearchable={true}
                        form={form}
                        dataForm={dataForm}
                        setInputValue={setInputValue}
                        setErrorInput={setErrorInput}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        allSteps={allSteps}
                        // onChange={handleOnChange}
                        reducerForm={reducerForm}
                        firstData={data}
                        setValidateReducer={setValidateReducer}
                        isDisabled={handleItemIsDisabled(item)}
                        // setIsDisabled={handleIsDisabled}
                      />
                      {arrScreen[0] === 'Cola' ? (
                        <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                      ) : null}
                      {/* className={itemIsDisabled ? 'disabled' : 'nodisabled'} */}
                      {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                    </div>
                    {/* {handleButtonIsDisabled(item) === true ? null : <IconPlus className="icon__add__destination" />} */}
                  </div>
                ) : item.cardType === 'multiselect' ? (
                  <div key={item.id + item.ref.element} className="contain__select">
                    {item.required && <span className="textRequired">{item.required.text}</span>}

                    <MySelect
                      item={item}
                      placeholder={item.placeholder}
                      options={item.options}
                      inputRef={ref}
                      defaultValue={[item.defaultValues] || ['']}
                      isMulti={true}
                      isSearchable={true}
                      form={form}
                      dataForm={dataForm}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      onChange={handleOnChange}
                      reducerForm={reducerForm}
                      firstData={data}
                      setValidateReducer={setValidateReducer}
                      value={[item.defaultValues]}
                      isDisabled={handleItemIsDisabled(item)}
                      // setIsDisabled={}
                    />

                    {item.defaultValues && item.ref.element === 'extensions' && item.ref.form !== 'diffusionList' ? (
                      <div className="sortableDrag__container" onClick={handleIsExtEdit}>
                        <ReactSortable
                          dragClass="sortableDrag"
                          ghostClass="ghost"
                          swap={true}
                          swapClass="highlighted"
                          animation="200"
                          easing="ease-out"
                          className="sorted__container"
                          list={state2}
                          delayOnTouchStart={true}
                          delay={2}
                          removeCloneOnHide={true}
                          setList={setState2}
                          chosenClass="queue__selected"
                          onEnd={handleEndSorting}>
                          <div className="sorted__header">
                            <span className="sorted__title">Arrastrar para ordenar</span>
                            <IconArrowsSort className="sorted__header__icon" id={'icon__sort'} />
                          </div>
                          {getArrayFormatted(item.defaultValues, [], 'onlyFormat')?.map((itemList, index) =>
                            itemList.id ? (
                              <div key={index} className="sorted__row">
                                <span className="sorted__pos"> {index + 1}</span>
                                <div data-id={itemList.id + '-' + index} className="sorted__item">
                                  <IconDotsVertical className="item__icon" />
                                  {itemList.name || itemList.label || item.options[index]?.label}
                                  <IconArrowsMoveVertical className="item__icon" />
                                </div>
                              </div>
                            ) : null
                          )}
                        </ReactSortable>
                      </div>
                    ) : null}
                    {isAudioPlayerOpen ? (
                      <AudioPlayer
                        title={inputValue.name}
                        allTracks={arrScreen}
                        selectedTracks={checkboxesActives.length > 0 ? checkboxesActives : objectSongWaiting.arraySongWaiting}
                        isOpen={setIsAudioPlayerOpen}
                        isEditable={true}
                        setCheckboxesActives={handleSelectedTracks}
                        checkboxesActives={objectSongWaiting.arraySongWaiting.map((itemLocucion) => itemLocucion.Soundprompt)}
                        setAudioPlayer={setAudioPlayer}
                      />
                    ) : null}
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                  </div>
                ) : item.cardType === 'input' ? (
                  crudType === 'edit' && item.ref.element === 'password' ? null : (
                    <div className="contain__input" key={index}>
                      {item.required ? (
                        <span className="textRequired" id={item.ref.element + '__' + index}>
                          {item.required.text}
                        </span>
                      ) : (
                        <span className="textRequired" id={item.ref.element + '__' + index}></span>
                      )}
                      <Input
                        onChange={handleTimeOutChange}
                        item={item}
                        form={form}
                        inputValue={inputValue}
                        setErrorInput={setErrorInput}
                        setInputValue={setInputValue}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        arrScreen={arrScreen}
                        inputValueUnique={inputValueUnique}
                        setInputValueUnique={setInputValueUnique}
                        isDisabled={handleItemIsDisabled(item)}
                        reducerForm={reducerForm}
                        // setIsDisabled={}
                      />
                      {arrScreen[0] === 'Cola' ? (
                        <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                      ) : null}
                      {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                      {inputValueUnique.some((obj) => obj.element === item.ref.element) ? <span className="error"> {item.required.errorMessageUnique}</span> : null}
                    </div>
                  )
                ) : item.cardType === 'inputRandom' ? (
                  crudType === 'edit' && item.ref.element === 'password' ? null : (
                    <div className="contain__input" key={index}>
                      {item.required.validation ? (
                        <span className="textRequired" id={item.ref.element + '__' + index}>
                          {item.required.text}
                        </span>
                      ) : (
                        <span className="textRequired" id={item.ref.element + '__' + index} />
                      )}
                      <InputRandom
                        onChange={handleTimeOutChange}
                        item={item}
                        form={form}
                        // arrScreen={arrScreen}
                        inputValue={inputValue}
                        setErrorInput={setErrorInput}
                        setInputValue={setInputValue}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        reducerForm={reducerForm}
                      />
                      {arrScreen[0] === 'Cola' ? (
                        <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                      ) : null}
                      {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : null}
                    </div>
                  )
                ) : item.cardType === 'dropzone' ? (
                  <div key={index} className="contain__form-dropzone">
                    <div className="dropzone">
                      {item.required.validation && <span className="textRequired">{item.required.text}</span>}
                      <MyDropzone item={item} setInputValue={setInputValue} form={form} inputValue={inputValue} accept={item.acceptFile} />
                    </div>
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : <span className="error"></span>}
                  </div>
                ) : item.cardType === 'toggle' ? (
                  <div key={index} className={arrScreen[0] === 'Cola' && data.id === 3 ? 'queue__toggle' : 'stepForm__toggle'}>
                    {item.required.text && (
                      <span className="textRequired" id={item.ref.element}>
                        {item.required.text}
                      </span>
                    )}

                    <div className="toggle__item">
                      <ToggleSwitch reducerForm={reducerForm} toggleSwitch={handleToggleSwitchChange} isActive={toggleIsActive} data={item} color={colors.green} />
                    </div>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                    ) : null}
                  </div>
                ) : item.cardType === 'radio' ? (
                  item.ref.element === 'voicemailActive' ? (
                    <div key={index} className="stepForm__radio">
                      <span className="textRequired" id={item.ref.element}>
                        {item.required.text}
                      </span>

                      {item.required.validation && item.radioType === 'multi' && <span id={item.ref.element}>{item.required.text}</span>}
                      <div className="contain__items-radio">
                        <Radio
                          form={form}
                          item={item}
                          defaultValueEdit={editRadio}
                          voiceMail={voiceMail}
                          dataForm={dataForm}
                          inputRef={ref}
                          setInputValue={setInputValue}
                          setErrorInput={setErrorInput}
                          setValidationNormalInput={setValidationNormalInput}
                          validationNormalInput={validationNormalInput}
                          allSteps={allSteps}
                        />{' '}
                      </div>
                    </div>
                  ) : (
                    <div key={index} className="stepForm__radio">
                      {item.required.text && (
                        <span className="textRequired" id={item.ref.element}>
                          {item.required.text}
                        </span>
                      )}

                      {item.required.validation && item.radioType === 'multi' && <span id={item.ref.element}> {item.required.text}</span>}
                      <div className="contain__items-radio">
                        <Radio
                          item={item}
                          form={form}
                          defaultValueEdit={editRadio}
                          voiceMail={callWaiting}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setValidationNormalInput={setValidationNormalInput}
                          validationNormalInput={validationNormalInput}
                          setErrorInput={setErrorInput}
                          arrScreen={arrScreen}
                          inputValueUnique={inputValueUnique}
                          setInputValueUnique={setInputValueUnique}
                        />
                      </div>
                    </div>
                  )
                ) : item.cardType === 'schedule' ? (
                  <div key={index} className="schedule_container">
                    {item.required.text && <span className="textRequired">{item.required.text}</span>}

                    <ScheduleForm item={item} form={form} crudType={crudType} />
                  </div>
                ) : item.cardType === 'calendar' ? (
                  <div key={index} className="calendar_container">
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <div className="calendar_wrapper">
                      <Calendar selectedDays={selectedDays} setSelectedDays={setSelectedDays} monthNames={monthNames} weekDays={weekDays} monthsDisplayed={2} data={item} crudType={crudType} />
                    </div>
                  </div>
                ) : item.cardType === 'color' ? (
                  <div key={index} className="color">
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <ColorPicker crudType={crudType} data={item} />
                  </div>
                ) : item.cardType === 'phone' ? (
                  <div key={index} className={'containerPhone'}>
                    {item.required.validation ? <span className="textRequired">{item.required.text}</span> : <span className="textRequired"> &nbsp; </span>}
                    <Phone onChange={handlePhoneChange} refForm={item.ref.form} crudType={crudType} item={item} />
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error"> {item.required.errorMessage}</span> : ''}
                  </div>
                ) : item.cardType === 'generateKey' ? (
                  <div key={index} className={'generateKey'}>
                    <RandomCodeGenerator refForm={item.ref.form} onChange={handleRandomCodeGenerator} />
                  </div>
                ) : item.cardType === 'multiFormPages' ? (
                  <div key={index} className={'container_multiFormPagesWrapper'}>
                    <MultiFormPages
                      data={item}
                      form={form}
                      dataForm={dataForm}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      validateReducer={validateReducer}
                      //
                      setValidateReducer={setValidateReducer}
                      reducerForm={reducerForm}
                      firstData={data}
                    />
                  </div>
                ) : item.cardType === 'destinations' ? (
                  <div key={index} className="contain__select">
                    {item.required && (
                      <span className="textRequired" id={item.ref.element}>
                        {item.required.text}
                      </span>
                    )}
                    <div className="contain__select__destination">
                      <DestinationSelect
                        onChange={handleDestinationSelect}
                        item={item}
                        placeholder={item.placeholder}
                        options={item.options}
                        inputRef={ref}
                        defaultValue={item.defaultValues}
                        //  === '' ? {} : item.defaultValues[0] !== undefined ? item.defaultValues[0] : item.defaultValues}
                        isMulti
                        isSearchable={true}
                        form={form}
                        dataForm={dataForm}
                        setInputValue={setInputValue}
                        setErrorInput={setErrorInput}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        allSteps={allSteps}
                        setValueSelect2={setValueSelect2}
                        valueSelect2={Number(valueSelect2)}
                      />

                      <span className="basic__destination">
                        {/* {item.ref.element === 'destinationCloseType' ? location.state.destinationClose[0].label : item.ref.element === 'destinationType' ? location.state.destination?.label : ''} */}
                        {valueSelect2}
                      </span>
                      {arrScreen[0] === 'Numeración externa' && item.ref.element === 'destinationType' ? (
                        <IconArrowRight className={'icon__add__destination'} onClick={handleAddRule} disabled={handleItemIsDisabled(item)} />
                      ) : null}
                    </div>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                    ) : null}
                    {errorInput && validationNormalInput.includes(item.ref.element) ? <span className="error">{item.required.errorMessage}</span> : null}
                  </div>
                ) : item.cardType === 'cardSlider' ? (
                  <div key={index}>
                    <span className="textRequired" id={item.ref.element}>
                      {item.label}
                    </span>
                    <CardSlider
                      item={item}
                      setInputValue={setInputValue}
                      setErrorInput={setErrorInput}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      allSteps={allSteps}
                      form={form}
                      dataModalButtonToTable={dataModalButtonToTable}
                      dataForm={dataForm}
                    />
                  </div>
                ) : item.cardType === 'titleInputPair' ? (
                  <div key={index} className="contain__titleInputPair">
                    <TitleInputPair
                      item={item}
                      form={form}
                      inputValue={inputValue}
                      setErrorInput={setErrorInput}
                      setInputValue={setInputValue}
                      setValidationNormalInput={setValidationNormalInput}
                      validationNormalInput={validationNormalInput}
                      arrScreen={arrScreen}
                      inputValueUnique={inputValueUnique}
                      setInputValueUnique={setInputValueUnique}
                      errorInput={errorInput}
                    />
                  </div>
                ) : item.cardType === 'toggleButton' ? (
                  <div className="contain__toggleButton" key={index}>
                    <span className="textRequired" id={item.ref.element}>
                      {item.label}
                    </span>

                    <div className="contain__toggleButton__item" onClick={() => handleToggleButtonChange(item)}>
                      <ToggleButton
                        textLeft={item.textLeft}
                        textRight={item.textRight}
                        isActive={isActiveToggleButton ? true : false}
                        setIsActive={setIsActiveToggleButton ? true : false}
                        twoButtonActive="true"
                        defaultValue={item.defaultValues}
                      />
                    </div>
                    {arrScreen[0] === 'Cola' ? (
                      <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />
                    ) : null}
                  </div>
                ) : item.cardType === 'witheSpace' ? (
                  <div key={index}></div>
                ) : null;

              {
                /* <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} />; */
              }
              return result;
            })}
            {arrScreen[0] === 'Dispositivo' && crudType === 'edit' ? (
              <div className="psw__icons__container">
                Contraseña:
                <IconEye onClick={handlePassword} />
                <IconClipboardCopy onClick={handleCopyPSW} />
              </div>
            ) : null}
            {/* <ReactTooltip className="tooltip" anchorSelect={'#' + item.ref.element} place="bottom" content={tooltipsQueue.tooltips[0][arrScreen[0]][item.ref.element]} delayShow={500} /> */}
          </div>
        ) : null}
      </StepFormWrapper>
    );
  }
);

StepForm.displayName = 'StepForm';

StepForm.propTypes = {
  dataForm: object,
  initial: string,
  animate: string,
  exit: string,
  isOpen: bool,
  form: func,
  crudType: string,
  stepForm: object,
  layoutId: number,
  variants: object,
  errorInput: bool,
  setErrorInput: func,
  validationNormalInput: array,
  setValidationNormalInput: func,
  inputValue: object,
  setInputValue: func,
  allSteps: array,
  maxwidth: string,
  arrScreen: array,
  inputValueUnique: array,
  setInputValueUnique: func,
  //
  validateReducer: array,
  setValidateReducer: func,
  reducerForm: object,
  data: object,
  dataTabs: array,
  children: object,
  dataModalButtonToTable: object,
};
