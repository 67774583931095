import styled from 'styled-components';
import { colors } from './theme/colors.js';

export const AppScreenWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .customConfirmButtonSwal {
    border: none;
    color: white;
    background-color: #0c3fc4;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-usersView {
    border: 1px solid ${colors.primaryColor};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    stroke: ${colors.primaryColor};
  }

  .icon__view {
    opacity: 0.7;
    margin-left: 25px;
    color: black;

    &:hover {
      opacity: 1;
    }
    &:focus {
      border: none;
    }
  }

  audio {
    width: 100px;
    height: 15px;
  }

  audio::-webkit-controls {
    display: none;
  }

  .numbering {
    .form__container {
      /* margin-top: 120px; */
      height: 100%;
    }

    /* .form__body { */
    /* height: 100%; */
    /* position: relative;
    top: 120px; */
    /* } */
    .numb_contain__table {
      height: 100%;
      /* position: relative;
    top: 120px; */
    }
  }

  .input-disabled {
    border: 1px solid grey;
    border-radius: 5px;
    width: 100%;
    max-width: 275px;
    height: 38px;
    padding: 0 0 0 10px;
    margin: 0;
    box-sizing: border-box;
    background-color: lightgrey;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &:focus {
      padding-left: 10px;
    }
  }
  audio::-webkit-slider-thumb {
    width: 100px;
    height: 10px;
    background-color: red;
  }

  audio::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  audio::-webkit-slider-thumb:hover {
    background-color: #fff;
  }

  .header__container {
    box-sizing: border-box;
    background-color: ${colors.primaryColor};
    width: -webkit-fill-available;
    height: 50px;
    position: fixed;
    top: 0;
    left: 10px;
    ${'' /* left: ${(p) => (p.isMenuOpen ? '228px' : '70px')}; */}
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 149;

    img {
      height: 20px;
    }
  }

  .form__container {
    width: 80%;
    height: 100%;
    /* position: fixed;
  : 120px; */
    margin: auto;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    border-bottom: rgb(215, 215, 215) 1px solid;

    &:hover {
      color: ${colors.blue};
      background-color: white;
    }

    td {
      cursor: auto;

      &:first-child {
        width: auto;
      }

      &:last-child {
        width: 100%;
      }
    }
  }

  .tooltip {
    background: ${colors.primaryColor};
    border-radius: 34px;
    border: none;
    height: auto;
  }

  .numb_contain__table {
    /* width: 60%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    position: relative;
    height: 50%;

    .nav__buttons__container {
      width: 100%;
      height: 50px;
    }

    .container__cards {
      top: 120px;
    }

    .left {
      width: 100%;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin: auto;
      gap: 20px;
      color: white;
      border-radius: 10px 10px 0px 0;

      .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        margin-left: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      }
    }

    table thead {
      background-color: #22262e;
      color: white;
      position: relative;
      margin-left: 10px;
      z-index: 2;

      td {
        min-width: 40px;
      }

      tr th {
        min-width: 150px;
      }

      tr th:nth-child(2) {
        border-left: none;
        font-weight: 700;
        cursor: pointer;
        min-width: 40px;
      }
    }

    td:nth-child(3) {
      color: #0c3fc4;
      font-weight: bold;
    }

    .navtable__container {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      margin: auto;
      background-color: #22262e;
      color: white;
      display: flex;
      justify-content: flex-end;
      border-radius: 10px 10px 0px 0;
      border-bottom: 1px solid lightgrey;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    .tooltip-column {
      color: white;
    }
    .numb__table {
      width: 60%;
    }

    .container .left {
      left: 285px;
      top: 60px;
    }

    .row {
      &:hover {
        background-color: ${colors.primaryColorLight};
        color: white;
      }
    }

    .section__name {
      display: flex;
      align-items: center;
      height: 30px;
      color: #0c3fc4;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      z-index: 9;
    }
  }

  .numb__table {
    width: 60%;
    position: relative;
    top: -50px;
  }

  .tooltip-column {
    min-width: 120px;
    color: black;
    width: max-content;
  }

  .extensions__format {
    width: 50px;
    height: 20px;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    color: white;
    background-color: ${colors.primaryColor};
    border: 1px solid rgb(12, 63, 196);
    border-radius: 25px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .settings-screen {
    /* box-sizing: border-box;
  height: 100%;
  position: relative;
  left: 58px;
  padding: 10px;
  width: 100%;*/
    top: 84px;

    .pageScreen {
      position: relative;
      top: 70px;
    }

    .contain__button-save {
      opacity: 1;
      height: 35px;
      position: fixed;
      box-sizing: border-box;
      display: flex;
      justify-content: end;
      top: 15px;
      right: 10px;
      z-index: 718;

      .cancel_button {
        color: lightgrey;
        /* background-color: #c142269a; */
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;

        &:hover {
          color: white;
          background-color: #c14326;
        }
      }
      .save_button {
        color: lightgrey;
        background-color: #29903b;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;

        &:hover {
          color: white;
          background-color: #2faa43;
        }
      }

      .save_button,
      .next_step {
        color: lightgrey;
        background-color: #29903b;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;

        &:hover {
          color: white;
          background-color: #2faa43;
        }
      }
    }
  }

  .left {
    /* padding-left: 15px; */
    ${'' /* width: 100%; */}
    display:flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
  }

  .form__body__queue {
    display: flex;
    flex-direction: column;
    margin: auto;
    /* position: relative;
  top: 95px; */
    overflow: scroll;
    align-items: center;

    width: 80%;
    border: 2px solid #0c3fc4;
    border-top: 0;
    border-radius: 0px 10px 10px 10px;

    .form__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      /* min-width: 75%; */
      text-align: left;
      color: white;
      font-size: 14px;
      font-weight: bold;
      top: 50px;

      .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #0c3fc4;
      }

      .title__Form_title {
        display: flex;
        align-items: center;
        color: #0c3fc4;
        font-size: 16px;
        border-radius: 15px;
      }
    }
  }

  .form__body {
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    border-radius: 15px;
    /* margin-top: 100px; */
    /* position: relative;
  top: 120px; */

    .container__cards {
      width: 100%;
      display: flex;
      flex-direction: column;

      .contain__button-save {
        opacity: 1;
        height: 35px;
        ${'' /* position: fixed; */}
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 20px;
        right: 10px;
        z-index: 718;

        .cancel_button {
          color: lightgrey;
          /* background-color: #c142269a; */
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #c14326;
          }
        }
        .save_button {
          color: lightgrey;
          background-color: #29903b;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }

        .save_button,
        .next_step {
          color: lightgrey;
          background-color: #29903b;
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }

    .form__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      text-align: left;
      color: #0c3fc4;
      font-size: 14px;
      font-weight: bold;
      top: 50px;
      border-top: 1px solid lightgrey;
      margin: auto;

      .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #0c3fc4;
      }

      .title__Form_title {
        display: flex;
        align-items: center;
        border-radius: 15px;
      }
    }
  }

  .form__body__prefs {
    width: 80%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 50px;
    align-items: center;
    /* border: 2px solid #0c3fc4; */
    /* border-top: 0; */
    padding-bottom: 20px;
    /* border-radius: 0 0 10px 10px; */

    .form__title {
      display: flex;
      /* min-width: 75%; */
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: 55px;
      text-align: left;
      color: white;
      font-weight: bold;
      position: fixed;
      top: 0px;
      z-index: 550;

      .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: white;
      }

      .title__Form_title {
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 15px;
      }
    }
  }

  .modal__container {
    width: 100%;
    height: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 60px;
    background-color: #22262e;
    opacity: 0.9;
    display: flex;
    justify-content: center;

    .modal__numberList__body {
      width: 60%;
      margin: auto;
      border-top: 2px solid #6a6a6b;
      border-right: 2px solid #6a6a6b;
      border-radius: 15px;
      background-color: white;

      .form__title {
        background-color: #22262e;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        text-align: left;
        color: white;
        font-weight: bold;
        height: 40px;

        .icon__Form_title {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          margin-right: 10px;
          color: white;
        }

        .title__Form_title {
          display: flex;
          align-items: center;
        }
      }

      .numb__contain-typeInput {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }

      .contain__button-save {
        opacity: 1;
        height: 40px;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: -280px;
        right: 0;
        z-index: 718;

        button {
          height: 30px;
          width: 90px;
          color: white;
          border-radius: 10px;
        }

        .cancel_button {
          color: lightgrey;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #c14326;
          }
        }
        .save_button {
          color: lightgrey;
          background-color: #29903b;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }

        .save_button,
        .next_step {
          color: lightgrey;
          background-color: #29903b;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }
  }

  .form__body__users {
    ${'' /* width: 100%; */}
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 30px;
    align-items: center;
    border-radius: 0 0 15px 15px;
    ${'' /* padding-top: 58px; */}

    .form__title {
      display: flex;
      width: 100%;
      padding: 10px;
      justify-content: center;
      align-items: center;
      padding: 10px;
      ${'' /* background-color: #22262e; */}

      ${'' /* margin-left: 55px; */}
      text-align: left;
      color: white;
      font-weight: bold;
      position: fixed;
      top: 0px;
      z-index: 550;

      .icon__Form_title {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        color: ${colors.primaryColor};
      }

      .title__Form_title {
        display: flex;
        align-items: center;
        color: ${colors.primaryColor};
        font-size: 16px;
      }
    }

    .container__cards {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 15px;
      padding-top: 30px;

      .contain__inputCard-component {
        width: 100%;
        height: 100%;
        padding: 40px;
        padding-right: 220px;

        .section__name {
          padding-left: 0;
        }
      }
      .section_name {
        padding-left: 0;
      }
      .stepForm__contain-typeInput {
        gap: 10px;

        /* padding-top: 10px; */
        /* padding-bottom: 30px; */
      }

      ${
        '' /* .contain__input {
        width: unset;
        max-width: 200px;
      } */
      }

      .contain__select {
        width: unset;
        ${'' /* grid-column-start: 1; */}
      }
      .contain__button-save {
        opacity: 1;
        height: 35px;
        position: fixed;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 68px;
        z-index: 718;

        .cancel_button {
          color: white;
          background-color: ${colors.primaryColorLight};
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: ${colors.primaryColor};
          }
        }

        .save_edit_button {
          background-color: ${colors.greenGYGAS};

          font-size: 14px;
          width: 100px;
          font-weight: bold;
          margin-right: 20px;
          border-radius: 10px;

          &:hover {
            background-color: ${colors.greenGYGASLight};
          }
        }

        .next_step {
          color: lightgrey;
          background-color: #29903b;
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }
  }

  .trunksoutboundscreen {
    box-sizing: border-box;
    height: 100%;

    .contain__table {
      box-sizing: border-box;
      height: calc(100% - 55px);
      position: relative;
      display: flex;
      /* width: auto; */
      margin-top: 20px;
      left: 5px;
    }
  }

  .error__container {
    width: 65%;
    margin: auto;
    margin-top: 100px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      width: 100%;
    }
    .error__body {
      text-align: left;
    }
    .error__title {
      color: red;
    }
  }

  .container__cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 100px; */

    .contain__inputCard-component {
      width: 90%;
      display: grid;

      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      flex-direction: column;
      gap: 15px;
      /* color: #515253; */
      margin: auto;
    }
  }

  .customDeleteButtonSwal {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    opacity: 0.7;
    background-color: #cd3434;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
  }

  .customDeleteButtonSwal:hover {
    background-color: #cd3434;
    opacity: 1;
  }

  .customConfirmButtonSwal:hover {
    background-color: #ec1616;
    color: white;
  }

  .customCancelButtonSwal {
    margin-right: 10px;
    border: none;
    opacity: 0.7;
    background-color: #22262e;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    width: 180px;
    height: 28px;
  }

  .customCancelButtonSwal:hover {
    background-color: #22262e;
    opacity: 1;
  }

  .customIconSwal {
    background-color: #d2d3d5;
    border: none;
  }

  .customConfirmButtonSwalPassword {
    border: none;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customConfirmButtonSwalPassword:hover {
    background-color: lightgrey;
    color: black;
    width: 100%;
  }

  .copyPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
  }

  .password {
    width: 80%;
  }

  .copyImage {
    width: 20%;
  }

  .copyImageIcon {
    height: 25px;
  }
`;
