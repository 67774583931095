import styled from 'styled-components';

export const ProductivityWrapper = styled.div`
  height: 100%;

  .selector {
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }

  .border {
    border: 1px solid #eeecec;
    border-radius: 16px;
  }

  .shadow {
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.25));
  }

  .text_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    padding-left: 10px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    margin: 0;
  }

  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }

  .grid {
    height: 700px;

    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(10, 1fr);
    gap: 30px;

    padding: 0 40px;

    .bar {
      font-size: 11px;
    }

    .chart_contain {
      width: 100%;
      height: 80%;
    }

    .main_chart {
      grid-column: 1 / 7;
      grid-row: 1 / 5;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 30px 20px 0;

      font-size: 13px;
    }

    .lateral_pie {
      grid-column: 7 / 11;
      grid-row: 1 / 4;

      font-size: 12px;
    }

    .lateral_bar {
      grid-column: 7 / 11;
      grid-row: 4 / 7;
    }

    .bottom_status {
      grid-column: 1 / 4;
      grid-row: 5 / 9;
    }

    .bottom_pie {
      grid-column: 4 / 7;
      grid-row: 5 / 9;
    }

    .first_lateral_stat {
      grid-column: 7 / 9;
      grid-row: 7 / 9;
      border-radius: 15px;
      background: #dff5f3;
    }

    .second_lateral_stat {
      grid-column: 9 / 11;
      grid-row: 7 / 9;
      border-radius: 15px;
      background: #f4f2ff;
    }
  }
`;
