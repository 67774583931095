import React, { useEffect, useState } from 'react';

import { dataScreenIvr } from '../../data/dataApplications';
import { colors } from '../../theme/colors';
import { DestinationSelect } from '../DestinationSelect/DestinationSelect';
import { InputNumber } from '../InputNumber/InputNumber';
import { Key } from './Key/Key';

import { PhoneWrapper } from './Phone.style';
import { func, object, string } from 'prop-types';
// import { selectedDocActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import { useDispatch } from 'react-redux';
// import { removeIvrFormDestinationAction } from '../../redux/actions/applications/actionCreators';

export const Phone = ({ onChange, refForm, crudType, item }) => {
  const [isActive, setIsActive] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [numbersActives, setNumbersActives] = useState([]);
  const [timeOutDefaultValues, setTimeOutDefaultValues] = useState(undefined);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { phone } = dataScreenIvr;
  const { data, icon, text, timeout, notPressed } = phone;

  // const dispatch = useDispatch();

  const onClick = (itemPressed) => {
    const found = numbersActives.length > 0 ? numbersActives.some((e) => e === itemPressed.target.innerText) : false;

    !found ? setIsActive(itemPressed.currentTarget.innerText) : null;

    // if (found) {
    //   const existingIndexInSelect = selectedOption.findIndex((item) => item.title === itemPressed.currentTarget.innerText);
    //   const optionToDelete = selectedOption[existingIndexInSelect];
    //   handleSelectChange(optionToDelete, 'clear', itemPressed.currentTarget.innerText);
    // }
  };
  //   setIsActive(newActives);

  //   const existingIndexInSelect = selectedOption.findIndex((item) => item.title === itemKey.currentTarget.innerText);
  //   const newSelectedOption = selectedOption[existingIndexInSelect];
  //   handleSelectChange(newSelectedOption, 'clear', newSelectedOption.title);
  //   // handleNumberActiv  e(isActive);
  //   const newActivesList = newActives
  //     .map((e) => (e !== itemKey.target.innerText ? e : null))
  //     .filter(function (el) {
  //       return el != null;
  //     });

  //     setNumbersActives(newActivesList);
  //     onChange(newSelectedOption, itemKey.target.innerText, refForm);
  //   } else {
  //     setIsActive(itemKey.target.innerText);
  //   }
  // };

  const handleNumberActive = (isActive) => {
    setNumbersActives([...numbersActives, isActive]);
  };

  const handleDeleteOption = (data) => {
    const existingIndexInSelect = selectedOption.findIndex((item) => item.title === data.title);
    const existingIndexInNumbers = numbersActives.findIndex((item) => item === data.title);
    if (existingIndexInSelect >= 0) {
      setSelectedOption(selectedOption.filter((item, index) => index !== existingIndexInSelect));
    }
    if (existingIndexInNumbers >= 0) {
      setNumbersActives(numbersActives.filter((item, index) => index !== existingIndexInNumbers));
    }
    setIsActive(numbersActives.filter((item, index) => index !== existingIndexInNumbers));
    // setIsActive([]);
    onChange(data, data.title, refForm);
  };

  const handleSelectChange = (option, name, number) => {
    if (name === 'clear') {
      option.title = number;
      handleDeleteOption(option, number);
    } else {
      const existingIndex = selectedOption.findIndex((item) => item.key === isActive);
      let newOption = {};
      if (existingIndex >= 0) {
        setSelectedOption(
          selectedOption.map((itemSel, index) => {
            if (index === existingIndex) {
              return {
                label: option.label,
                value: Number(option.value),
                ref: option.name,
                key: name,
                title: option.title,
                name: option.name,
              };
            }
            return itemSel, option.name;
          })
        );
      } else {
        newOption = {
          label: option?.label,
          value: Number(option?.value),
          ref: option.ref,
          key: isActive,
          title: name.name !== undefined ? name.name : name,
          name: option?.name !== undefined ? option.name : option.label,
        };
        setSelectedOption([...selectedOption, newOption]);
        handleNumberActive(isActive);
      }
      setIsActive([]);
      onChange(option, newOption.key, refForm);
    }
  };

  const handelTimeOut = (value) => {
    const timeout = {
      label: 'timeout',
      ref: 'timeout',
      value: value,
    };

    onChange(timeout, 'timeout', refForm);
  };

  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit') {
        const { defaultValues } = item;

        const updatedNumbersActives = defaultValues.filter((obj) => obj.destinationType).map((obj) => obj.key);

        setNumbersActives(updatedNumbersActives);

        const findSelectedOption = defaultValues
          .filter((obj) => obj.destinationType)
          .map((obj) => ({
            title: obj.key !== 'timeout' ? obj.key : 'Si no se pulsa',
            label: obj.destinationName,
            value: obj.destinationId !== 0 ? obj.destinationId : 'hangup',
            name: obj.name,
          }));

        setSelectedOption(findSelectedOption);

        const timeOut = defaultValues[11];
        setTimeOutDefaultValues(timeOut && timeOut.timeOut !== undefined ? [timeOut.timeOut] : [2]);

        // setIsActive(isActive === '' ? updatedNumbersActives : isActive);
        // setIsActive(updatedNumbersActives);
        setIsActive(isActive);
      } else {
        setTimeOutDefaultValues([2]);
      }
      if (selectedOption.length === 0) {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 500);
  }, [item]);

  return (
    <PhoneWrapper>
      <div className="phone">
        <div className="phone__selection"></div>
      </div>

      <div className="phone__container">
        <div className="phone__left">
          <div className="phone__keys">
            {data.map((itemKey, index) => (
              <div className="phone__key" key={index}>
                {itemKey === isActive || numbersActives.includes(itemKey) ? (
                  <Key item={itemKey} onClick={onClick} backgroundColor={colors.secondaryColor} onChange={handleSelectChange} />
                ) : itemKey === '*' || itemKey === '#' ? (
                  <Key item={itemKey} onClick={() => {}} backgroundColor={colors.greyHover} isDisable={true} />
                ) : (
                  <Key item={itemKey} onClick={onClick} backgroundColor={colors.white} onChange={handleSelectChange} />
                )}
              </div>
            ))}
          </div>
          <div className="phone__notPressed">
            <div className="phone__notPressed__contain">
              <div className="phone__notPressed__title">
                <p className="phone__notPressed__title__item">{notPressed}</p>
              </div>
              <div className="phone__notPressed__select">
                <DestinationSelect onChange={handleSelectChange} name={notPressed} number={isActive} placeholder={'Selecciona una opción'} defaultValue={{}} />
              </div>
            </div>
            {/* </div> */}
            <div className="phone__timeOut">
              <div className="phone__timeOut__contain">
                <div className="phone__timeOut__title">
                  <p>{timeout}</p>
                </div>
                <div className="phone__timeOut__input">
                  <InputNumber onChange={handelTimeOut} defaultValues={timeOutDefaultValues} name={'timeOut'} id={'timeOut'} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="phone__list__actives">
          <div className="phone__selection__items">
            {isActive.length > 0 ? (
              <>
                <div className="phone__key__active">
                  {text} {isActive}
                </div>
                <div className="phone__select">
                  <DestinationSelect
                    onChange={handleSelectChange}
                    name={selectedOption.length > 0 ? selectedOption[0]?.name : ''}
                    number={isActive}
                    placeholder={'Selecciona una opción'}
                    defaultValue={{}}
                  />
                </div>
              </>
            ) : null}
          </div>
          <p>Asignadas</p>

          {selectedOption.length !== 0 && (
            <div className="phone__info">
              {selectedOption.map((option, index) => (
                <React.Fragment key={index}>
                  <div className="phone__key__active">
                    {text} {numbersActives[index]}
                  </div>
                  <div className="phone__option__active">
                    <div className="phone__option__item">
                      <p>{option.value === 'hangup' ? option.label : `${option.name} : ${option.label?.name === undefined ? option.label : option.label?.name}`}</p>
                      <img src={icon.cross.img} alt={icon.cross.alt} onClick={() => handleDeleteOption(option)} />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </PhoneWrapper>
  );
};
Phone.propTypes = { onChange: func, refForm: string, crudType: string, item: object };
