import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { weight } from '../../../theme/fonts';

export const KeyWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${colors.greyLine};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  //transition: all 0.3s ease-in-out;
  color: ${colors.black};
  border-radius: 50%;

  &:active {
    transform: ${(props) => (props.isDisable ? '' : `scale(0.96)`)};
    //transition: all 0.1s;
  }

  .key {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      font-weight: ${weight.regular};
      font-size: 25px;
      line-height: 29px;
    }
  }
  &:hover {
    border: ${(props) => (props.isDisable ? '' : `1px solid ${colors.primaryColor}`)};

    color: ${(props) => (props.isDisable ? '' : `${colors.primaryColor}`)};
    cursor: ${(props) => (props.isDisable ? '' : `pointer`)};
    //transition: all 0.3s ease-in-out;
  }
`;
