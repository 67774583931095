import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .pageScreen__title {
    position: fixed;
    background-color: white;
    left: 13%;
    box-sizing: border-box;
    width: auto;
    // min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      background-color: white;
      margin-left: 10px;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.bold};
      letter-spacing: 0.25px;
    }
  }

  .icon__dispositivo {
    color: ${colors.greenGYGAS};
  }

  .user__contain__table {
    height: 100%;
    width: auto;
    ${'' /* max-width: 750px; */}
    ${'' /* padding-left: ${(props) => (props.isMenuOpen ? '160px' : '60px')}; */}
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    z-index: 900;
    padding-top: 5px;
    padding-bottom: 125px;
    background-color: ${colors.backgroundColor};

    .left {
      width: 100%;
      height: 30px;
      padding-left: 5px;
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      ${'' /* min-width: 800px; */}

      .icon-plus {
        width: 35px;
        height: 35px;
        padding: 5px;
        background-color: #0c3fc4;
        color: white;

        &:hover {
          cursor: pointer;
          border-radius: 10px;
        }
      }

      .icon-menu {
        color: #0c3fc4;
        /* display: flex; */
        width: 100%;
        /* height: 30px; */
        padding-left: 5px;
      }
      /* z-index: 999;
      font-size: 30px;
      font-weight: 900; */
    }

    .tooltip-column {
      width: 100%;
      color: white;
      display: flex;
      justify-content: space-between;
      padding-right: 5px;

      tr th:last-child div:last-child {
        justify-content: left;
      }
    }

    thead {
      background-color: ${colors.primaryColor};
      border-radius: 10px;
      color: white;
      position: relative;
      margin-left: 10px;
      z-index: 2;
    }

    .nav__buttons__container {
      height: 50px;
      width: 100%;
    }

    ${
      '' /* .user__table {
      width: 90%;
    } */
    }

    .section__name {
      width: max-content;
      display: flex;
      align-items: center;
      color: #0c3fc4;
      height: 30px;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      z-index: 90;
      padding-left: 5px;
    }

    .navtable__container {
      width: 100%;
      height: 30px;
      position: relative;
      top: 0px;
      left: 0px;
      color: rgb(0, 0, 0);
      display: flex;
      justify-content: flex-end;
      z-index: 9;
      text-align: end;
      flex-direction: row-reverse;

      table {
        position: relative;
        top: -40px;
        left: 40px;
      }
    }

    tr th:nth-child(2) {
      border-left: none;
      font-weight: 700;
      cursor: pointer;
      width: 40px;
      min-width: unset;
    }

    .row {
      color: black;
      width: 100%;
      border-bottom: rgb(215, 215, 215) 1px solid;

      &:hover {
        td {
          color: ${colors.blue};
        }
      }
      td {
        cursor: auto;
        color: black;
      }
    }

    .contain__button-save {
      opacity: 1;
      height: 35px;
      position: fixed;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      top: 20px;
      /* right: 100px; */
      z-index: 718;

      button {
        color: lightgrey;
        background-color: #29903b;
        border-radius: 10px;
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;
      }

      button:hover {
        color: white;
        background-color: #2faa43;
      }
    }
  }

  .pageScreen {
    width: 100%;
    display: flex;
    background-color: ${colors.primaryColor};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: scroll;
    padding-top: 102px;
    ${
      '' /* position: relative;
    top: 10px; */
    }

    &__tabs {
      ${'' /* background-color: #ffffff; */}
      display: flex;
      justify-content: flex-start;
      border: none;
      position: fixed;
      z-index: 101;
      margin: auto;
      padding: 20px;
      height: 60px;
      width: 100%;

      &__tab {
        min-width: 130px;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${colors.blue};

        &:hover {
          cursor: pointer;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          // font-size: ${newFontSize.h3};

          border: none;
        }
      }

      .active {
        min-height: 35px;
        padding: 5px;
        ${'' /* background-color: ${colors.blue}; */}
        color: ${colors.blue};
        border-radius: 10px 10px 0 0;
        border-bottom: 2px solid ${colors.blue};
        font-weight: ${weight.bold};
      }

      .inactive {
        min-height: 35px;
        padding: 5px;
        color: ${colors.black};
        border-radius: 10px 10px 0 0;

        &:hover {
          cursor: pointer;
          color: ${colors.blue};
        }
      }
    }

    &__form {
      height: 100%;
      width: 100%;
      ${'' /* padding-left: ${(p) => (!p.isMenuOpen ? '30px' : '160px')}; */}
      padding-right: 30px;
      border: 1px solid ${colors.primaryColor};
      background-color: ${colors.backgroundColor};
      border-radius: 15px 0;

      .contain__button-save {
        opacity: 1;
        height: 35px;
        position: fixed;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        top: 75px;
        right: 10px;
        z-index: 718;

        .cancel_button {
          color: lightgrey;
          /* background-color: #c142269a; */
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #c14326;
          }
        }

        .save_button {
          color: lightgrey;
          background-color: #29903b;
          border-radius: 10px;
          width: 100px;
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;

          &:hover {
            color: white;
            background-color: #2faa43;
          }
        }
      }
    }
  }
`;
