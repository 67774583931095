import React from 'react';
// import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
// import { menuApplications } from '../../data/dataApplications/index.js';
// import { menuNavLeft } from '../../data/dataNavMenuLeft/index.js';
import { ApplicationsNavigation } from '../../navigation/ApplicationsNavigation/ApplicationsNavigation';

import './ApplicationsScreen.css';
import { menuApplications } from '../../data/dataApplications';
// import { useLocation } from 'react-router-dom';
// import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
// import { Header } from '../../components/Header/Header.jsx';
// import { menuNavLeft } from '../../data/dataNavMenuLeft/index.js';

const ApplicationsScreen = () => {
  // const [, setIsForm] = useState(false);
  // const location = useLocation();
  // const { pathname } = useLocation();

  // const windowLocation1 = pathname.split('/')[2];

  // useEffect(() => {
  //   setIsForm(location?.pathname.includes('create') || location?.pathname.includes('update') ? true : false);
  // }),
  //   [location];

  return (
    <div className="applications">
      {/* <Header text={menuNavLeft.menuTop[3].text} /> */}
      {/* {!isForm ? <MenuTop menu={menuApplications} /> : <Breadcrumb location1={windowLocation1} />} */}
      <MenuTop menu={menuApplications} />
      <ApplicationsNavigation />
    </div>
  );
};

export default ApplicationsScreen;
