import styled from 'styled-components';

export const ConfigurationAsteriskScreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;

    &__drop {
      display: flex;
    }
  }
`;
