import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
// import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenShortMarking } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import { ShortMarkingScreenScreenWrapper } from './ShortMarkingScreen.style';
// import { NavTable } from '../../../components/NavTable/NavTable';

export const ShortMarkingScreen = () => {
  const { shortMarking, getShortMarking, deleteShortMarking, uploadFileShortMarking, loading } = useApplications();
  const { typeSwal } = useSwal();
  const { isCardView } = useView();
  const [checkBoxesActives, setCheckboxesActives] = useState([]);
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound } = dataScreenShortMarking;

  let navigate = useNavigate();

  useEffect(() => {
    getShortMarking();
    setSearch([]);
  }, [setSearch]);

  const COLUMNS = [
    {
      Header: 'Número corto',
      accessor: 'num_corto',
    },
    {
      Header: 'Destino',
      accessor: 'destino',
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Sede',
      accessor: 'company',
    },
  ];

  const DATA = shortMarking.map(({ id, nombre, num_corto, destino, Company }) => ({
    id,
    nombre,
    num_corto,
    destino,
    company: Company?.name,
    companyId: Company?.id,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ id, nombre, num_corto, destino, company, companyId }) => ({
        title: num_corto,
        subtitle: nombre,
        text: destino,
        text2: company,
        id,
        nombre,
        num_corto,
        destino,
        company,
        companyId,
      }));
    } else {
      return DATA.map(({ id, nombre, num_corto, destino, Company }) => ({
        title: num_corto,
        subtitle: nombre,
        text: destino,
        text2: Company?.name,
        id,
        nombre,
        num_corto,
        destino,
        company: Company?.name,
        companyId: Company?.id,
      }));
    }
  };

  const dataToCards = dataCard();

  const dataExport = shortMarking.map(({ nombre, num_corto, destino, Company }) => ({
    Nombre: nombre,
    'Num corto': num_corto,
    Destino: destino,
    Compañia: Company?.name,
  }));

  const handleOpenFormCreate = () => {
    navigate('/applications/shortMarking/create', {});
  };
  const handleOpenFormEdit = (item) => {
    navigate('/applications/shortMarking/update', {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteShortMarking(item.original);
          } else {
            response = await deleteShortMarking(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getShortMarking();
          }
        } catch (err) {
          if (import.meta.env.VITE_DEBUG === 'true') console.log(err);
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileShortMarking(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getShortMarking();
      }
    } catch (err) {
      return err;
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      'Número Corto': '',
      Destino: '',
      Nombre: '',
      'Sede Id': '',
    },
  ];

  return (
    <ShortMarkingScreenScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'right',
              data: DATA,
              columns: COLUMNS,
            },
            filter: {
              position: 'right',
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
        {/* {shortMarking[0]?.status ? null : (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterShortMarking} setSearch={setSearch} />
          </div>
        )} */}
        {/* <NavTable checkboxesActives={checkBoxesActives} deleteFunction={deleteShortMarking} /> */}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : shortMarking[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            format={'list'}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
            isCheckbox={true}
            isNavTable={true}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
        </div>
      )}
    </ShortMarkingScreenScreenWrapper>
  );
};
