import { useEffect, useState } from 'react';
import React from 'react';
import { array, string } from 'prop-types';
import {
  IconAlertCircle,
  // IconEdit,
  IconMail,
  IconNumber,
  IconPhone,
  IconStack,
  IconUser,
  IconMicrophone,
  IconTableShortcut,
  IconPlaylist,
  IconBrandTabler,
  IconClipboardData,
  IconLicense,
  IconPlus,
} from '@tabler/icons-react';
import { useStore } from 'react-redux';
import { IconMicrophone2 } from '@tabler/icons-react';
import { IconArrowsDiff } from '@tabler/icons-react';
// import { IconMicrophone2 } from '@tabler/icons-react';

export const FormTitle = ({ type, formTitle }) => {
  const { getState } = useStore();

  const {
    queueFormReducer,
    ivrFormReducer,
    mailboxesFormReducer,
    conferenceFormReducer,
    shortMarkingFormReducer,
    songWaitingFormReducer,
    usersFormReducer,
    domainsFormReducer,
    EMFormAlertsReducer,
    arrayNumbering,
    formExtensionsReducer,
    whitelistFormReducer,
    companyFormReducer,
    FormListNumberingReducer,
  } = getState();

  const [title, setTitle] = useState(formTitle[0] || '');
  const [Icon, setIcon] = useState('');
  // const [Icon2, setIcon2] = useState('');

  const setFormTitle = () => {
    if (type === 'assignRule') {
      setTitle('Crear nueva regla');
      setIcon(<IconLicense className="icon__Form_title" />);
    } else {
      const strItemTitle = title;
      const strType = strItemTitle.slice(-1) !== 'o' && strItemTitle.slice(-1) !== 'a' ? 'a' : strItemTitle.slice(-1);
      const newLabel = 'Nuev' + strType + ' ';
      const isCreate = type === 'create' || type === 'createUserDispo';
      const firstWord = isCreate ? newLabel : strItemTitle === 'assignUserDispo' ? 'Asignar dispositivo a' : strType;
      let newUserName = '';

      switch (strItemTitle) {
        case 'Usuario':
          setTitle(isCreate ? firstWord + title : usersFormReducer.firstname + ' ' + usersFormReducer.lastname);
          setIcon(<IconUser className="icon__Form_title" />);
          break;
        case 'Alerta':
          setTitle(isCreate ? firstWord + strItemTitle : EMFormAlertsReducer.name);
          setIcon(<IconAlertCircle className="icon__Form_title" />);
          break;
        case 'Cola':
          setTitle(isCreate ? firstWord + strItemTitle : queueFormReducer.name);
          setIcon(<IconStack className="icon__Form_title" />);
          break;
        case 'Número':
          setTitle(isCreate ? firstWord + strItemTitle : arrayNumbering.description);
          setIcon(<IconNumber className="icon__Form_title" />);
          break;
        case 'Dispositivo':
          newUserName = usersFormReducer?.firstname + ' ' + usersFormReducer?.lastname;

          setTitle(
            isCreate && type === 'createUserDispo'
              ? firstWord + strItemTitle + ' para ' + newUserName
              : type === 'edit'
                ? formExtensionsReducer.identification
                : type === 'assignUserDispo'
                  ? 'Asignar dispositivo a ' + newUserName
                  : ''
          );

          type === 'createUserDispo'
            ? setIcon(<IconPlus className="icon__Form_title1" color="white" width={'35px'} height={'35px'} />)
            : setIcon(<IconArrowsDiff className="icon__Form_title1" color="#29903b" width={'35px'} height={'35px'} />);
          // setIcon2(<IconPhone className="icon__Form_title2" color="#4176ff" />);
          break;
        case 'Buzón':
          setTitle(isCreate ? firstWord + strItemTitle : mailboxesFormReducer.name);
          setIcon(<IconMail className="icon__Form_title" />);
          break;
        case 'Conferencia':
          setTitle(isCreate ? firstWord + strItemTitle : conferenceFormReducer.name);
          setIcon(<IconMicrophone2 className="icon__Form_title" />);
          break;
        case 'Ivr':
          setTitle(isCreate ? firstWord + strItemTitle : ivrFormReducer.name);
          setIcon(<IconPhone className="icon__Form_title" />);
          break;
        case 'Locución':
          setTitle(isCreate ? firstWord + strItemTitle : ivrFormReducer.name);
          setIcon(<IconMicrophone className="icon__Form_title" />);
          break;
        case 'Marcación abreviada':
          setTitle(isCreate ? firstWord + strItemTitle : shortMarkingFormReducer.nombre);
          setIcon(<IconTableShortcut className="icon__Form_title" />);
          break;
        case 'Música en espera':
          setTitle(isCreate ? firstWord + strItemTitle : songWaitingFormReducer.name);
          setIcon(<IconPlaylist className="icon__Form_title" />);
          break;
        case 'Dominio':
          setTitle(isCreate ? firstWord + strItemTitle : domainsFormReducer.domain);
          setIcon(<IconBrandTabler className="icon__Form_title" />);
          break;
        case 'Lista blanca':
          setTitle(isCreate ? firstWord + strItemTitle : whitelistFormReducer.ipAddr);
          setIcon(<IconClipboardData className="icon__Form_title" />);
          break;
        case 'Sede':
          setTitle(isCreate ? firstWord + strItemTitle : companyFormReducer.name);
          setIcon(<IconLicense className="icon__Form_title" />);
          break;
        case 'Numeración externa':
          setTitle(isCreate ? firstWord + strItemTitle : FormListNumberingReducer.name);
          setIcon(<IconLicense className="icon__Form_title" />);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    title === '' || title === 'Numeración externa' || title === 'Usuario' || title === 'Nuevo Usuario' || title === 'Dispositivo' || title === 'Nuevo Dispositivo' ? setFormTitle() : null;
  }),
    [title];

  return (
    <div className="form__title">
      {Icon}
      {/* {Icon2} */}
      <span className="title__Form_title">{title}</span>
    </div>
  );
};

FormTitle.propTypes = { formTitle: array, formIcon: string, type: string };
