import { IconHash, IconLayoutDashboard, IconUsers, IconLayoutGrid, IconListCheck, IconArrowForwardUp, IconSettings, IconAmbulance, IconTool } from '@tabler/icons-react';

//svg from NavMenuMedium

//logo
import zerovozLogo from '../../assets/images/logo/zerovoz-logo.png';
import minizerovoz from '../../assets/images/logo/zerovoz-mini.svg';

//paths for routes navigation
import { routesWide } from '../../navigation/routes';

import arrow from '../../assets/images/navMenu/arrow.svg';

export const logo = zerovozLogo;
export const miniLogo = minizerovoz;

export const menuNavLeft = {
  chevrons: { icon: arrow, alt: 'button open close navbar' },
  menuTop: [
    {
      image: IconLayoutDashboard,
      text: 'Dashboard',
      path: routesWide.dashboard,
      restricted: 'numbering',
    },
    {
      text: 'Divider',
    },
    {
      image: IconUsers,
      text: 'Usuarios',
      path: routesWide.users,
      restricted: 'numbering',
    },
    {
      image: IconHash,
      text: 'Numeraciones',
      path: routesWide.numbering,
      restricted: 'numbering',
    },
    {
      image: IconLayoutGrid,
      text: 'Aplicaciones',
      path: routesWide.applications,
      restricted: 'applications',
    },
    {
      image: IconListCheck,
      text: 'Llamadas',
      path: routesWide.cdr,
      restricted: 'cdr',
    },
    {
      image: IconArrowForwardUp,
      text: 'Rutas de salida',
      path: routesWide.outboundRoutes,
      restricted: 'outboundRoutes',
    },
    // {
    //   text: 'Divider',
    // },
    {
      image: IconAmbulance,
      text: 'Emergency manager',
      path: routesWide.EM,
      restricted: 'emergencyManager',
    },
    // {
    //   text: 'Divider',
    // },
    {
      image: IconTool,
      text: 'Configuración',
      path: routesWide.settings,
      restricted: 'settings',
    },
    // {
    //   text: 'Divider',
    // },
  ],
  menuBottom: [
    {
      image: IconSettings,
      text: 'Panel de administración',
      path: routesWide.settings,
      restricted: 'settings',
    },
    // {
    //   text: 'Divider',
    // },
  ],
};
