import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { formCreateApiKey } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';

export const ApiScreenForm = ({ crudType }) => {
  const { createApi, settingsForm, getApi } = useSettings();

  const { typeSwal } = useSwal();
  // const [refresh, setRefresh] = useState(false);

  const data = formCreateApiKey;

  useEffect(() => {
    settingsForm(
      { action: 'clearForm' },
      {
        form: 'apiKey',
      }
    );
  }, []);

  useEffect(() => {
    getApi();

    if (crudType === 'edit') {
      // setRefresh(true);
      data.tabs.map((tab) => {
        tab.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.carType === 'input') {
              settingsForm(
                {
                  action: item2.cardType,
                  value: item2.defaultValues,
                },
                {
                  form: 'apiKey',
                  element: item2.ref.element,
                }
              );
            }
          });
        });
      });
    }
  }, []);

  const onSubmit = () => {
    if (crudType === 'create') {
      typeSwal('createLoader', '', settingsForm, getApi, `/${routesWide.settings}/${routesSettings.api}`, 'api', createApi);
    }
  };

  return <>{<FormScreen arrScreen={['API']} data={data} onSubmit={onSubmit} crudType={crudType} form={settingsForm} />}</>;
};

ApiScreenForm.propTypes = { crudType: string };
