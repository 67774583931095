import React, { useEffect } from 'react';
import Select from 'react-select';
import { useHeadquarter } from '../../hooks/useHeadquarter';
import { useValidationForm } from '../../hooks/useValidationForm';
import { SelectWrapper } from './MySelect.style';
import { array, bool, func, number, object, string } from 'prop-types';

export const MySelect = ({
  item,
  options,
  placeholder,
  inputRef,
  defaultValue,
  isMulti,
  isSearchable,
  form,
  setInputValue,
  setErrorInput,
  setValidationNormalInput,
  validationNormalInput,
  allSteps,
  idPage,
  destinationOpen,
  isDisabled,
  setValidateReducer,
  reducerForm,
  firstData,
  value,
}) => {
  const { getHeadquarter, companyLink } = useHeadquarter();
  const { validationForm } = useValidationForm();

  useEffect(() => {
    getHeadquarter();
  }, []);

  try {
    const handleSelectChange = (inputValue, action, item) => {
      if (reducerForm !== undefined) {
        item.idDestination = destinationOpen === undefined ? null : destinationOpen;
        item.idPage = idPage;
        item.action = action.action;

        let responseReducerValidate = validationForm(reducerForm, firstData, item);
        responseReducerValidate && setValidateReducer && setValidateReducer(responseReducerValidate);
      }

      if (item.type === 'dynamic') {
        const associatedDataInputs = allSteps.reduce((inputs, step) => [...inputs, ...step.input.filter((input) => input.ref && input.ref.type === 'associatedData')], []);

        const departments = companyLink.filter((item) => item.Company.id === +inputValue?.value).map((item) => item.Department);

        associatedDataInputs.forEach((input) => {
          input.options.splice(0, input.options.length);
          input.options = departments.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        });
      }

      if (inputValue?.length > 0) {
        if (action.action === 'remove-value') {
          setInputValue((prevState) => ({
            ...prevState,
            [item.element]: inputValue,
          }));
          action.cardType = 'multiselect';
          form(action, item, inputValue);
        }
        Array.isArray(inputValue)
          ? inputValue.forEach((input) => {
              const newState = item.ref?.element ? item.ref?.element : item.element;
              setInputValue((prevState) => ({
                ...prevState,
                [newState]: { value: Number(input?.value), label: input?.label },
              }));
            })
          : setInputValue((prevState) => ({
              ...prevState,
              [item.element]: inputValue,
            }));
      }

      if (inputValue?.label === '' || inputValue?.length === 0 || inputValue === null) {
        setErrorInput(true);
        setValidationNormalInput((prevState) => [...prevState, item.element]);
      }
      if (inputValue?.length === 0) {
        setInputValue((prevState) => ({
          ...prevState,
          [item.element]: '',
        }));
      } else {
        setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.element));
        if (validationNormalInput.length === 0) setErrorInput(false);
      }

      // if (inputValue) {
      //   inputValue.optionRefPage = idPage;
      //   inputValue.destinationOpen = destinationOpen;
      // }

      if (action.removedValues) {
        action.removedValues[0].optionRefPage = idPage;
        action.removedValues[0].destinationOpen = destinationOpen;
      }
      if (action.removedValue) {
        action.removedValue.optionRefPage = idPage;
        action.removedValue.destinationOpen = destinationOpen;
      }

      // item.element !== 'extensions' ? form(action, item, inputValue) : null;
      if (item.element === 'captureId') {
        // || item.element === 'allow') {
        action.action = 'multiselect';
        item.action = 'multiselect';
      }

      form(action, item, inputValue);
    };

    // const [itemIsDisabled, setItemIsDisabled] = useState(false);

    // const handleIsDisabled = (item) => {
    //   const element = item.ref.element;
    //   let newStatus = false;

    //   switch (element) {
    //     case 'SIPExtension':
    //       newStatus = reducerForm.isSIPExtension ? false : true;
    //       break;
    //     case 'identification':
    //       newStatus = true;
    //       break;
    //     case 'allow':
    //       newStatus = location.state.typeExtension === 'Extensión' ? true : false;
    //       break;
    //     default:
    //       newStatus = false;
    //       break;
    //   }
    //   setItemIsDisabled(newStatus);
    // };

    // console.log('default value', defaultValue);
    return (
      <SelectWrapper>
        {Object.keys(defaultValue || '').length > 0 ? (
          value ? (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              value={defaultValue}
              // defaultValue[0] !== undefined ? { value: defaultValue[0], label: options[0].label } :
              // value={defaultValue}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
              className={isDisabled ? 'disabled' : ''}
              // disabled={item.ref.element === 'SIPExtension' && form.isSIPExtension === 0 ? true : false}
            />
          ) : (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              defaultValue={defaultValue}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
            />
          )
        ) : (
          <Select
            options={options[0]?.name ? options.map((option) => ({ name: option.name, label: option.label + ' - ' + option.name, value: option.value })) : options}
            placeholder={placeholder}
            onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
            inputRef={inputRef}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={true}
            setValidateReducer={setValidateReducer}
          />
        )}
      </SelectWrapper>
    );
  } catch (error) {
    if (import.meta.env.VITE_DEBUG === 'true') console.log(error);
  }
};
MySelect.propTypes = {
  item: object,
  options: array,
  placeholder: string,
  inputRef: object,
  // defaultValue: PropTypes.oneOfType([PropTypes.oneOf([PropTypes.object, PropTypes.array])]),
  defaultValue: array,
  isMulti: bool,
  isSearchable: bool,
  form: func,
  setInputValue: func,
  setErrorInput: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  allSteps: array,
  idPage: number,
  destinationOpen: number,
  isDisabled: bool,
  setValidateReducer: func,
  reducerForm: object,
  firstData: object,
  value: array,
};
