import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const ToggleSwitchWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  .switch {
    width: 30px;
    height: 16px;
    display: flex;
    background: ${colors.blue};
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-shadow: inset 0px 0.461899px 3.07932px rgba(0, 0, 0, 0.15);
    border-radius: 15.3966px;
  }

  .switch[data-isOn='true'] {
    justify-content: flex-end;
    background: ${colors.blue};
    fill: ${colors.blue};
  }

  .valueToggle {
    margin-left: 10px;
    font-size: 14px;
  }

  .valueToggle__disabled {
    margin-left: 10px;
    color: ${colors.grey};
  }

  .switch__disabled {
    cursor: none;
    width: 30px;
    height: 16px;
    background: ${colors.blue};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: inset 0px 0.461899px 3.07932px rgba(0, 0, 0, 0.15);
    border-radius: 15.3966px;

    &:hover {
      cursor: none;
    }
  }

  .handle {
    width: 15px;
    height: 15px;
    background: linear-gradient(180deg, #ffffff 20.59%, #ffffff 85.35%);
    box-shadow: 0px 0px 0.620393px rgba(0, 0, 0, 0.35);
    border-radius: 15.5098px;
    border: 0.31px solid ${colors.greyBorder};
  }

  .handle[data-isOn='true'] {
    border: 0.31px solid ${colors.greyLine};
  }
`;

export const SecondVersionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 30px;

  .container {
    width: 140px;
    height: 30px;
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1px;
    color: #444972;
    gap: 5px;

    p {
      width: 50%;
      font-size: 11px;
      padding: 0 4px;
      margin: 0;
      padding: 0;
      text-align: center;
      font-weight: 700;
    }

    .selected {
      width: 50%;
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
  }
`;
