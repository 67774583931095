import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes, { bool, func, object } from 'prop-types';
import { useStore } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PermissionProvider from '../Permission/PermissionProvider';
import Restricted from '../Permission/PermissionConsumer';
import { useDispatch } from 'react-redux';
import { NavMenuWrapper } from './NavMenuLeft.style';
import { isMenuOpenActionCreator } from '../../redux/reducers/settingsReducers/systemReducers/systemReducers';
// import logo from '../../assets/images/logo/logo-color.png';
// import { Button } from '../Button/Button';
// import { ToastContainer } from 'react-toastify';
// import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import logoTpartner from '../../assets/images/logo/logo-alone-blanco.png';

export const NavMenuLeft = ({ menu }) => {
  const { menuTop } = menu;

  const { pathname } = useLocation();
  const { getState } = useStore();

  const dispatch = useDispatch();
  // const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  const handleMenu = () => {
    dispatch(isMenuOpenActionCreator(!isMenuOpen));
  };

  const [, setWindowLocation1] = useState('');

  const pathNameSeparate = pathname.split('/')[1];

  const permissions = getState()['arrayUsersPermission'];
  const titlePermissionsUser = permissions.map((item) => item.title);

  // const location1 = window.location.pathname.split('/')[2];
  const location1 = window.location.pathname.split('/')[1];

  const handleGetLocation = (location1) => {
    // setWindowLocation2(location1 !== '' ? location1 : '');
    setWindowLocation1(location1 !== '' ? location1 : '');
  };

  useEffect(() => {
    handleGetLocation(location1);
  }, [location1]);

  const changeView = () => {
    isMenuOpen ? dispatch(isMenuOpenActionCreator(false)) : null;
  };

  // const classBtHambur = isMenuOpen === true ? 'iconActionMenu' : 'iconActionMen';

  return (
    <PermissionProvider permissionsUser={titlePermissionsUser}>
      <NavMenuWrapper isMenuOpen={isMenuOpen} className="contain__navLeft">
        {/* <img src={logo} width="100%" height="40px" /> */}
        <div className="container__top">
          <div className="hamburguer__container">
            {/* <Button
              hoverIcon={IconArrowRight}
              id="menu"
              onClick={handleMenu}
              className={classBtHambur}
              Icon={isMenuOpen ? IconArrowLeft : ''}

              // Icon={IconArrowLeft}
            /> */}
            <img src={logoTpartner} className={'hamburguer__icon'} id="menu" onClick={handleMenu} />
            {isMenuOpen ? <span className={'hamburguer__text'}>TPartner </span> : null}
            {/* {isMenuOpen ? (
              <Button
                id="pin"
                onClick={handlePin}
                className={'iconActionMenu__Pin__open'}
                Icon={IconPin}
              />
            ) : null} */}
          </div>
          {menuTop.map((itemMenu, index) =>
            itemMenu.text === 'Divider' ? (
              <div key={index} className="item__divider" />
            ) : (
              <Restricted key={index} to={itemMenu.restricted}>
                <Link to={'../' + itemMenu.path} replace className="navleft-link">
                  <div className={itemMenu.path === pathNameSeparate ? 'item__active' : 'item__contain'} id={itemMenu.path} onClick={changeView}>
                    <itemMenu.image className="item__image" height={'40px'} />
                    {isMenuOpen ? <p className="item__text">{itemMenu.text}</p> : null}
                  </div>
                </Link>
              </Restricted>
            )
          )}
        </div>
        <ReactTooltip className="tooltip" anchorId="menu" place="bottom" content="Menú" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="dashboard" place="right" content="Dashboard" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="users" place="right" content="Usuarios" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="numbering" place="right" content="Numeraciones" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="applications" place="right" content="Aplicaciones" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="cdr" place="right" content="Llamadas" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="outboundRoutes" place="right" content="Rutas de salida" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="preferences" place="right" content="Configuración de usuario" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="EM" place="right" content="Emergency Manager" delayShow={500} />
        <ReactTooltip className="tooltip" anchorId="settings" place="right" content="Configuración" delayShow={500} />
      </NavMenuWrapper>
    </PermissionProvider>
  );
};

NavMenuLeft.propTypes = {
  menu: PropTypes.object.isRequired,
  isClosed: bool,
  setIsClosed: func,
  user: object,
};
