// import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import happy from '../assets/images/swal/happy.svg';
// import question from '../assets/images/swal/question.svg';
import runIcon from '../assets/images/login/run.svg';
import { iconButton } from '../data/dataButtons';

export const useSwal = () => {
  const navigate = useNavigate();

  const typeSwal = (type, name, hookForm, action, path, form, hookFunction) => {
    switch (type) {
      case 'config':
        return Swal.fire({
          heightAuto: false,
          background: '#EAECEF',
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: async () => {
            Swal.showLoading();
            const response = await name();
            if (response.status === 200) {
              Swal.fire({
                heightAuto: false,
                title: '¡ Añadido correctamente !',
                // iconHtml: `<img src = ${happy} alt = "happy face" />`,
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                background: '#EAECEF',
              });
            } else {
              typeSwal('error');
            }
          },
        });

      case 'create':
      case 'update':
        return Swal.fire({
          heightAuto: false,
          title: type === 'create' ? '¡ Añadido correctamente !' : '¡ Editado correctamente !',
          // iconHtml: `<img src = ${happy} alt = "happy face" />`,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          background: '#EAECEF',
        })
          .then(() => {
            hookForm({ action: 'clear' }, { form: form });
            action();
          })
          .finally(() => {
            navigate(path, {
              replace: true,
            });
          });

      case 'wantDelete':
      case 'modify':
      case 'changePassword':
        return Swal.fire({
          buttonsStyling: false,
          customClass: {
            confirmButton: 'customDeleteButtonSwal',
            cancelButton: 'customCancelButtonSwal',
            // icon: 'customIconSwal',
          },
          background: '#EAECEF',
          heightAuto: false,
          title: type === 'wantDelete' ? '¿Estás seguro de eliminar?' : type === 'modify' ? '¿Estás seguro de modificar?' : '¿Modificar la contraseña?',
          text: type === 'wantDelete' || type === 'changePassword' ? 'Ésta acción no se puede deshacer.' : '',
          // iconHtml: `<img src = ${question} alt = "Surprised face" />`,
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: type === 'wantDelete' ? 'Sí, eliminar' : 'Sí, modificar',
          cancelButtonText: 'Cancelar',
        });

      case 'delete':
      case 'success':
      case 'upload':
        return Swal.fire({
          heightAuto: false,
          title: type === 'delete' ? '¡ Eliminado, correctamente !' : type === 'success' ? '¡ Modificado correctamente !' : '¡ Subido correctamente !',
          showCancelButton: false,
          showConfirmButton: false,
          // iconHtml: `<img src = ${happy} alt = "happy face" />`,
          timer: 1000,
          timerProgressBar: true,
          background: '#EAECEF',
        });

      case 'error':
      case 'errorLogin':
        return Swal.fire({
          heightAuto: false,
          title: '¡Error!',
          text: type === 'error' ? `No se puede eliminar porque tiene ${name} asociadas.` : 'Usuario o contraseña incorrectos',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          background: '#EAECEF',
        }).then(() => {});

      case 'updateLoader':
        return Swal.fire({
          heightAuto: false,
          background: '#EAECEF',
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: async () => {
            Swal.showLoading();
            const response = await hookFunction();

            if (response.status === 200) {
              typeSwal('update', '', hookForm, action, path);
              hookForm({ action: 'clear', value: '' }, { form: form, element: '' });
              navigate(path, { replace: true });
            } else {
              typeSwal('error');
            }
          },
        });

      case 'createLoader':
        return Swal.fire({
          heightAuto: false,
          background: '#EAECEF',
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: async () => {
            Swal.showLoading();
            const response = await hookFunction();

            if (response?.status === 201 || response?.status === 200) {
              typeSwal('create', '', hookForm, action, path);
              hookForm({ action: 'clear', value: '' }, { form: form, element: '' });
              navigate(path, { replace: true });
            } else {
              typeSwal('error');
            }
          },
        });
      case 'Contraseña':
      case 'password':
      case 'apiKey':
        Swal.fire({
          title: type === 'password' || type === 'Contraseña' ? 'Contraseña' : 'API Key',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'customConfirmButtonSwalPassword',
          },
          confirmButtonText: `<div class='copyPassword' ><p class='password'>${name}</p><div class='copyImage'><img class='copyImageIcon' src=${iconButton.copy.icon} alt=${iconButton.copy.alt} /></div></div>`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigator.clipboard.writeText(name);
            Swal.fire({
              html: type === 'password' || type === 'Contraseña' ? '¡Contraseña copiada al portapapeles!' : '¡API Key copiada al portapapeles!',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
            });
          }
        });
        break;
      case 'copy':
        navigator.clipboard.writeText(name);
        Swal.fire({
          html: `¡Contraseña ${name} copiada al portapapeles!`,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
        break;
      case 'newPassword':
        return Swal.fire({
          showCloseButton: true,
          heightAuto: false,
          html: `
            <div>

              <style>
                .containerSwal {
                  display: flex;
                  flex-direction: column;
                }

                label {
                  font-size: 16px;
                  margin: 8px 0;
                  text-align: left;
                }

                input {
                  border: none;
                  border-bottom: 1px solid gray;
                  padding: 9px 0;
                }

                input:focus {
                  outline: none;
                }
              
                .input_container {
                  display: flex;
                  flex-direction: column;
                  margin: 10px 30px;
                }

              </style>

              <div class = "containerSwal" >

                <h3>Cambiar contraseña</h3>

                <div class = "input_container" >
                  <label>Nueva contraseña</label>
                  <input id = "password" >
                </div>

                <div class = "input_container" >
                  <label>Repite la nueva contraseña</label>
                  <input id = "password_confirm">
                </div>

              </div>

            </div>

            `,

          preConfirm: () => {
            const new_password = Swal.getPopup().querySelector('#password').value;
            const new_password2 = Swal.getPopup().querySelector('#password_confirm').value;

            if ([new_password, new_password2].includes('')) Swal.showValidationMessage(`Los campos tienen que estar llenos`);

            if (new_password === new_password2 && new_password !== '') {
              hookForm(new_password, name.id);
              return { password: new_password };
            } else {
              Swal.showValidationMessage('Las contraseñas no coinciden');
            }
          },
        }).then((result) => {
          if (result.value === undefined) return;

          if (result.value?.password === undefined) Swal.fire({ text: 'La contraseña no coinciden' });
          else {
            Swal.fire({
              icon: 'success',
              text: `Contraseña de ${name.firstname} modificada`,
              heightAuto: false,
              timer: 2000,
            });
          }
        });
      case 'logout':
        return Swal.fire({
          buttonsStyling: false,
          customClass: {
            confirmButton: 'customConfirmButtonSwal',
            cancelButton: 'customCancelButtonSwal',
            // icon: 'customIconSwal',
          },

          // allowOutsideClick : false,
          allowEscapeKey: false,
          allowEnterKey: false,

          iconHtml: `<img src = ${runIcon} alt = "LogOut menu icon" />`,

          background: 'white',
          reverseButtons: true,

          // padding: "5%",
          heightAuto: true,

          backdrop: `#EAECEF`,

          title: '¿Cerrar sesión? ',
          text: 'Estás a punto de salir de la Wide, asegúrate de guardar todos los cambios.',

          showCancelButton: true,
          confirmButtonText: `Cerrar sesión`,
          cancelButtonText: 'Cancelar',
        });

      default:
        break;
    }
  };

  return {
    typeSwal,
  };
};
