import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateUser } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useUsers from '../../../hooks/useUsers';
import { routesUser, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useDepartments } from '../../../hooks/useDepartments';
import { useAccessLevels } from '../../../hooks/useAccessLevel';
import FallbackRender from '../../../components/FallbackRenderer/FallbackRenderer';
import { ErrorBoundary } from 'react-error-boundary';
import { PageScreen } from '../../../components/PageScreen/PageScreen';
import useNumbering from '../../../hooks/useNumbering';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useStore } from 'react-redux';

const UserScreenForm = ({ crudType }) => {
  const { postCreateUser, userForm, usersFormReducer, updateUser } = useUsers();
  const { getCategoriesHook, categoriesHook } = useOutbound();

  const { getExtensions } = useNumbering();
  const { getUsers, users } = useSettings();
  const { typeSwal } = useSwal();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { getDepartments, departmentsHook } = useDepartments();
  const { getAccessLevels, arrayAccessLevelsHook } = useAccessLevels();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const { getState } = useStore();
  const [docSettings] = useState(getState()['getConfigReducer']);
  // const dispatch = useDispatch();
  // const [checkboxesActives, setCheckboxesActives] = useState([]);

  const data = formCreateUser;

  const { state } = useLocation();

  useEffect(() => {
    getHeadquarter();
    getDepartments();
    getAccessLevels();
    getExtensions();
    getCategoriesHook();
    // dispatch(getSettingsThunk(setLoading));
    // getSettingsData();
    // getCompanyLink();
  }, [docSettings]);

  useEffect(() => {
    userForm(
      { action: 'clearForm' },
      {
        form: 'user',
      }
    );

    if (crudType === 'edit') {
      userForm(
        {
          action: 'id',
          value: location.state?.id,
        },
        { form: 'user', element: 'id' }
      );
      userForm(
        {
          action: 'input',
          value: location.state?.voicemailPIN,
        },
        { form: 'user', element: 'voicemailPIN' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.callForward,
        },
        { form: 'user', element: 'callForward' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.notAnswer,
        },
        { form: 'user', element: 'notAnswer' }
      );

      userForm(
        {
          action: 'toggle',
          value: location.state?.isCallForward,
        },
        { form: 'user', element: 'isCallForward' }
      );

      userForm(
        {
          action: 'toggle',
          value: location.state?.isNotAnswer,
        },
        { form: 'user', element: 'isNotAnswer' }
      );

      userForm(
        {
          action: 'select-option',
        },
        { form: 'user', element: 'SIPExtension' },
        {
          value: location.state?.SIPExtensionId,
          //  usersFormReducer.SIPExtension || location.state?.SIPExtensionId ||
          label: location.state?.SIPExtensionId,
          //  usersFormReducer.SIPExtension || location.state?.SIPExtensionId ||
        }
      );
      //   : null;

      userForm(
        {
          action: 'select-option',
        },
        {
          form: 'user',
          element: 'companyId',
        },
        {
          value: state?.companyId,
          label: state?.Company,
        }
      );

      // userForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'user',
      //     element: 'department',
      //   },
      //   {
      //     label: state?.department,
      //     value: String(state?.departmentId),
      //   }
      // );

      userForm(
        {
          action: 'select-option',
          value: location.state?.companyId,
          label: location.state?.Company,
        },
        {
          form: 'user',
          element: 'Company',
        }
      );

      let newDept = [];

      if (location.state?.departmentId) {
        const found = departmentsHook.find((itemDept) => itemDept.id === location.state?.departmentId);
        found ? newDept.push(found) : null;
      }

      userForm(
        {
          action: 'select-option',
          value: newDept[0]?.id,
          label: newDept[0]?.name,
        },
        {
          form: 'user',
          element: 'departmentId',
        }
      );

      // userForm(
      //   {
      //     action: 'select-option',
      //     value: location.state?.departmentId.value,
      //     label: location.state?.departmentId.label,
      //   },
      //   {
      //     form: 'user',
      //     element: 'Department',
      //   }
      // );
      userForm(
        {
          action: 'select-option',
          value: location.state?.category,
          label: location.state?.category,
        },
        {
          form: 'user',
          element: 'categories',
        }
      );
      userForm(
        {
          action: 'select-option',
          value: location.state?.accesslevelId,
        },
        {
          form: 'user',
          element: 'accesslevelId',
        }
      );

      userForm(
        {
          action: 'select-option',
          value: location.state?.accesslevelId,
          label: location.state?.AccessLevel,
        },
        {
          form: 'user',
          element: 'AccessLevel',
        }
      );

      userForm(
        {
          action: 'select-option',
          value: location.state?.roleId,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'role',
        }
      );

      location.state?.captureId.forEach((itemCapture) => {
        const newCapture = departmentsHook.find((itemCapt) => itemCapt.id === itemCapture);

        newCapture
          ? userForm(
              {
                action: 'multiselect',
                value: newCapture.id,
                label: newCapture.name,
              },
              {
                form: 'user',
                element: 'captureId',
              }
            )
          : null;
      });

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isvoicemailEmail,
          //  label: location.state?.type,
        },
        {
          form: 'user',
          element: 'isvoicemailEmail',
        }
      );

      Object.keys(location.state).map((itemForm) =>
        data.tabs.map((itemTab) =>
          itemTab.steps.map((itemStep) =>
            itemStep.input.map((item3) => {
              if (
                item3.ref.element !== 'accesslevelId' &&
                item3.ref.element !== 'role' &&
                item3.ref.element !== 'departmentId' &&
                item3.ref.element !== 'Department' &&
                item3.ref.element !== 'captureId' &&
                item3.ref.element !== 'companyId' &&
                item3.ref.element !== 'Company' &&
                item3.ref.element !== 'voicemailPIN' &&
                item3.ref.element !== 'SIPExtension' &&
                itemForm === item3.ref.element
              ) {
                return userForm(
                  {
                    action: item3.cardType,
                    value: location.state[itemForm],
                    label: location.state[itemForm],
                  },
                  {
                    form: item3.ref.form,
                    element: item3.ref.element,
                  }
                );
              } else {
                return null;
              }
            })
          )
        )
      );
    }
  }, []);

  const getArray = () => {
    let newArrayExt = [];
    if (docSettings) {
      const extIni = docSettings?.extension_start;
      const extEnd = docSettings.extension_end;

      for (let i = extIni; i <= extEnd; i++) {
        const newValue = { value: i + 1, label: i + 1 };
        const found = users.some((e) => Number(e.SIPExtensionId) === i);

        if (!found) {
          newArrayExt.push(newValue);
        }
      }

      if (location.state?.Extension) {
        const thisDocExt = { value: location.state.SIPExtensionId, label: location.state.SIPExtensionId };

        !newArrayExt.includes(thisDocExt) ? newArrayExt.push(thisDocExt) : null;
      }
    }
    return newArrayExt;
  };

  useEffect(() => {
    setTimeout(() => {
      if (typeof usersFormReducer === 'object' && arrayHeadquarterHook.length > 0) {
        //  data.tabs.map((itemTab) =>
        //   itemTab.steps.map((itemStep) =>
        //     itemStep.input.map((item3) => {
        data.tabs.map((itemTab) =>
          itemTab.steps.map((itemStep) => {
            itemStep.input.map((item2) => {
              if (item2.ref.element === 'companyId') {
                arrayHeadquarterHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const company = {
                    label: item3.name,
                    value: item3.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === company.value);
                  if (!found && company.value !== undefined) {
                    item2.options.push(company);
                  }
                });
              }
              if (item2.ref.element === 'categories') {
                item2.options = [];
                categoriesHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const itemCat = {
                    value: item3.id,
                    label: item3.description,
                  };
                  const found = item2.options.some((e) => e.value === itemCat.value);
                  if (!found && itemCat.value !== undefined) {
                    item2.options.push(itemCat);
                  }
                });
              }

              if (item2.ref.element === 'accesslevelId') {
                arrayAccessLevelsHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const accesslevel = {
                    label: item3.name,
                    value: item3.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === accesslevel.value);
                  if (!found && accesslevel.value !== undefined) {
                    item2.options.push(accesslevel);
                  }
                });
              }

              if (item2.ref.element === 'departmentId') {
                item2.options = [];
                departmentsHook?.forEach((itemDepart) => {
                  const found = item2.options.find((e) => e.value === location.state?.departmentId);

                  if (!found) {
                    item2.options.push({ value: itemDepart.id, label: itemDepart.name });
                  }
                });
              }

              if (item2.ref.element === 'captureId') {
                item2.options = [];
                departmentsHook?.forEach((itemDepart) => {
                  const found = item2.options.find((e) => e.value === location.state?.departmentId);

                  if (!found) {
                    item2.options.push({ value: itemDepart.id, label: itemDepart.name });
                  }
                });
              }

              // if (item2.ref.element === 'captureId') {
              //   item2.options = [];
              //   departmentsHook?.forEach((itemDepart) => {
              //     const found = item2.options.find((e) => e.value === usersFormReducer.captureId?.value);

              //     if (!found) {
              //       item2.options.push({ value: itemDepart.id, label: itemDepart.name });
              //     }
              //   });
              // }

              if (item2.ref.element === 'SIPExtension') {
                item2.options = [];
                const newArrayExt = getArray();

                item2.options = newArrayExt;
              }
            });
          })
        );
      }

      // if (typeof arrayAccessLevelsHook === 'object' && arrayAccessLevelsHook?.length > 0) {
      //   data.tabs.map((itemTab) =>
      //     itemTab.steps.map((item) => {
      //       item.input.map((item2) => {
      //         if (item2.ref.element === 'accesslevelId') {
      //           arrayAccessLevelsHook.forEach((item3) => {
      //             if (item3.name === null) {
      //               item3.name = 'not defined';
      //             }
      //             const accesslevel = {
      //               label: item3.name,
      //               value: item3.id?.toString(),
      //             };
      //             const found = item2.options.some((e) => e.value === accesslevel.value);
      //             if (!found && accesslevel.value !== undefined) {
      //               item2.options.push(accesslevel);
      //             }
      //           });
      //         }
      //         if (item2.ref.element === 'categories') {
      //           item2.options = [];
      //           categoriesHook.forEach((item3) => {
      //             if (item3.name === null) {
      //               item3.name = 'not defined';
      //             }
      //             const itemCat = {
      //               value: item3.id,
      //               label: item3.description,
      //             };
      //             const found = item2.options.some((e) => e.value === itemCat.value);
      //             if (!found && itemCat.value !== undefined) {
      //               item2.options.push(itemCat);
      //             }
      //           });
      //         }

      // if (item2.ref.element === 'departmentId') {
      //   item2.options = [];
      //   departmentsHook?.forEach((itemDepart) => {
      //     const newDepart = {
      //       label: itemDepart?.name,
      //       value: itemDepart?.id,
      //     };

      //     const found = item2.options.some((e) => e.value === newDepart.value);

      //     if (!found && newDepart.value !== undefined) {
      //       item2.options.push(newDepart);
      //     }
      //   });
      // }

      // if (item2.ref.element === 'captureId') {
      //   item2.options = [];
      //   departmentsHook.forEach((item3) => {
      //     if (item3.name === null) {
      //       item3.name = 'not defined';
      //     }
      //     const itemCapture = {
      //       value: item3.id,
      //       label: item3.name,
      //     };
      //     const found = item2.options.some((e) => e.value === itemCapture.value);
      //     if (!found && itemCapture.value !== undefined) {
      //       item2.options.push(itemCapture);
      //     }
      //   });
      // }
      // });
      // })
      // );
      // }

      if (typeof usersFormReducer === 'object') {
        if (crudType === 'edit' && (usersFormReducer.firstname === null || usersFormReducer.firstname !== location.state?.firstname)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.tabs.map((itemTab) =>
            itemTab.steps.map((item) => {
              item.input.map((item2) => {
                switch (item2.ref.element) {
                  case 'firstname':
                    item2.defaultValues = usersFormReducer.firstname;
                    break;
                  case 'lastname':
                    item2.defaultValues = usersFormReducer.lastname;
                    break;
                  case 'secondname':
                    item2.defaultValues = usersFormReducer.secondname;
                    break;
                  case 'email':
                    item2.defaultValues = usersFormReducer.email;
                    break;
                  case 'password':
                    item2.defaultValues = usersFormReducer.password;
                    break;
                  case 'accesslevelId':
                    location.state?.accesslevelId &&
                      item2.options.filter((item3) => {
                        if (item3.value === usersFormReducer.accesslevelId.toString()) {
                          return (item2.defaultValues = item3);
                        }
                        return false;
                      });
                    break;
                  case 'companyId':
                    item2.options.filter((item3) => {
                      if (item3.value === usersFormReducer.companyId.toString()) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    // item2.defaultValues = usersFormReducer.companyId;
                    break;
                  case 'departmentId':
                    item2.defaultValues = usersFormReducer?.departmentId;
                    break;
                  case 'captureId':
                    item2.defaultValues = usersFormReducer?.captureId;
                    break;
                  case 'role':
                    item2.options.filter((item3) => {
                      if (location.state?.roleId === Number(item3.value)) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'Company':
                    item2.options.forEach((item3) => {
                      if (item3.value === usersFormReducer.Company.id.toString()) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                    break;
                  case 'AccessLevel':
                    item2.defaultValues = usersFormReducer.AccessLevel;
                    break;
                  // case 'isSIPExtension':
                  //   item2.defaultValues = location.state?.isSIPExtension;
                  //   break;
                  case 'SIPExtension':
                    // location.state?.Extension &&
                    //   item2.options?.filter((item3) => {
                    //     if (item3.value === location.state?.SIPExtension) {
                    //       return (item2.defaultValues = item3);
                    //     }
                    //     return false;
                    //   });
                    item2.defaultValues = usersFormReducer.SIPExtension;
                    break;
                  case 'isVoicemail':
                    item2.defaultValues = usersFormReducer.isVoicemail;
                    break;
                  case 'isNotAnswer':
                    item2.defaultValues = usersFormReducer.isNotAnswer;
                    break;
                  case 'isCallForward':
                    item2.defaultValues = usersFormReducer.isCallForward;
                    break;
                  case 'isvoicemailEmail':
                    item2.defaultValues = usersFormReducer.isvoicemailEmail;
                    break;
                  case 'isTeams':
                    item2.defaultValues = usersFormReducer.isTeams;
                    break;
                  case 'isRainbow':
                    item2.defaultValues = usersFormReducer.isRainbow;
                    break;
                  case 'categories':
                    item2.defaultValues = usersFormReducer.category;
                    break;
                  default:
                    item2.defaultValues = usersFormReducer[item2.ref?.element];
                    break;
                }
                return item2;
              });
              return item;
            })
          );
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 1000);
  }, [loadingCounter]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesUser.usersList}/${routesUser.userCreate}`, 'user', postCreateUser);
      //
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.users}`, 'user', updateUser);
    }
  };

  return (
    <ErrorBoundary fallbackRender={FallbackRender}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PageScreen
          reducerForm={formCreateUser}
          arrScreen={['Usuario']}
          dataSave={dataSave}
          data={data}
          onSubmit={onSubmit}
          render={dataSave}
          setRender={setDataSave}
          form={userForm}
          crudType={crudType}
          maxwidth={'800px'}
          // checkboxesActives={checkboxesActives}
          // setCheckboxesActives={setCheckboxesActives}
          // isEditMode={isEditMode}
          // setIsEditMode={setIsEditMode}
        />
      )}

      {/* <FormScreen data={data} reducerForm={usersFormReducer} arrScreen={['Usuario']} onSubmit={onSubmit} form={userForm} dataSave={dataSave} crudType={crudType} /> */}
    </ErrorBoundary>
  );
};

UserScreenForm.propTypes = { crudType: string, row: string };

export default UserScreenForm;
