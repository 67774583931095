import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateDomains } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { string } from 'prop-types';

export const DomainsForm = ({ crudType }) => {
  const { settingsForm, getDomains, createDomain, updateDomain, domainsForm } = useSettings();

  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateDomains;

  useEffect(() => {
    settingsForm(
      { action: 'clearForm' },
      {
        form: 'domains',
      }
    );
    if (crudType === 'edit') {
      settingsForm(
        {
          action: 'id',
          value: location.state?.id,
        },
        { form: 'domains', element: 'id' }
      );
      settingsForm(
        {
          action: 'input',
          value: location.state.domain,
        },
        { form: 'domains', element: 'domain' }
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit' && (location.state.domain === '' || location.state.domain !== domainsForm.domain)) {
        setLoadingCounter(loadingCounter + 1);
      } else if (crudType === 'edit') {
        data.steps.map((item) => {
          item.input.map((item2) => {
            switch (item2.ref.element) {
              case 'domain':
                item2.defaultValues = location.state.domain;
                break;
              default:
                break;
            }
            return item2;
          });
          return item;
        });
        setLoading(false);
      } else if (crudType === 'create') {
        setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  });

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', settingsForm, getDomains, `/${routesWide.settings}/${routesSettings.domains}`, 'domains', createDomain);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', settingsForm, getDomains, `/${routesWide.settings}/${routesSettings.domains}`, 'domains', updateDomain);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen arrScreen={['Dominio']} data={data} onSubmit={onSubmit} form={settingsForm} dataSave={dataSave} crudType={crudType} />}</>;
};

DomainsForm.propTypes = { crudType: string };
