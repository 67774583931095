import styledComponents from 'styled-components';

export const AlertsWrapper = styledComponents.div`
   box-sizing: border-box;
  height: 100%;
  padding-left: 70px;
  padding-top: 70px;

  .contain__table {
    box-sizing: border-box;
    height: calc(100% - 55px);
    position: relative;
    display: flex;
    /* width: auto; */
    margin-top: 1px;
    left: 5px;
  }
  

    .contain__cardGrid {
        // height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 20px 36px 0;
        //transition: all 1s easy-out;
    }
`;
