import React from 'react';
// import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
import { menuCallRegister } from '../../data/dataCallRegister';
// import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { CallRegisterNavigation } from '../../navigation/CallRegisterNavigation/CallRegisterNavigation';
import { CallRegiserScreenWrapper } from './CallRegisterScreen.style';

export const CallRegisterScreen = (setIsClosed) => {
  return (
    <CallRegiserScreenWrapper>
      {/* <Header text={menuNavLeft.menuTop[4].text} /> */}
      <MenuTop menu={menuCallRegister} setIsClosed={setIsClosed} />
      <CallRegisterNavigation />
    </CallRegiserScreenWrapper>
  );
};
