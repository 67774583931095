import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const NavButtonsWrapper = styled.div`
  width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown__add {
    background-color: ${colors.primaryColor};
    border: 1px solid lightgrey;
    color: white;
    border-radius: 10px;
    min-width: unset;
  }

  .dropdown__add__button {
    background-color: ${colors.white};
    color: black;
  }

  .iconActionMenu__close {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: flex-start;
    color: ${colors.black};
    border-radius: 10px;
    background-color: ${colors.white};

    p {
      color: black;
    }

    &:hover {
      border-radius: 10px;
      color: ${colors.white};
      background-color: ${colors.blue};
    }
  }

  .icon-separator {
    border-bottom: 2px solid #373c47;
    max-height: 2px;
    padding: 0;
  }

  .icon-plus2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.white};
    min-width: 40px;
    color: white;

    &:hover {
      background-color: #373c47;
      color: ${colors.white};
    }

    gap: 10px;
  }

  .icon-plus {
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    font-size: 40px;
    font-weight: 500;
    z-index: 900;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: 2px solid white;

    &:hover {
      cursor: pointer;
      border: 2px solid ${colors.blue};
    }
  }

  .icon-edit {
    ${'' /* color: ${colors.white}; */}
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 40px;
    font-weight: 500;
    z-index: 900;

    &:hover {
      cursor: pointer;
      border-radius: 10px;
      ${'' /* background-color: ${colors.blue}; */}
      color: ${colors.white};
    }
  }

  .right {
    height: 30px;
    width: 50%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    ${'' /* margin-right: 20px; */}
  }

  .custom-file-input {
    background-color: ${colors.greyButton};
    border-radius: 8px;
    border: 0px;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black};
  }

  .changeView {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 40px;
    cursor: pointer;
    color: ${colors.grey};
    // //transition: all 0.3s;

    &:hover {
      background-color: ${colors.primaryColor};
      // //transition: all 0.3s;
      color: white;
    }
    &__card,
    &__table {
      margin-right: 4px;

      font-size: ${newFontSize.button};
      font-weight: ${weight.medium};
    }

    &__line {
      margin-right: 4px;
    }

    &__isActive {
      margin-right: 4px;
      cursor: pointer;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.medium};
    }
  }
`;
