import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { CardGird } from '../../../components/CardGrid/CardGird';

import { HideColumn } from '../../../components/HideColumn/HideColumn';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterApis, dataScreenApi } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
// import useView from '../../../hooks/useView';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { string } from 'prop-types';

import { ApiScreenWrapper } from './ApiScreen.style';
import { NavTable } from '../../../components/NavTable/NavTable';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';

export const ApiScreen = () => {
  const { api, getApi, loading } = useSettings();

  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });
  const navigate = useNavigate();

  const [checkBoxesActives, setCheckboxesActives] = useState([]);
  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound } = dataScreenApi;

  useEffect(() => {
    getApi();
  }, []);

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.settings}/${routesSettings.createApi}`, {});
  };

  const DATA = api.map(({ apiKey, id, userId, name }) => ({
    name,
    apiKey,
    id,
    userId,
  }));

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'API Key',
      accessor: 'apiKey',
      disableSortBy: true,
      Cell: ({ row }) => <HideColumn row={row} id={row.original.id} data={row.original.apiKey} text={'apiKey'} />,
    },
  ];

  const dataExport = api.map(({ name, apikey }) => ({
    name,
    apikey,
  }));

  const template = [
    {
      name: '',
    },
  ];

  // const dataCard = () => {
  //   if (search.length > 0) {
  //     return search.map(({ apiKey, id, userId, name }) => ({
  //       title: apiKey,
  //       subtitle: name,
  //       apiKey,
  //       id,
  //       userId,
  //       name,
  //     }));
  //   } else {
  //     return api.map(({ apiKey, id, userId, name }) => ({
  //       title: apiKey,
  //       subtitle: name,
  //       apiKey,
  //       id,
  //       userId,
  //       name,
  //     }));
  //   }
  // };

  // const dataToCards = dataCard();
  const handleOpenFormEdit = (item) => {
    navigate('/preferences/company/update', {
      state: item.original,
    });
  };

  return (
    <ApiScreenWrapper>
      <div className="api-screen">
        <div className="contain__buttons">
          <NavButtons
            optionButtons={{
              add: {
                position: 'left',
                function: handleOpenFormCreate,
              },
              search: {
                position: 'right',
                data: DATA,
                columns: COLUMNS,
              },
              filter: {
                position: 'right',
              },
              file: {
                position: 'right',
                data: dataExport,
                template,
              },
            }}
            // importFile={handleUploadFile}
            setSearch={setSearch}
            setIsFilter={setIsFilter}
            isFilter={isFilter}
            checkboxesActives={checkBoxesActives}
            setCheckboxesActives={setCheckboxesActives}
          />
          {!api[0]?.status ? (
            <div className={'contain__menuFilter'}>
              <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterApis} setSearch={setSearch} />
            </div>
          ) : null}
          <NavTable checkboxesActives={checkBoxesActives} totalDocs={api.length} />
        </div>
        {loading ? (
          <LoaderSpinner />
        ) : search[0]?.notFound ? (
          <div className="contain__notFound">
            <h4>{search[0].notFound}</h4>
          </div>
        ) : api[0]?.status ? (
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        ) : (
          <>
            <div className="contain__table">
              <TableInfo
                COLUMNS={COLUMNS}
                DATA={search.length === 0 ? DATA : search}
                format={'list'}
                deleteFunction={() => {}}
                updateFunction={handleOpenFormEdit}
                onClick={handleOpenFormEdit}
                setIsBottom={setIsBottom}
                isOptions={false}
                isCheckbox={true}
                checkboxesActives={checkBoxesActives}
                setCheckboxesActives={setCheckboxesActives}
                setDataCard={() => {
                  false;
                }}
              />
            </div>
          </>
        )}
      </div>
    </ApiScreenWrapper>
  );
};

ApiScreen.propTypes = { row: string };
