import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize } from '../../theme/fonts';

export const NavMenuWrapper = styled.div`
  height: 100%;
  // width: ${(p) => (!p.isMenuOpen ? '65px' : '100%')};
  //transition: all 1.5s;

  .contain__navLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .hamburguer__container {
    display: flex;
    justify-content: center;
    border: 0;
    justify-content: ${(p) => (!p.isMenuOpen ? '' : 'flex-start')};
    width: 100%;
    height: 68px;
    align-items: center;
    padding-left: ${(p) => (!p.isMenuOpen ? '' : '18px')};
    margin-bottom: 50px;

    &:hover {
      cursor: pointer;
      // color: ${colors.black};
      // border-radius: 15px 15px 0 0;

      .item__text {
        // color: ${colors.primaryColor};
      }

      .item__image {
        height: 40px;
        width: 40px;
        color: ${colors.primaryColor};
      }
    }
  }

  .iconActionMenu__Pin__close {
    width: 40px;
    height: 40px;
  }

  .iconActionMenu__Pin__open {
    width: 40px;
    height: 40px;
  }

  .navleft__bottom {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.greyLine};
    // border-radius: 0 0 15px 15px;
  }

  .hamburguer__icon {
    width: 35px;
    margin-top: 2px;
    color: ${colors.grey};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;

    &:hover {
      opacity: 1;
      color: ${colors.white};
    }
  }
  .hamburguer__text {
    width: 100%;
    text-align: left;
    padding-left: 15px;
    font-size: ${newFontSize.body};
    color: ${colors.greyLine};
    &:hover {
      opacity: 1;
      color: ${colors.white};
    }
  }

  // .iconActionMenu__close {
  //   width: 35px;
  //   margin-top: 2px;
  //   color: ${colors.grey};
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   opacity: 0.8;

  //   &:hover {
  //     opacity: 1;
  //     color: ${colors.white};
  //   }
  // }

  // .iconActionMenu__close {
  //   width: 35px;
  //   display: flex;
  //   justify-content: flex-start;
  //   margin-top: 8px;
  //   color: ${colors.grey};
  //   opacity: 0.8;

  //   &:hover {
  //     opacity: 1;
  //     color: ${colors.white};
  //   }
  // }

  .iconActionMenu {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    &__contain {
      height: 30px;
      transform: rotate(90deg);
    }
  }

  .container__top {
    width: 100%;
    display: flex;
    flex-direction: column;
    // color: ${colors.white};
    // border-radius: 10px;
    // //transition: all 1.5s;
    // border: 1px solid ${colors.greyLine};
    justify-content: center;
    // padding-top: 45px;
    gap: 10px;
  }

  .navleft-link {
    height: 65px;
    width: 100%;
  }

  .container__bottom {
    width: 100%;
    height: 100%;
    // border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primaryColor};
    border: 1px solid ${colors.greyLine};
    margin-top: 25px;

    .item__image {
      height: 30px;
      // border-radius: 10px;
      color: ${colors.primaryColor};
    }

    .item__image__bottom {
      height: 30px;
      width: 100%;

      // border-radius: 15px;

      &:hover {
        color: ${colors.black};
        // border-radius: 15px;
      }
    }
  }

  .item__contain__bottom2 {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px;

    &:hover {
      heigth: 100%;
      width: 100%;
      color: ${colors.black};
      background-color: ${colors.white};
      // border-radius: 10px;

      .item__text {
        width: 100%;
      }

      .item__image {
        height: 40px;
        width: 100%;
        color: darkgrey;
        // border-radius: 10px;
      }
    }
  }

  .item__contain {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-radius: 10px;
    color: darkgrey;

    &:hover {
      cursor: pointer;
      // height: 100%;
      color: white;
      // border-radius: 0 0 15px 15px;
      opacity: 0.9;
      background-color: ${colors.grey};
      margin: auto;
      // border-radius: 10px;

      .item__text {
        width: 100%;
        // color: ${colors.white};
      }

      .item__image {
        color: ${colors.white};
        // border-radius: 10px;
      }
    }

    .item__image {
      // padding-left: ${(p) => (!p.isMenuOpen ? '' : '20px')};
      height: 35px;
      width: 100px;
      // border-radius: 10px;
      color: darkgrey;
    }
  }

  .item__text {
    width: 100%;
    z-index: ${(p) => (!p.isMenuOpen ? -1 : 10)};
    // color: ${colors.white};
    // font-size: ${newFontSize.h3};
    // font-weight: bold;
    // padding-left: ${(p) => (!p.isMenuOpen ? '5px' : '10px')};
    // margin-right: 10px;
  }

  .item__divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    // div {
    height: 1px;
    background: ${colors.greyLine};
    width: 100%;
    margin: auto;
    // }
  }

  .item__active {
    height: 100%;
    width: 100%;
    background-color: ${colors.blue};
    color: ${colors.white};
    // //transition: all 1.5s;
    // padding-left: ${(p) => (!p.isMenuOpen ? '' : '10px')};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    // border-radius: 10px;
    font-weight: bold;

    .item__image {
      // padding-left: ${(p) => (!p.isMenuOpen ? '' : '20px')};
      height: 40px;
      width: 100px;
      color: ${colors.white};
    }

    .item__text {
      width: 100%;
      color: ${colors.white};
      // font-size: ${newFontSize.h3};
      // font-family: ${fontFamily.font};
      font-weight: bold;
      // opacity: ${(p) => (!p.isMenuOpen ? 0 : 1)};
      // //transition: opacity 1s;
      // transition-delay: ${(p) => (!p.isMenuOpen ? 0 : 0.9)};
      z-index: ${(p) => (!p.isMenuOpen ? -1 : 10)};
    }
  }
`;
