import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { LoginScreen } from '../../screens/LoginScreen/LoginScreen';
import StaticScreen from '../../screens/StaticScreen/StaticScreen';
import { StaticNavigationScreenWrapper } from './StaticNavigation.style';
import { useStore } from 'react-redux';

// import { ToastContainer } from 'react-toastify';

export const StaticNavigation = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(JSON.parse(localStorage.getItem('tokenStorage')) || false);

  const { getState } = useStore();
  const { isMenuOpen } = getState()['system'];

  const setAuth = (value) => {
    setIsAuthenticated(value);
  };

  useEffect(() => {
    localStorage.setItem('tokenStorage', JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log('stepf handleBeforeUnload');
  //     // Perform actions before the component unloads
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <StaticNavigationScreenWrapper className="App__container" isMenuOpen={isMenuOpen}>
      <BrowserRouter>
        <Routes>
          <Route path={'/*'} element={isAuthenticated === false ? <Navigate to={'/login'} replace /> : <StaticScreen />} />
          <Route path="/login" element={<LoginScreen setAuth={setAuth} />} />
        </Routes>
      </BrowserRouter>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={0}
        className={'toaster__modal'}
      /> */}
    </StaticNavigationScreenWrapper>
  );
};
