import styled from 'styled-components';
// import { colors } from '../../theme/colors.js';

export const WideWrapper = styled.div`
  box-sizing: border-box;
  ${'' /* height: 100%; */}
  width: 100%;
  height: 100%;
  ${'' /* position: fixed; */}
  ${'' /* background-color: ${colors.primaryColor}; */}
  ${
    '' /* .widenav__container{
  } */
  };
`;
