import React from 'react';
import { useDropzone } from 'react-dropzone';
import { dataDropzone } from '../../data/dataDropzone';

import { ContainData, MyDropzoneWrapper } from './MyDropzone.style';
import { func, object } from 'prop-types';

export const MyDropzone = ({ item, inputValue, accept, setInputValue, form }) => {
  const { dropzone } = dataDropzone;

  const handleDropzoneChange = (file, item) => {
    setInputValue((prevState) => ({
      ...prevState,
      [item.ref.element]: file,
    }));
    let action = { action: 'dropzone', value: file };
    form(action, item.ref);
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    // fileRejections,
    //isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      handleDropzoneChange(acceptedFiles, item);
    },
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path}>
      {file.path}
      {/* - {file.size} bytes */}
    </span>
  ));

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //     <span key={file.path}>
  //         {file.path}
  //         {/* - {file.size} bytes */}
  //         <span>
  //             {errors.map((e) => (
  //                 <span key={e.code}>{e.message}</span>
  //             ))}
  //         </span>
  //     </span>
  // ))

  return (
    <>
      <MyDropzoneWrapper isDragAccept={isDragAccept} isDragReject={isDragReject}>
        <div className="contain__dropzone">
          <div {...getRootProps({})}>
            <input {...getInputProps()} />
            {inputValue?.file?.label ? <p>{dropzone.textDropzone}</p> : <p>{dropzone.textDropzone}</p>}
          </div>
        </div>

        {inputValue?.file?.label ? (
          <ContainData>
            <div className="dropzone__file">
              <span className="dropzone__current">{dropzone.textCurrentFile}</span>
              <span className="dropzone__item">{inputValue.file.label}</span>
            </div>
          </ContainData>
        ) : (
          <ContainData>
            <div className="dropzone__file">
              <span className="dropzone__success">{dropzone.textDropzoneSuccess}</span>
              {acceptedFileItems.length > 0 ? <span className="dropzone__item">{acceptedFileItems}</span> : <span className="dropzone__item"> &nbsp; </span>}
            </div>
            {/* <div className="dropzone__file">
                            <span className="dropzone__error">
                                {dropzone.textDropzoneError}
                            </span>
                            <span className="dropzone__item">
                                {fileRejectionItems}
                            </span>
                        </div> */}
          </ContainData>
        )}
      </MyDropzoneWrapper>
    </>
  );
};

MyDropzone.propTypes = { item: object, inputValue: object, accept: object, setInputValue: func, form: func };
