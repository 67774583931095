import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserScreenForm from '../../screens/UsersScreen/UsersScreen/UserScreenForm';
import UsersScreen from '../../screens/UsersScreen/UsersScreen/UsersScreen';
import { routesUser } from '../routes';

const UsersNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersScreen />} />
      <Route path={routesUser.usersList} element={<UsersScreen />} />
      <Route path={routesUser.userCreate} element={<UserScreenForm crudType={'create'} />} />
      <Route path={routesUser.userUpdate} element={<UserScreenForm crudType={'edit'} />} />
    </Routes>
  );
};

export default UsersNavigation;
