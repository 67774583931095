import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const UsersScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: -webkit-fill-available;
  position: fixed;
  left: 70px;
  top: 70px;

  .song-waiting {
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .modal__form__container {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: ${colors.primaryColor};
    top: 0;
    z-index: 9;
    opacity: 1;
  }

  ${
    '' /* .contain__select {
    width: 50%;
  } */
  }

  .modal__form__body {
    width: 700px;
    height: auto;
    position: relative;
    background-color: white;
    top: 200px;
    margin: auto;
    z-index: 9;
    opacity: 1;
    border-radius: 15px 15px 15px 15px;

    .form__title {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      text-align: left;
      color: #0c3fc4;
      font-size: 14px;
      font-weight: bold;
      top: 50px;
      margin: auto;
      width: 100%;
      background-color: ${colors.primaryColor};
      border-radius: 10px 10px 0 0px;
      padding: 5px;
    }

    .title__Form_title {
      color: white;
    }
    .stepForm__contain-typeInput {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 8px;

      .stepForm__toggle {
        grid-column: span 4 / span 4;
      }

      .basicRow {
        grid-column: span 1;
      }

      .contain__select {
        grid-column: span 3;
        width: 100%;
      }
    }

    .form__body {
      margin-top: 0;
      top: 0;

      .icon__Form_title {
        color: white;
      }

      .contain__button-save {
        opacity: 1;
        width: 100%;
        height: 45px;
        position: relative;
        box-sizing: border-box;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        top: -180px;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 10px;
        color: white;

        button {
          color: lightgrey;
          width: 75px;
          height: 35px;
          background-color: #29903b;
          border-radius: 10px;
          ${'' /* width: 120px; */}
          font-size: 14px;
          font-weight: bold;
          margin-right: 20px;
        }

        button:hover {
          color: white;
        }

        .cancel_button {
          background-color: ${colors.primaryColor};
          height: 35px;
          border: 1px solid #474849;

          &:hover {
            color: white;
            background-color: #cd3434;
          }
        }
      }
    }
  }

  .contain__menuFilter {
    height: calc(100% - 54px);
    background-color: ${colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
  }
`;
