import styled from 'styled-components';
import { colors } from '../../theme/colors.js';
import { newFontSize, weight } from '../../theme/fonts.js';

export const NotFoundWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${'' /* position: fixed; */}
  // display: grid;
  background-color: ${colors.primaryColor};
  // grid-template-columns: 1fr 2fr 1fr;
  // grid-template-rows: 1fr 350px 1fr;

  .contain-notFound {
    border-radius: 15px;
    height: 100%;
    grid-column-start: 2;
    grid-row-start: 2;
    display: grid;
    grid-template-rows: 0fr 1fr 4fr;
    justify-items: center;
    position: relative;
    top: 50px;
    ${
      '' /* position: fixed;
    top: 50px; */
    }
    background-color: ${colors.backgroundColor};
  }

  .contain-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    // background-color: ${colors.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contain-title {
    align-self: center;
    text-align: center;

    h4 {
      margin: 0;
      font-size: ${newFontSize.h2};
      font-weight: ${weight.medium};
      color: ${colors.black};
    }
  }
  .contain-subtitle {
    align-self: start;
    text-align: center;

    p {
      margin: 0;
      font-size: ${newFontSize.h3};
      font-weight: ${weight.medium};
      color: ${colors.black};
    }
  }
  .contain-button {
    justify-self: stretch;
  }
`;
