import React, { useEffect } from 'react';
import Select from 'react-select';
import { useHeadquarter } from '../../hooks/useHeadquarter';
import { useValidationForm } from '../../hooks/useValidationForm';
import { SelectWrapper } from './MySelect.style';
import { array, bool, func, number, object, string } from 'prop-types';

export const MySelect = ({
  item,
  options,
  placeholder,
  inputRef,
  defaultValue,
  isMulti,
  isSearchable,
  form,
  setInputValue,
  setErrorInput,
  setValidationNormalInput,
  validationNormalInput,
  allSteps,
  idPage,
  destinationOpen,
  isDisabled,
  setValidateReducer,
  reducerForm,
  firstData,
  value,
}) => {
  const { getHeadquarter, companyLink } = useHeadquarter();
  const { validationForm } = useValidationForm();

  useEffect(() => {
    getHeadquarter();
  }, []);

  try {
    const handleSelectChange = (inputValue, action, item) => {
      if (reducerForm !== undefined) {
        item.idDestination = destinationOpen === undefined ? null : destinationOpen;
        item.idPage = idPage;
        item.action = action.action;

        let responseReducerValidate = validationForm(reducerForm, firstData, item);
        responseReducerValidate && setValidateReducer && setValidateReducer(responseReducerValidate);
      }

      // assocInput.defaultValues = [
      //   departments.map(() => ({
      //     value: departments[0].id,
      //     label: departments[0].name,
      //   })),
      // ];
      // assocInput.action = 'clear';
      // setInputValue((prevState) => ({
      //   ...prevState,
      //   [assocInput.ref.element]: assocInput.defaultValues,
      // }));
      // handleSelectChange(null, assocInput, assocInput);

      // const newItemCapture = {
      //   action: item.action,
      //   element: assocInput.ref.element,
      //   form: 'user',
      //   idDestination: item.idDestination,
      //   idPage: item.idPage,
      //   type: 'associatedData',
      // };
      // // action.cardType = 'multiselect';
      // form(action, newItemCapture, assocInput.defaultValues);
      // });
      if (inputValue?.length > 0 || action.action === 'clear') {
        if (action.action === 'remove-value' || action.action === 'clear') {
          setInputValue((prevState) => ({
            ...prevState,
            [item.element]: inputValue,
          }));
          // action.cardType = 'multiselect';
          form(action, item, inputValue);
        }

        Array.isArray(inputValue)
          ? inputValue.forEach((input) => {
              const newState = item.ref?.element ? item.ref?.element : item.element;
              setInputValue((prevState) => ({
                ...prevState,
                [newState]: { value: Number(input?.value), label: input?.label },
              }));
            })
          : setInputValue((prevState) => ({
              ...prevState,
              [item.element]: inputValue,
            }));
      }

      if (inputValue?.label === '' || inputValue?.length === 0 || inputValue === null) {
        setErrorInput(true);
        setValidationNormalInput((prevState) => [...prevState, item.element]);
      }
      if (inputValue?.length === 0) {
        setInputValue((prevState) => ({
          ...prevState,
          [item.element]: '',
        }));
      } else {
        setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.element));
        if (validationNormalInput.length === 0) setErrorInput(false);
      }

      // if (inputValue) {
      //   inputValue.optionRefPage = idPage;
      //   inputValue.destinationOpen = destinationOpen;
      // }

      if (action.removedValues) {
        action.removedValues[0].optionRefPage = idPage;
        action.removedValues[0].destinationOpen = destinationOpen;
      }
      if (action.removedValue) {
        action.removedValue.optionRefPage = idPage;
        action.removedValue.destinationOpen = destinationOpen;
      }

      // item.element !== 'extensions' ? form(action, item, inputValue) : null;
      // if (item.element === 'captureId') {
      //   action.action = 'multiselect';
      //   item.action = 'multiselect';
      //   item.ref = { element: item.element };
      // }

      if (item.type === 'dynamic') {
        // || item.type === 'associatedData'
        const associatedDataInputs = allSteps.reduce((inputs, step) => [...inputs, ...step.input.filter((input) => input.ref && input.ref.type === 'associatedData')], []);

        const departments = companyLink.filter((itemFilter) => itemFilter.Company.id === +inputValue?.value).map((item) => item.Department);

        associatedDataInputs.forEach((assocInput) => {
          assocInput.options.splice(0, assocInput.options.length);
          // item.type !== 'associatedData'
          assocInput.options = departments.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          assocInput.defaultValues = {
            value: departments[0].id,
            label: departments[0].name,
          };
          setInputValue((prevState) => ({
            ...prevState,
            [assocInput.ref.element]: assocInput.defaultValues,
          }));

          const newItemCapture = {
            action: item.action,
            element: assocInput.ref.element,
            form: 'user',
            idDestination: item.idDestination,
            idPage: item.idPage,
            type: 'associatedData',
          };
          reducerForm[assocInput.ref.element] = assocInput.defaultValues;

          allSteps[0].input[5].defaultValues = assocInput.defaultValues;
          allSteps[0].input[6].defaultValues = assocInput.defaultValues;

          form(action, newItemCapture, assocInput.defaultValues);
        });
      }

      if (item.element === 'companyId') {
        setInputValue((prevState) => ({
          ...prevState,
          [item.element]: inputValue,
        }));
      }

      form(action, item, inputValue);
    };

    return (
      <SelectWrapper>
        {Object.keys(defaultValue || '').length > 0 ? (
          value ? (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              value={defaultValue}
              // defaultValue[0] !== undefined ? { value: defaultValue[0], label: options[0].label } :
              // value={defaultValue}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
              className={isDisabled ? 'disabled' : ''}
              // disabled={item.ref.element === 'SIPExtension' && form.isSIPExtension === 0 ? true : false}
            />
          ) : (
            <Select
              options={options}
              placeholder={placeholder}
              onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
              inputRef={inputRef}
              defaultValue={defaultValue}
              isMulti={isMulti}
              isSearchable={isSearchable}
              isClearable={true}
              isDisabled={isDisabled}
              // setIsDisabled={handleIsDisabled}
              setValidateReducer={setValidateReducer}
            />
          )
        ) : (
          <Select
            options={options[0]?.name ? options.map((option) => ({ value: option.value, label: option.label + ' - ' + option.name, name: option.name })) : options}
            placeholder={placeholder}
            onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
            inputRef={inputRef}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={true}
            setValidateReducer={setValidateReducer}
          />
        )}
      </SelectWrapper>
    );
  } catch (error) {
    if (import.meta.env.VITE_DEBUG === 'true') console.log(error);
  }
};
MySelect.propTypes = {
  item: object,
  options: array,
  placeholder: string,
  inputRef: object,
  // defaultValue: PropTypes.oneOfType([PropTypes.oneOf([PropTypes.object, PropTypes.array])]),
  defaultValue: array,
  isMulti: bool,
  isSearchable: bool,
  form: func,
  setInputValue: func,
  setErrorInput: func,
  setValidationNormalInput: func,
  validationNormalInput: array,
  allSteps: array,
  idPage: number,
  destinationOpen: number,
  isDisabled: bool,
  setValidateReducer: func,
  reducerForm: object,
  firstData: object,
  value: array,
};
