export const actionTypesUser = {
  create: 'CREATE_USERS',
  delete: 'DELETE_USERS',
  update: 'UPDATE_USERS',
  get: 'GET_USERS',
  getUsersPermission: 'GET_USERS_PERMISSION',
  getAccessLevel: 'GET_USERS_ACCESS_LEVELS',

  changeUserFormId: 'CHANGE_USER_FORM_ID',
  changeUserFormName: 'CHANGE_USER_FORM_NAME',
  changeUserFormFirstSurname: 'CHANGE_USER_FORM_FIRST_SURNAME',
  changeUserFormSecondSurname: 'CHANGE_USER_FORM_SECOND_SURNAME',
  changeUserFormEmail: 'CHANGE_USER_FORM_EMAIL',
  changeUserFormPassword: 'CHANGE_USER_FORM_PASSWORD',
  changeUserFormCompany: 'CHANGE_USER_FORM_COMPANY',
  changeUserFormDepartment: 'CHANGE_USER_FORM_DEPARTMENT',
  changeUserFormAccessLevels: 'CHANGE_USER_FORM_ACCESS_LEVELS',
  addUserFormAccessLevels: 'ADD_USER_FORM_ACCESS_LEVELS',

  changeUserFormRoles: 'CHANGE_USER_FORM_ROLES',
  addUserFormRoles: 'ADD_USER_FORM_ROLES',
  changeUserFormCapture: 'CHANGE_USER_FORM_CAPTURE',
  changeUserFormisSIPExtension: 'CHANGE_USER_FORM_isSIPExtension',
  addUserFormisSIPExtension: 'ADD_USER_FORM_isSIPExtension',

  addUserFormSIPExtension: 'ADD_USER_FORM_SIPExtension',
  changeUserFormSIPExtension: 'CHANGE_USER_FORM_SIPExtension',
  changeUserFormCallForward: 'CHANGE_USER_FORM_CALLFORWARD',
  changeUserFormNotAnswer: 'CHANGE_USER_FORM_NOTANSWER',
  addUserFormisTeams: 'ADD_USER_FORM_isTeams',
  addUserFormisRainbow: 'ADD_USER_FORM_isRainbow',

  changeUserFormisTeams: 'CHANGE_USER_FORM_isTeams',
  changeUserFormisRainbow: 'CHANGE_USER_FORM_isRainbow',
  changeUserFormisCallForward: 'CHANGE_USER_FORM_isCALLFORWARD',
  changeUserFormIsNotAnswer: 'CHANGE_USER_FORM_isNOTANSWER',
  addUserFormisVoicemail: 'ADD_USER_FORM_isVoicemail',
  changeUserFormisVoicemail: 'CHANGE_USER_FORM_isVoicemail',

  addUserFormisvoicemailEmail: 'ADD_USER_FORM_isvoicemailEmail',
  changeUserFormisvoicemailEmail: 'CHANGE_USER_FORM_isvoicemailEmail',

  addUserFormvoicemailPIN: 'ADD_USER_FORM_voicemailPIN',
  changeUserFormvoicemailPIN: 'CHANGE_USER_FORM_voicemailPIN',

  changeUserRingTimeout: 'CHANGE_USER_FORM_TIMEOUT',

  addUserFormCompany: 'ADD_USER_FORM_COMPANY',
  addUserFormDepartment: 'ADD_USER_FORM_DEPARTMENT',

  removeUserFormRoles: 'REMOVE_USER_FORM_ROLES',

  clearUserForm: 'CLEAR_USER_FORM',
  clearUserFormRoles: 'CLEAR_USER_FORM_ROLES',
};
