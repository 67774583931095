import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateUser } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useUsers from '../../../hooks/useUsers';
import { routesWide } from '../../../navigation/routes';

import { string } from 'prop-types';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useDepartments } from '../../../hooks/useDepartments';
import { useAccessLevels } from '../../../hooks/useAccessLevel';
import FallbackRender from '../../../components/FallbackRenderer/FallbackRenderer';
import { ErrorBoundary } from 'react-error-boundary';
import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { useStore } from 'react-redux';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
// import { arraysAreEqual } from '../../../utils/AppUtils';
// import useNumbering from '../../../hooks/useNumbering';
// import { getCompanyLinkReducer } from '../../../redux/reducers/headquarter/headquarterReducer';

const UserScreenForm = ({ crudType }) => {
  const { postCreateUser, userForm, updateUser } = useUsers();
  const { getSettingsData, getConfigData } = useSettings();
  // const navigate = useNavigate();

  const { getCategoriesHook, categoriesHook } = useOutbound();
  const { getDepartments, departmentsHook } = useDepartments();
  // const { arrayNumberingExtensionsHook } = useNumbering();
  const { getState } = useStore();

  const { isMenuOpen } = getState()['system'];
  const { usersFormReducer } = getState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [render, setRender] = useState(false);
  const { getUsers, users } = useSettings();
  const { typeSwal } = useSwal();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { getAccessLevels, arrayAccessLevelsHook } = useAccessLevels();
  const location = useLocation();
  // const [selectedCompany, setSelectedCompany] = useState('');
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateUser;

  const { state } = useLocation();

  useEffect(() => {
    getSettingsData();
    getConfigData();
    getHeadquarter();
    getDepartments();
    getAccessLevels();
    getCategoriesHook();
  }, []);

  const [docSettings] = useState(getState()['getConfigReducer']);

  useEffect(() => {
    userForm(
      { action: 'clearForm' },
      {
        form: 'user',
      }
    );

    if (crudType === 'create' || crudType === 'edit') {
      userForm(
        {
          action: 'id',
          value: location.state?.id,
        },
        { form: 'user', element: 'id' }
      );

      userForm(
        {
          action: 'input',
          value: location.state?.voicemailPIN,
        },
        { form: 'user', element: 'voicemailPIN' }
      );

      userForm(
        {
          action: 'select-option',
          value: location.state?.SIPExtensionId,
          label: location.state?.SIPExtensionId,
        },
        { form: 'user', element: 'SIPExtension' }
        // { value: location.state?.SIPExtensionId, label: location.state?.SIPExtensionId }
      );

      const newCategory = categoriesHook?.find((itemCapt) => itemCapt.id === location.state?.category);

      newCategory
        ? userForm(
            {
              action: 'select-option',
              value: location.state?.category,
              label: newCategory?.description,
            },
            { form: 'user', element: 'category' }
          )
        : null;

      // const newCapture = [];
      // location.state?.captureId.forEach((itemCapture) => {
      //   const found = departmentsHook.find((itemCapt) => itemCapt.id === itemCapture);

      //   found ? newCapture.push({ value: found.id, label: found.name }) : null;
      // });

      // newCapture.length > 0
      //   ?
      location.state?.captureId.forEach((itemCapture) => {
        const found = departmentsHook.find((itemCapt) => itemCapt.id === itemCapture.value);

        found
          ? userForm(
              {
                action: 'select-option',
              },
              { form: 'user', element: 'captureId' },
              { value: found.id, label: found.name }
            )
          : null;
      });

      // : null;

      userForm(
        {
          action: 'select-option',
        },
        {
          form: 'user',
          element: 'companyId',
        },
        {
          value: state?.companyId,
          label: state?.Company,
        }
      );

      // userForm(
      //   {
      //     action: 'select-option',
      //   },
      //   {
      //     form: 'extension',
      //     element: 'department',
      //   },
      //   {
      //     label: state?.department,
      //     value: String(state?.departmentId),
      //   }
      // );

      // userForm(
      //   {
      //     action: 'select-option',
      //     value: location.state?.companyId,
      //     label: location.state?.Company,
      //   },
      //   {
      //     form: 'user',
      //     element: 'Company',
      //   }
      // );

      userForm(
        {
          action: 'select-option',
          value: usersFormReducer.departmentId.value || location.state?.departmentId,
          label: usersFormReducer.departmentId.label || location.state?.Department,
        },
        {
          form: 'user',
          element: 'departmentId',
        }
      );

      // userForm(
      //   {
      //     action: 'select-option',
      //     value: location.state?.departmentId,
      //     label: location.state?.Department,
      //   },
      //   {
      //     form: 'user',
      //     element: 'Department',
      //   }
      // );

      const newAccessLevel = arrayAccessLevelsHook.find((itemAcc) => {
        const newItem = itemAcc.id === location.state?.accesslevelId;
        return newItem;
      });
      newAccessLevel
        ? userForm(
            {
              action: 'select-option',
              value: newAccessLevel.id,
              label: newAccessLevel.name,
            },
            {
              form: 'user',
              element: 'accesslevelId',
            }
          )
        : null;

      userForm(
        {
          action: 'select-option',
          value: location.state?.accesslevelId,
          label: location.state?.AccessLevel,
        },
        {
          form: 'user',
          element: 'AccessLevel',
        }
      );

      // const newRole = arrayAccessLevelsHook.find((itemAcc) => {
      //   const newItem = itemAcc.id === location.state?.accesslevelId;
      //   return newItem;
      // });
      // newRole
      // ? userForm(
      //     {
      //       action: 'select-option',
      //       value: newRole.id,
      //       label: newRole.name,
      //       //  label: location.state.type,
      //     },
      //     {
      //       form: 'user',
      //       element: 'rolesId',
      //     }
      //   )
      // :
      userForm(
        {
          action: 'select-option',
          value: location.state?.roleId,
          label: location.state?.role,
        },
        {
          form: 'user',
          element: 'roleId',
        }
      );

      // userForm(
      //   {
      //     action: 'toggleButton',
      //     value: location.state.isVoicemail,
      //   },
      //   {
      //     form: 'user',
      //     element: 'isVoicemail',
      //   }
      // );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isvoicemailEmail,
          //  label: location.state.type,
        },
        {
          form: 'user',
          element: 'isvoicemailEmail',
        }
      );

      // userForm(
      //   {
      //     action: 'toggleButton',
      //     value: location.state.isSIPExtension,
      //   },
      //   {
      //     form: 'user',
      //     element: 'isSIPExtension',
      //   }
      // );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isTeams || false,
          //  label: location.state.type,
        },
        {
          form: 'user',
          element: 'isTeams',
        }
      );

      userForm(
        {
          action: 'toggleButton',
          value: location.state?.isRainbow || false,
          //  label: location.state.type,
        },
        {
          form: 'user',
          element: 'isRainbow',
        }
      );

      location.state &&
        Object.keys(location.state).map((item) =>
          data.steps.map((item2) =>
            item2.input.map((item3) => {
              if (
                item3.ref.element !== 'accesslevel' &&
                item3.ref.element !== 'roleId' &&
                item3.ref.element !== 'departmentId' &&
                item3.ref.element !== 'Department' &&
                item3.ref.element !== 'companyId' &&
                item3.ref.element !== 'Company' &&
                // item3.ref.element !== 'voicemailPIN' &&
                item3.ref.element !== 'SIPExtensionId' &&
                item3.ref.element !== 'captureId' &&
                item3.ref.element !== 'category' &&
                item === item3.ref.element
              ) {
                return userForm(
                  {
                    action: item3.cardType,
                    value: location.state[item],
                    label: location.state[item],
                  },
                  {
                    form: item3.ref.form,
                    element: item3.ref.element,
                  }
                );
              } else {
                return null;
              }
            })
          )
        );
    }
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof usersFormReducer === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'companyId') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  value: item3.id,
                  label: item3.name,
                };
                // setSelectedCompany(company.value);
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            // if (item2.ref.element === 'departmentId') {

            //   item2.options = [];
            //   departmentsHook?.forEach((itemDepart) => {
            //     if (itemDepart.Ddi?.companyId === location.state?.companyId) {
            //       const found = item2.options.find((e) => e.value === location.state?.departmentId);
            //       if (!found) {
            //         item2.options.push({ value: itemDepart.id, label: itemDepart.name });
            //       }
            //     }
            //   });

            // }
            if (item2.ref.element === 'accesslevelId') {
              arrayAccessLevelsHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const accesslevel = {
                  label: item3.name,
                  value: item3.id,
                };
                const found = item2.options.some((e) => e.value === accesslevel.value);
                if (!found && accesslevel.value !== undefined) {
                  item2.options.push(accesslevel);
                }
              });
            }

            if (item2.ref.element === 'SIPExtension') {
              item2.options = [];
              for (let i = docSettings?.extension_start; i < docSettings?.extension_end; i++) {
                const assigned = users.some((itemUser) => Number(itemUser.SIPExtensionId) === i);
                if (!assigned) {
                  // const found = item2.options.some((e) => e.value === location.state?.SIPExtensionId);
                  // if (!found) {
                  item2.options.push({
                    label: i,
                    value: i,
                  });
                  // }
                }
              }
            }
            if (item2.ref.element === 'category') {
              item2.options = [];
              categoriesHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const itemCat = {
                  value: item3.id,
                  label: item3.description,
                };
                const found = item2.options.some((e) => e.value === itemCat.value);
                if (!found && itemCat.value !== undefined) {
                  item2.options.push(itemCat);
                }
              });
            }
            // if (item2.ref.element === 'captureId') {
            //   item2.options = [];
            //   location.state?.captureId?.forEach((itemCapture) => {
            //     item2.options = [];
            //     departmentsHook?.forEach((itemDepart) => {
            //       if (itemDepart.Ddi?.companyId === location.state?.companyId) {
            //         const found = item2.options.find((e) => e.value === itemCapture?.value);
            //         if (!found) {
            //           item2.options.push({ value: itemDepart.id, label: itemDepart.name });
            //         }
            //       }
            //     });
            //   });
            // }
          });
        });
      }

      // if (typeof arrayAccessLevelsHook === 'object' && arrayAccessLevelsHook?.length > 0) {
      //   data.steps.map((item) => {
      //     item.input.map((item2) => {
      //       if (item2.ref.element === 'accesslevelId') {
      //         arrayAccessLevelsHook.forEach((item3) => {
      //           if (item3.name === null) {
      //             item3.name = 'not defined';
      //           }
      //           const accesslevel = {
      //             label: item3.name,
      //             value: item3.id?.toString(),
      //           };
      //           const found = item2.options.some((e) => e.value === accesslevel.value);
      //           if (!found && accesslevel.value !== undefined) {
      //             item2.options.push(accesslevel);
      //           }
      //         });
      //       }
      //     });
      //   });
      // }

      if (typeof usersFormReducer === 'object') {
        if (crudType === 'edit' && (usersFormReducer.firstname === null || usersFormReducer.firstname !== location.state.firstname)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'firstname':
                  item2.defaultValues = usersFormReducer.firstname;
                  break;
                case 'lastname':
                  item2.defaultValues = usersFormReducer.lastname;
                  break;
                case 'secondname':
                  item2.defaultValues = usersFormReducer.secondname;
                  break;
                case 'email':
                  item2.defaultValues = usersFormReducer.email;
                  break;
                case 'password':
                  item2.defaultValues = usersFormReducer.password;
                  break;
                case 'accesslevelId':
                  location.state.accesslevelId &&
                    item2.options.filter((item3) => {
                      if (item3.value === usersFormReducer.accesslevelId) {
                        return (item2.defaultValues = item3);
                      }
                      return false;
                    });
                  break;
                case 'companyId':
                  // item2.options.filter((item3) => {
                  //   if (item3.value === usersFormReducer.companyId) {
                  //     return (item2.defaultValues = item3);
                  //   }
                  //   return false;
                  // });
                  item2.defaultValues = location.state?.companyId;
                  // { value: usersFormReducer?.companyId.value, label: usersFormReducer?.companyId.label };
                  break;
                case 'departmentId':
                  // if (item2.defaultValues?.value !== usersFormReducer?.departmentId.value) {
                  //   item2.defaultValues = { label: location.state?.Department, value: usersFormReducer?.departmentId.value };
                  //   usersFormReducer.captureId = item2.defaultValues;
                  // }
                  item2.defaultValues = { value: usersFormReducer?.departmentId.value, label: usersFormReducer?.departmentId.label };
                  // usersFormReducer?.departmentId;
                  //  { label: location.state?.Department, value: usersFormReducer?.departmentId.value };
                  break;
                case 'roleId':
                  item2.options.filter((item3) => {
                    if (location.state.roleId === Number(item3.value)) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'Company':
                  item2.options.forEach((item3) => {
                    if (item3.value === usersFormReducer.Company.id.toString()) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'AccessLevel':
                  item2.defaultValues = usersFormReducer.AccessLevel;
                  break;
                case 'isVoicemail':
                  item2.defaultValues = usersFormReducer.isVoicemail;
                  break;
                case 'isvoicemailEmail':
                  item2.defaultValues = usersFormReducer.isvoicemailEmail;
                  break;
                case 'isTeams':
                  item2.defaultValues = usersFormReducer.isTeams;
                  break;
                case 'isRainbow':
                  item2.defaultValues = usersFormReducer.isRainbow;
                  break;
                case 'SIPExtension':
                  item2.defaultValues = usersFormReducer.SIPExtension;
                  break;
                case 'category':
                  item2.defaultValues = usersFormReducer.category;
                  break;
                case 'captureId':
                  item2.defaultValues = usersFormReducer?.captureId;
                  break;

                default:
                  item2.defaultValues = usersFormReducer[item2.ref?.element];
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              item2.defaultValues = '';
            });
          });
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [crudType, loadingCounter]);

  const handleOpenFormCreate = () => {
    setIsModalOpen(true);
    // navigate('/users/usersList/create', {});
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.users}`, 'user', postCreateUser);
      ///${routesUser.usersList}/${routesUser.userCreate}
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.users}`, 'user', updateUser);
    }
  };

  return (
    <ErrorBoundary fallbackRender={FallbackRender}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PageScreen
          arrScreen={['Usuario']}
          dataSave={dataSave}
          data={data}
          onSubmit={onSubmit}
          render={render}
          setRender={setRender}
          form={userForm}
          crudType={'edit'}
          maxwidth={'1000px'}
          reducerForm={usersFormReducer}
          handleOpenFormCreate={handleOpenFormCreate}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isMenuOpen={isMenuOpen}
          // setCheckboxesActives={setCheckboxesActives}
          // checkboxesActives={checkboxesActives}
        />
      )}
      {/* <FormScreen data={data} reducerForm={usersFormReducer} arrScreen={['Usuario']} onSubmit={onSubmit} form={userForm} dataSave={dataSave} crudType={crudType} />} */}
    </ErrorBoundary>
  );
};

UserScreenForm.propTypes = { crudType: string };

export default UserScreenForm;
