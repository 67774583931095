import styled from 'styled-components';

export const CallRegisterNavigationWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // margin-top: 120px;
  // padding-left: 200px;

  // padding-left: ${(p) => (!p.isMenuOpen ? '50px' : '150px')};
`;
